import React from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';

import { useAuth } from '../../../contexts/User';

import Subheader from '../../../components/Subheader';
import CardTabbed from '../../../components/CardTabbed';
import DraggerUpload from '../../../components/DraggerUpload';

import { InboxOutlined } from '@ant-design/icons';
import { Row, Col, Card, Form, Button, Input, InputNumber, Select, DatePicker, Switch, message, Alert, Table, Space, Modal } from 'antd';

import { STATUS as STASTATUS, hasStatus, handleCountClick } from '../../../helpers/AppsHelper';

import { APIBackend as API } from '../../../api';

import moment from 'moment';

import createPersistedState from '../../../hooks/usePersistedState';

const { Option } = Select;

const useUserStageState = createPersistedState('userStage');
const useContractorState = createPersistedState('contractor');

const Dashboard_InfantPayments = ({ ...props }) => {
	const auth = useAuth();
	const history = useHistory();
	const { infantId } = useParams();

	const [form] = Form.useForm();

	const [data, setData] = React.useState(null);
	const [loading, setLoading] = React.useState(true);
	const [loadingUser, setLoadingUser] = React.useState(true);

	const [userStage, setUserStage] = useUserStageState(0);
	const [contractor, setContractor] = useContractorState(null);

	const acceptApp = (table, id) => {
		!['appinfants'].includes(table)
			? API.countApp(table, id).then(r => {
				Modal.confirm({
					title: 'Potwierdź akcję',
					content: <>Na pewno chcesz przekazać wpłatę do akceptacji?{!['appgrants'].includes(table) ? <><br/>Wysokość dofinansowania wynosi: <strong>{r?.subsidy_total ?? '?'} PLN</strong></> : null}</>,
					onOk() {
						return API.acceptApp(table, id).then(() => {
							message.success('Wpłata została przekazana do akceptacji');
						}).catch(err => {
							message.error(err.getMessage());
						}).finally(() => {
							getAppList();
						});
					}
				});
			}).catch(err => {
				message.error(err.getMessage());
			})
			: Modal.confirm({
				title: 'Potwierdź akcję',
				content: <>Na pewno chcesz przekazać wpłatę do akceptacji?</>,
				onOk() {
					return API.acceptApp(table, id).then(() => {
						message.success('Wpłata została przekazana do akceptacji');
					}).catch(err => {
						message.error(err.getMessage());
					}).finally(() => {
						getAppList();
					});
				}
			});
	};

	const deleteApp = (table, id) => {
		Modal.confirm({
			title: 'Potwierdź akcję',
			content: <>Na pewno chcesz usunąć wpłatę?</>,
			onOk() {
				return API.deleteApp(table, id).then(() => {
					message.success('Wpłata została usunięta');
				}).catch(err => {
					message.error(err.getMessage());
				}).finally(() => {
					getAppList();
				});
			}
		});
	};

	const getAppList = () => {
		if(auth.hasActiveProfile()) {
			API.getInfantPaymentsForInfant(infantId).then(data => {
				setData(Array.isArray(data) ? data : []);
			}).catch(err => {
				console.log(err)
			});
		}
	};

	const columns = [
		{
			title: 'Numer wpłaty',
			dataIndex: 'appinfantpayment_number',
			key: 'appinfantpayment_number',
			render: (text, record, index) => {
				return text ?? '(brak)';
			}
		},
		{
			title: 'Okres płatności',
			dataIndex: 'period',
			key: 'period',
			render: (text, record, index) => {
				return text ?? '(brak)';
			}
		},
		{
			title: 'Rzeczywista kwota płatności',
			dataIndex: 'real_price',
			key: 'real_price',
			render: (text, record, index) => {
				return text ?? '(brak)';
			}
		},
		{
			title: 'Kwota podlegająca dofinansowaniu',
			dataIndex: 'subsidy_value',
			key: 'subsidy_value',
			render: (text, record, index) => {
				return text ?? '(brak)';
			}
		},
		{
			title: 'Wysokość dofinansowania',
			dataIndex: 'subsidy_total',
			key: 'subsidy_total',
			render: (text, record, index) => {
				return text ?? '(brak)';
			}
		},
		{
			title: 'Status',
			dataIndex: 'staapp_name',
			key: 'staapp_name',
			render: (text, record, index) => {
				return text ?? '(brak)';
			}
		},
		{
			title: 'Akcje',
			key: 'actions',
			align: 'right',
			render: (text, record) => (
				<Space size="middle">
					{
						[1,4].includes(record?.staapp_id)
							? <Button size="small" onClick={() => acceptApp('appinfantpayments', record.id)} className="app-btn-accept">Przekaż do akceptacji</Button>
							: null
					}

					{
						record?.can_delete
							? <Button danger size="small" onClick={() => deleteApp('appinfantpayments', record.id)} className="app-btn-accept">Usuń</Button>
							: null
					}

					{
						hasStatus(record?.staapp_id, STASTATUS.STA_REGISTERED)
							? <Button type="primary" onClick={() => handleCountClick('infantpayments', record.id).then(r => {
								message.success(`Dofinansowanie zostało obliczone, wysokość dofinansowania: ${r?.subsidy_total ?? 0} PLN`);
								getAppList();
							}).catch(err => message.error('Wystąpił błąd podczas obliczania dofinansowania, prosimy o kontakt z BOK'))} className="ant-btn-sm app-btn-preview">Oblicz dofinansowanie</Button>
							: null
					}

					<Link to={[1,4].includes(record.staapp_id) ? `/infantpayments/edit/${record.id}` : null} disabled={![1,4].includes(record.staapp_id)} className="ant-btn ant-btn-primary ant-btn-sm app-btn-preview">Edytuj</Link>
				</Space>
			)
		}
	];

	React.useEffect(() => {

	}, []);

	React.useEffect(() => {
		setLoadingUser(false);
	}, [auth, auth.user]);

	React.useEffect(() => {
		getAppList();
	}, [auth.activeProfile, auth.user]);

	const applyInfants = () => {
		history.push('/infants/create');
	};

	return (
		<>
			<Subheader title={data?.length ? `[${data[0].appinfant_number}] Rejestr wpłat` : `Rejestr wpłat`} extra={<Subheader.Actions>
				<Link to="/infants" style={{ marginRight: 15 }}>Wróć</Link>

				<Link to={`/infantpayments/create/${infantId}`} className="ant-btn ant-btn-primary">Nowa wpłata</Link>
			</Subheader.Actions>} />

			<Row gutter={[24, 24]}>
				<Col sm={24} lg={24}>
					<Card loading={!data}>
						<div style={{ display: 'block', width: '100%' , overflowX: 'auto' }}>
							<Table dataSource={data} columns={columns} rowKey={() => Math.random()} />
						</div>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default Dashboard_InfantPayments;
