const globalState = {};

const createGlobalState = (key, cb, initialValue) => {
	if(!globalState[key]) {
		globalState[key] = { callbacks: [], value: initialValue };
	}

	globalState[key].callbacks.push(cb);

	return {
		unregister() {
			const arr = globalState[key].callbacks;
			const idx = arr.indexOf(cb);
			if(idx > -1) {
				arr.splice(idx, 1);
			}
		},
		emit(value) {
			if(globalState[key].value !== value) {
				globalState[key].value = value;
				globalState[key].callbacks.forEach(callback => {
					if(cb !== callback) {
						callback(value);
					}
				});
			}
		}
	};
};

export default createGlobalState;
