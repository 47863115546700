import React from 'react';

import Tour from 'reactour';

import { useAuth } from '../../../contexts/User';

import { Button, Table, Space, Skeleton, message, Modal } from 'antd';

import { APIBackend as API } from '../../../api';

import createPersistedState from '../../../hooks/usePersistedState';

const useContractorState = createPersistedState('contractor');

const Dashboard_Profile = ({ ...props }) => {
	const auth = useAuth();

	const [contractor, setContractor] = useContractorState(null);

	const [data, setData] = React.useState([]);
	const [loading, setLoading] = React.useState(true);

	const [isTourOpen, setIsTourOpen] = React.useState(false);

	const steps = [
		{
			content: <>
				W tym miejscu możesz przekazać profil do akceptacji, jeśli jest on w finalnej wersji.
				<br/><br/>
				Pojawią się tutaj również profile, które zostały zwrócone do korekty.
			</>,
			stepInteraction: false,
		},
		{
			selector: '.zfss-edit-profile-btn',
			content: <>
				Pamiętaj, że na tym etapie możesz wprowadzać zmiany w profilu.
			</>,
			stepInteraction: false,
		},
		{
			selector: '.zfss-profile-status',
			content: <>
				Możesz tutaj również śledzić status Twojego profilu. Pamiętaj, że w momencie jego zaakceptowania zniknie on z listy i będziesz mógł/mogła go podejrzeć na zakładce "Aktywny profil".
			</>,
			stepInteraction: false,
		},
		{
			selector: '.zfss-profile-nav',
			content: <>
				Aby zobaczyć swój profil, kliknij ikonę ze swoim imieniem i nazwiskiem w prawej górnej części ekranu.
			</>,
			stepInteraction: false,
		},
		{
			selector: '.zfss-accept-profile-btn',
			content: <>
				Jeżeli profil jest już gotowy do wysłania, możesz to zrobić za pomocą przycisku "Przekaż do akceptacji". Później wystarczy czekać na rozpatrzenie.
				<br/><br/>
				<i>Dostaniesz również odpowiednią informację za pośrednictwem wiadomości e-mail.</i>
			</>,
			stepInteraction: false,
		},
	];

	const acceptProfile = (profile_id) => {
		Modal.confirm({
			title: 'Potwierdź akcję',
			content: <>
				Czy jesteś pewny/a, że uzupełniłeś/łaś wszystkie dane na profilu i chcesz przekazać go do akceptacji?
			</>,
			okText: 'Tak, przekaż do akceptacji',
			onOk() {
				return API.acceptProfile(profile_id).then(() => {
					message.success('Profil został przekazany do akceptacji');
					getProfiles();
				}).catch(err => {
					message.error('Wystąpił błąd');
				}).finally(() => {
					props.setMessage(null);
				});
			}
		});
	};

	const columns = [
		{
			title: 'Imię i nazwisko',
			dataIndex: 'full_name',
			key: 'full_name'
		},
		// {
		// 	title: 'Adres e-mail',
		// 	dataIndex: 'email',
		// 	key: 'email',
		// 	render: (text, record, index) => {
		// 		let email = text;
		// 		const data = email.split('@');
		//
		// 		const username = data[0].substr(0, 3) + data[0].substr(3).replace(/./gi, '*');
		// 		email = `${username}@${data[1]}`;
		//
		// 		return email;
		// 	}
		// },
		{
			title: 'Uwagi (do korekty)',
			dataIndex: 'to_correct',
			key: 'to_correct',
			ellipsis: true,
			render: (text, record, index) => {
				return text ?? (record.staprofile_id === 4 ? '(brak)' : '-');
			}
		},
		{
			title: 'Status',
			dataIndex: 'staprofile_name',
			key: 'staprofile_name',
			render: (text) => {
				return <span className="zfss-profile-status">{text}</span>;
			}
		},
		{
			title: 'Data utworzenia',
			dataIndex: 'created_at',
			key: 'created_at'
		},
		{
			title: 'Akcje',
			key: 'actions',
			align: 'right',
			render: (text, record) => (
				<Space size="middle">
					<Button className="zfss-edit-profile-btn" type="primary" size="small" disabled={![1,4].includes(record?.staprofile_id)} onClick={
						[1,4].includes(record?.staprofile_id)
							? () => {
								props.changePage('edit', {
									id: record.id
								});
							}
							: () => {}
					}>Edytuj</Button>
					{
						[1,4].includes(record?.staprofile_id)
							? <Button className="zfss-accept-profile-btn" size="small" onClick={() => { acceptProfile(record.id); }}>Przekaż do akceptacji</Button>
							: null
					}
				</Space>
			)
		}
	];

	const getProfiles = () => {
		API.profiles(auth.user.id, contractor).then(data => {
			setData(data.filter(el => ![2,3].includes(el.staprofile_id) && el.contractor_id === contractor && el.user_id === auth.user.id));
		}).catch(err => {

		}).finally(() => {
			setLoading(false);
			if(!auth.afterFirstTour()) {
				setIsTourOpen(true);
			}
		});
	};

	React.useEffect(() => {
		getProfiles();
	}, []);

	return (
		<>
			{loading ? <Skeleton active /> : <div style={{ display: 'block', width: '100%' , overflowX: 'auto' }}><Table dataSource={data} columns={columns} rowKey={() => Math.random()} className='table-fixed-to-auto' /></div>}

			<Tour
				steps={steps}
				isOpen={isTourOpen}
				onRequestClose={() => setIsTourOpen(false)}
				closeWithMask={false}
				lastStepNextButton={<Button size="small" onClick={() => {
					auth.setAfterFirstTour();
					setIsTourOpen(false);
				}}>Rozumiem!</Button>}
				className="appTour"
			/>
		</>
	);
};

export default Dashboard_Profile;
