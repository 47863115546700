import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

import { Main, Container } from '../../helpers/UI';

import { Row, Col, Form, Card, Input, Button, Alert } from 'antd';

import Logo_Sanpro from '../../assets/images/logos/sanpro.png';
import Sygnet_Impel from '../../assets/images/logos/impel_sygnet_color.png';

import { useAuth } from '../../contexts/User';

import { APIBackend as API } from '../../api';

const LoginPane = styled.div`
	display: table-cell;
	vertical-align: middle;
`;

const LoginPaneTitle = styled.div`
	font-size: 24px;
`;

const LoginPaneSubtitle = styled.div`
	font-size: 14px;
	font-weight: 400;
	margin: 10px 0 0;
`;

const CenteredCol = styled(Col)`
	display: table;
	margin: 0 auto;
	height: 100%;
`;

const StrongLink = styled(Link)`
	font-weight: bold;
	font-size: 16px;
`;

const LoginPaneStyles = createGlobalStyle`
	${LoginPane} .ant-card-head {
		background: #457b9d;
		color: white;
		position: relative;
		text-align: center;
		font-family: 'Poppins', sans-serif;
		padding-bottom: 35px;
	}

	${LoginPane} .ant-card-head:after {
		display: block;
		content: '';
		clear: both;
		position: absolute;
		bottom: -73px;
		left: 50%;
		width: 73px;
		height: 73px;
		border-radius: 100%;
		border: 6px solid #FFF;
		box-shadow: 0 2px 0 -1px #0072a3;
		transform: translate(-50%, -50%);
		background: url(${Sygnet_Impel}) no-repeat center center #FFF;
		background-size: 100% 100%;
	}

	${LoginPane} .ant-card-body {
	    padding-top: 44px;
	}
`;

const Login = ({ ...props }) => {
	const [isError, setError] = React.useState(false);
	const [notices, setLoginNotices] = React.useState(null);

	const auth = useAuth();
	const history = useHistory();

	const onLogin = (values) => {
		const referrer = (props.location.state) ? props.location.state.from : null || '/';

		auth.login(values).then(data => {
			history.push(referrer);
		}).catch(err => {
			setError(err);
		});
	};

	React.useEffect(() => {
		API.getLoginNotices().then(data => {
			setLoginNotices(Array.isArray(data.data) ? data.data : []);
		}).catch(err => {
			setLoginNotices([]);
			console.log(err);
		});
	}, []);

	const hasAnyNotice = () => {
		if(notices) {
			return true
		}
		return false;
	};

	return (
		<Main>
			<Container>
				<Row style={{ height: '100vh' }}>
					<CenteredCol sm={20} md={16} lg={12}>
						<LoginPane>
							{
							hasAnyNotice()
								? notices.map(function(notice, idx){
									return (<Alert type="info" key={idx} message={<>
										<strong>{ notice.title }</strong><br/>{ notice.content}</>} style={{ marginBottom: 20 }} />
									)
								})
								: null
							}
							{ isError && <Alert message={isError.getMessage()} type="error" style={{ marginBottom: '15px' }} /> }
							<LoginPaneStyles />
							<Card
								title={<>
									<LoginPaneTitle>Witaj w SocialPartner</LoginPaneTitle>
									<LoginPaneSubtitle>
										Zaloguj się do systemu, aby kontynuować
									</LoginPaneSubtitle>
								</>}
							>
								<Form
									name="login-form"
									layout="vertical"
									onFinish={onLogin}
								>
									<Form.Item
										label="Adres e-mail:"
										name="email"
										rules={[
											{ required: true, message: 'Proszę wprowadzić adres e-mail' },
											// { type: 'email', message: 'Proszę wprowadzić poprawny adres e-mail' }
										]}
									>
										<Input />
									</Form.Item>

									<Form.Item
										label="Hasło:"
										name="password"
										rules={[
											{ required: true, message: 'Proszę wprowadzić hasło' }
										]}
									>
										<Input.Password />
									</Form.Item>

									<Row>
										<Col span={24} style={{ textAlign: 'right' }}>
											<Form.Item>
												<Button type="primary" htmlType="submit">
													Zaloguj się
												</Button>
											</Form.Item>
										</Col>
									</Row>

									<Row>
										<Col span={24}>
											<Link to="/forgot-password">
												Przypomnienie hasła / pierwsze logowanie
											</Link>
										</Col>
										{<Col span={24}>
											Jeśli nie masz jeszcze konta, <StrongLink to="/register">zarejestruj się</StrongLink>, a następnie postępuj zgodnie z poleceniami na ekranie
										</Col>}
									</Row>
								</Form>
							</Card>
						</LoginPane>
					</CenteredCol>
				</Row>
			</Container>
		</Main>
	);
};

export default Login;
