import React from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';

import { useAuth } from '../../../contexts/User';

import Subheader from '../../../components/Subheader';
import CardTabbed from '../../../components/CardTabbed';
import DraggerUpload from '../../../components/DraggerUpload';

import { InboxOutlined } from '@ant-design/icons';
import { Row, Col, Card, Form, Button, Input, InputNumber, Select, DatePicker, Switch, message } from 'antd';

import { APIBackend as API } from '../../../api';

import moment from 'moment';

import createPersistedState from '../../../hooks/usePersistedState';
import {onApplyHook} from "../../../common/on-apply-hook/on-apply.hook";

const { Option } = Select;

const useUserStageState = createPersistedState('userStage');
const useContractorState = createPersistedState('contractor');

const Dashboard_InfantPayments = ({ ...props }) => {
	const auth = useAuth();
	const history = useHistory();
	const { infantId } = useParams();

	const [form] = Form.useForm();

	const [profileData, setProfileData] = React.useState({});
	const [loading, setLoading] = React.useState(true);
	const [saving, setSaving] = React.useState(false);
	const [loadingUser, setLoadingUser] = React.useState(true);
	const [data, setData] = React.useState(null);
	const [relationData, setRelationData] = React.useState(null);
	const [currentTab, setCurrentTab] = React.useState('general');

	const [userStage, setUserStage] = useUserStageState(0);
	const [contractor, setContractor] = useContractorState(null);

	const [attachments, setAttachments] = React.useState({
		list: [],
		add: [],
		del: []
	});

	React.useEffect(() => {
		API.getActiveProfile(auth.user.id, contractor).then(data => {
			setProfileData(data);
		}).catch(err => {

		}).finally(() => {
			setLoading(false);
		});

		API.infants(infantId).then(data => {
			setData(data);
		}).catch(err => {
			console.log(err);
		});

		API.relations(['dctfiletypes']).then(data => {
			setRelationData(data);
		}).catch(err => {
			console.log(err);
		});
	}, []);

	React.useEffect(() => {
		setLoadingUser(false);
	}, [auth, auth.user]);

	const onSave = async (values) => {
		setSaving(true);

		if(!(await onApplyHook('infant_payments'))) {
			setSaving(false);
			return false;
		}

		Object.keys(values).forEach(el => {
			if(['application_date', 'from', 'to', 'payment_date'].includes(el)) {
				values[el] = values[el] ? values[el].format('YYYY-MM-DD') : null;
			} else if(['attachments'].includes(el)) {
				if(attachments.hasOwnProperty('add')) {
					attachments.add.forEach(el => {
						if(el) {
							values[`files[${el.category}/${el.name}]`] = el.file;
						}
					});
				}

				if(attachments.hasOwnProperty('del')) {
					attachments.del.forEach(el => {
						if(el) {
							values[`delete[${el.category}/${el.name}]`] = el.uid;
						}
					});
				}

				delete values[el];
			}
		});

		const _data = new FormData();

		for(let k in values) {
			let val = values[k];

			if(val !== null && val !== undefined && val !== '') {
				_data.append(k, val);
			}
		}

		API.addInfantPayment(infantId, values).then(r => {
			message.success('Wpłata została złożona');
			history.push('/infantpayments/'+infantId);
		}).catch(err => {
			message.error(err.getMessage());
			console.log(err);
		}).finally(() => {
			setSaving(false);
		});
	};

	const onChange = (field, allFields) => {
		const keys = Object.keys(field);

		if(!keys.length) return;

		const value = field[keys[0]];
	};

	const onFinishFailed = (data) => {
		const {
			errorFields
		} = data;

		if(errorFields.length) {
			errorFields.forEach(el => {
				if(el.errors[0]) {
					message.error(`${el.errors[0]}`);
				}
			});
		}
	};

	const tabs = [
		{ key: 'general', tab: 'Dane podstawowe' },
		{ key: 'attachments', tab: 'Załączniki' }
	];

	const tabContent = {
		general: <>
			<Row gutter={[16, 24]}>
				<Col sm={24} md={12}>
					<Form.Item
						label="Wnioskujący"
						name="profile_id"
						initialValue={profileData.id}
					>
						<Select disabled>
							<Option value={profileData.id}>{auth.user.full_name}</Option>
						</Select>
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Powiązany wniosek"
						name="appinfant_id"
						initialValue={infantId}
					>
						<Select disabled>
							<Option value={infantId}>{data?.family_first_name ? `[${data?.number ?? '-'}] ${data?.family_first_name} ${data?.family_last_name}` : '-'}</Option>
						</Select>
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Okres płatności"
						name="period"
						rules={
							[
								{
									type: 'string',
									max: 7,
									message: 'Pole Okres płatności może zawierać maksymalnie 7 znaków. Format: RRRR/MM, np. 2021/07'
								},
								{
									pattern: /(\d{4}\/\d{2})/g,
									message: 'Nieprawidłowy format, proszę uzupełnić pole w następującym formacie: RRRR/MM, np. 2021/07'
								},
								{
									required: true,
									message: 'Proszę uzupełnić okres płatności'
								}
							]
						}
					>
						<Input />
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Rzeczywista kwota płatności"
						name="real_price"
						rules={
							[
								{
									required: true,
									message: 'Proszę uzupełnić rzeczywistą kwotę płatności'
								}
							]
						}
					>
						<InputNumber precision={2} decimalSeparator="," formatter={(value) => value.replace(/,/, '.')} style={{ width: '100%' }} />
					</Form.Item>
				</Col>
			</Row>
		</>,
		attachments: <>
			<Row gutter={[16, 24]}>
				<Col sm={24} md={12}>
					<Form.Item
						label="Ogólny"
						name="attachments"
						getValueFromEvent={(e) => {
							if(Array.isArray(e)) {
								return e;
							}

							return e && e.fileList;
						}}
						rules={[
							{
								required: true,
								message: 'Proszę dodać dowód wpłaty do załączników'
							}
						]}
					>
						<DraggerUpload
							fileType={
								(relationData && relationData.hasOwnProperty('dctfiletypes'))
									? relationData.dctfiletypes.find(el => el.name === 'Ogólny') ?? null
									: null
							}
							updateAttachments={setAttachments}
							data={attachments}
						>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>

							<p className="ant-upload-text">Kliknij lub przeciągnij plik do tego obszaru, aby dodać załącznik</p>
						</DraggerUpload>
					</Form.Item>
				</Col>
			</Row>
		</>,
	};

	return (
		<>
			<Form form={form} layout="vertical" onFinish={onSave} onValuesChange={onChange} onFinishFailed={onFinishFailed}>
				<Subheader title={`Dodawanie wpłaty`} extra={<Subheader.Actions>
					<Link to={`/infantpayments/${infantId}`} style={{ marginRight: 15 }}>Wróć</Link>
					<Button type="primary" htmlType="submit" disabled={loading} loading={saving}>Dodaj wpłatę</Button>
				</Subheader.Actions>} />

				<CardTabbed loading={loading || loadingUser} tabList={tabs} tabContent={tabContent} activeTabKey={currentTab} onTabChange={key => { setCurrentTab(key); }} />
			</Form>
		</>
	);
};

export default Dashboard_InfantPayments;
