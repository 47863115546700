import React, { useState } from 'react';
import { Link } from "react-router-dom";
import styled, { createGlobalStyle } from 'styled-components';

import { Main, Container } from '../../helpers/UI';

import { APIBackend as API } from '../../api';

import { Form, Input, Button, Row, Col, Card, Alert } from 'antd';

import Logo_Sanpro from '../../assets/images/logos/sanpro.png';
import Sygnet_Impel from '../../assets/images/logos/impel_sygnet_color.png';

const LoginPane = styled.div`
	display: table-cell;
	vertical-align: middle;
`;

const LoginPaneTitle = styled.div`
	font-size: 24px;
`;

const CenteredCol = styled(Col)`
	display: table;
	margin: 0 auto;
	height: 100%;
`;

const LoginPaneStyles = createGlobalStyle`
	${LoginPane} .ant-card-head {
		background: #457b9d;
		color: white;
		position: relative;
		text-align: center;
		font-family: 'Poppins', sans-serif;
		padding-bottom: 35px;
	}

	${LoginPane} .ant-card-head:after {
		display: block;
		content: '';
		clear: both;
		position: absolute;
		bottom: -73px;
		left: 50%;
		width: 73px;
		height: 73px;
		border-radius: 100%;
		border: 6px solid #FFF;
		box-shadow: 0 2px 0 -1px #0072a3;
		transform: translate(-50%, -50%);
		background: url(${Sygnet_Impel}) no-repeat center center #FFF;
		background-size: 100% 100%;
	}

	${LoginPane} .ant-card-body {
	    padding-top: 44px;
	}
`;

const ForgottenPassword = (props) => {
    const [sent, setSent] = useState(false);
    const [error, setError] = useState(false);

	const onFormFinished = (values) => {
		API.resetPasswordToken(values.email).then(() => {

		}).catch(err => {
			setError(err);
		}).finally(() => {
			setSent(true);
		});
	};

	return (<Main>
		<Container>
			<Row style={{ height: '100vh' }}>
				<CenteredCol sm={20} md={16} lg={12}>
					<LoginPane>
						<LoginPaneStyles />
						<Card title={<>
							<LoginPaneTitle>Odzyskiwanie hasła</LoginPaneTitle>
						</>} bordered={false}>
							{ sent ? (
								error ? <Row>
									<Col span={24} style={{ textAlign: 'center', marginTop: 15 }}>
										<Alert message={error.getMessage()} type="error" style={{ marginBottom: 20 }} />

										<Link to="/login">
											Wróć do logowania
										</Link>
									</Col>
								</Row> : <Row>
									<Col span={24} style={{ textAlign: 'center', marginTop: 15 }}>
										<Alert message="Na wysłany adres e-mail zostały wysłane instrukcje resetowania hasła." type="success" style={{ marginBottom: 20 }} />

										<Link to="/login">
											Wróć do logowania
										</Link>
									</Col>
								</Row>
							) : <Form name="forgot-form" layout="vertical" onFinish={onFormFinished}>
								<Row>
									<Col span={24} style={{ textAlign: 'center', marginTop: 15 }}>
										<p>
											Wprowadź swój adres e-mail, który podałeś podczas zakładania konta, a my wyślemy Ci instrukcję jak zresetować Twoje hasło.
										</p>
									</Col>
								</Row>

								<Form.Item
									label="Adres e-mail:"
									name="email"
									rules={[{ required: true, message: 'Proszę wprowadzić adres e-mail' },{ type: 'email', message: 'Proszę wprowadzić poprawny adres e-mail' }]}>
									<Input />
								</Form.Item>

								<Row>
									<Col span={24} style={{ textAlign: 'right' }}>
										<Form.Item>
											<Button type="primary" htmlType="submit">
												Przypomnij hasło
											</Button>
										</Form.Item>
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<Link to="/login">
											Wróć do logowania
										</Link>
									</Col>
								</Row>
							</Form> }
						</Card>
					</LoginPane>
				</CenteredCol>
			</Row>
		</Container>
	</Main>);
};

export default ForgottenPassword;
