import styled from 'styled-components';

const Footer = styled.div.attrs(props => ({

}))`
	background: #fff;
	padding: 24px 50px;
	color: rgba(0, 0, 0, .85);
	font-size: 14px;
	box-shadow: 0 -3px 5px -5px rgba(0, 0, 0, .3);
	border-top: 1px solid #d5d5dd;
	margin-top: auto;
`;

export default Footer;
