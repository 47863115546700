import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useAuth } from '../../../contexts/User';
import PromiseQueue from '../../../utils/PromiseQueue';

import Subheader from '../../../components/Subheader';
import { Content as UIContent, Container, FlexBox, NotificationBell, ProfilePane } from '../../../helpers/UI';
import { convertTableToName, convertTableToNames, STATUS as APPSTATUS } from '../../../helpers/AppsHelper';

import AppCard from '../../../components/AppCard';

import Tour from 'reactour'

import { Dropdown, Menu, Button, Card, Table, Space, Alert, message, Skeleton, Empty, Result, Modal } from 'antd';

import { APIBackend as API } from '../../../api';

import createPersistedState from '../../../hooks/usePersistedState';
import {debounce} from "lodash";

const useContractorState = createPersistedState('contractor');
const useBranchState = createPersistedState('branch');

const TourTitle = styled.h1`
	font-size: 1.8rem;
	margin: 0;
	padding: 0;
	text-transform: uppercase;
`;

const AppGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
	grid-gap: 20px;
	align-items: stretch;
	// display: flex;
	// flex-wrap: wrap;
	// justify-content: flex-start;
	// margin: -25px -15px;

	& > div {
		// flex: 1 1 calc(360px);
		// margin: 25px 15px;
		// width: calc(360px);
		// max-width: calc(360px);
	}
`;

const benefitsWhitelist = ['apppears', 'apploans', 'appgrants', 'apprehabs', 'appextras', 'apptrips', 'appreftrips', 'appcamps', 'apprefcamps', 'apptourists', 'appreftourists', 'appmultis', 'apptickets', 'appinfants'];

const Dashboard = ({ ...props }) => {
	const auth = useAuth();

	const appListEl = React.useRef(null);

	const [contractor, setContractor] = useContractorState(null);
	const [branch, setBranch] = useBranchState(null);

	const [availableBenefits, setAvailableBenefits] = React.useState(null);
	const [data, setData] = React.useState(null);
	const [notices, setSystemNotices] = React.useState(null);
	const [isTourOpen, setIsTourOpen] = React.useState(false);

	const getAppList = () => {
		if(auth.profileLoaded) {
			if(auth.hasActiveProfile()) {
				API.dashboard().then(data => {
					setAvailableBenefits(data);
				}).catch(err => {
					setAvailableBenefits([]);
					console.log(err);
				});

				if(auth?.user?.id && auth?.contractor) {
					API.listApps(auth?.user?.id, auth?.contractor).then(data => {
						setData(Array.isArray(data) ? data : []);
					}).catch(err => {
						console.log(err)
					});
				}
			} else {
				PromiseQueue.enqueue(() => {
					return new Promise((resolve, reject) => {
						resolve();
					}).then(() => {
						if(!auth.afterFirstTour() && auth.hasAcceptedContractor(auth.contractor)) {
							setIsTourOpen(true);
						}
					});
				});
			}

			if(auth.contractor) {
				API.getSystemNotices(auth.contractor).then(data => {
					setSystemNotices(Array.isArray(data.data) ? data.data : []);
				}).catch(err => {
					setSystemNotices([]);
					console.log(err);
				});
			}

		}
	};

	const getAppListDebounced = debounce(() => getAppList(),800);

	React.useEffect(() => {
		getAppListDebounced();
	}, [auth.activeProfile, auth.profileLoaded]);

	const steps = [
		{
			content: <>
				<TourTitle>Witaj!</TourTitle>
				Zanim zaczniesz chcielibyśmy Cię wprowadzić, abyś płynnie poruszał(a) się po całej aplikacji.
				<br/>
				<Button style={{ marginTop: 15 }} size="small" onClick={() => {
					auth.setAfterFirstTour();
					setIsTourOpen(false);
				}}>Pomiń</Button>
			</>,
			stepInteraction: false,
		},
		{
			selector: '.zfss-no-profile',
			content: <>
				Aktualnie nie posiadasz żadnego aktywnego profilu, czyli zestawu Twoich danych, których potrzebujemy do weryfikacji i rozpatrzenia wniosków.
			</>,
			stepInteraction: false,
		},
		{
			selector: '.zfss-no-profile',
			content: <>
				Do utworzenia Twojego pierwszego profilu przejdziemy w późniejszym czasie - bez obaw, postaramy się oprowadzić Cię po aplikacji tak, aby wszystko było dla Ciebie jasne.
			</>,
			stepInteraction: false,
		},
		{
			selector: '.zfss-appgrid-no-profile',
			content: <>
				Tutaj po zaakceptowaniu Twojego profilu pojawią się wnioski, które będziesz mógł/mogła złożyć. Poniżej znajdziesz listę wniosków, które zostały przez Ciebie złożone jak i te, które są jeszcze w wersji roboczej.
			</>,
			stepInteraction: false,
		},
		{
			selector: '.zfss-profile-btn',
			content: <>
				Jeśli jesteś gotowy/a, przejdźmy do utworzenia Twojego pierwszego profilu.
				<br/>
				Wystarczy, że klikniesz w ten przycisk.
			</>
		},
	];

	const handleAppClick = (e) => {
		if(!auth.hasActiveProfile()) {
			e.preventDefault();
		}
	};

	const hasAnyPendingApp = () => {
		if(data) {
			return data.filter(el => [APPSTATUS.STA_REGISTERED, APPSTATUS.STA_BACK_TO_CORRECTION].includes(el?.staapp_id)).length > 0;
		}

		return false;
	};

	const hasAnyNotice = () => {
		if(notices) {
			return true
		}
		return false;
	};

	const acceptApp = (table, id) => {
		!['appinfants'].includes(table)
			? API.countApp(table, id).then(r => {
				Modal.confirm({
					title: 'Potwierdź akcję',
					content: <>Na pewno chcesz przekazać wniosek do akceptacji?{!['appgrants'].includes(table) ? <><br/>Wysokość dofinansowania wynosi: <strong>{r?.subsidy_total ?? '?'} PLN</strong></> : null}</>,
					onOk() {
						return API.acceptApp(table, id).then(() => {
							message.success('Wniosek został przekazany do akceptacji');
						}).catch(err => {
							message.error(err.getMessage());
						}).finally(() => {
							getAppList();
						});
					}
				});
			}).catch(err => {
				message.error(err.getMessage());
			})
			: Modal.confirm({
				title: 'Potwierdź akcję',
				content: <>Na pewno chcesz przekazać wniosek do akceptacji?</>,
				onOk() {
					return API.acceptApp(table, id).then(() => {
						message.success('Wniosek został przekazany do akceptacji');
					}).catch(err => {
						message.error(err.getMessage());
					}).finally(() => {
						getAppList();
					});
				}
			});
	};

	const deleteApp = (table, id) => {
		Modal.confirm({
			title: 'Potwierdź akcję',
			content: <>Na pewno chcesz usunąć wniosek?</>,
			onOk() {
				return API.deleteApp(table, id).then(() => {
					message.success('Wniosek został usunięty');
				}).catch(err => {
					message.error(err.getMessage());
				}).finally(() => {
					getAppList();
				});
			}
		});
	};

	const columns = [
		{
			title: 'Numer wniosku',
			dataIndex: 'number',
			key: 'number',
			render: (text, record, index) => {
				return text ?? '-';
			}
		},
		{
			title: 'Rodzaj wniosku',
			dataIndex: 'table',
			key: 'table',
			render: (text, record, index) => {
				return convertTableToName(text);
			}
		},
		{
			title: 'Status',
			dataIndex: 'staapp_name',
			key: 'staapp_name'
		},
		{
			title: 'Data złożenia',
			dataIndex: 'application_date',
			key: 'application_date'
		},
		{
			title: 'Akcje',
			key: 'actions',
			align: 'right',
			render: (text, record) => (
				<Space size="middle">
					{
						[1,4].includes(record?.staapp_id)
							? <Button size="small" onClick={() => acceptApp(record.table, record.id_table.split('-')[0])} className="app-btn-accept">Przekaż do akceptacji</Button>
							: null
					}
					{
						record?.can_delete
							? <Button danger size="small" onClick={() => deleteApp(record.table, record.id_table.split('-')[0])} className="app-btn-delete">Usuń</Button>
							: null
					}
					<Link to={`/${record.table.substr(3)}/${record.id_table.split('-')[0]}`} className="ant-btn ant-btn-primary ant-btn-sm app-btn-preview">Podgląd</Link>
				</Space>
			)
		}
	];

	return (
		<>
			<Subheader title="Pulpit" />

			{
				hasAnyNotice()
				? notices.map(function(notice, idx){
					return (<Alert type="info" key={idx} message={<>
						<strong>{ notice.title }</strong><br/>{ notice.content}</>} style={{ marginBottom: 20 }} />
					)
				})
				: null
			}

			{
				auth.hasActiveProfile()
					? null
					: <Alert className="zfss-no-profile" type="error" message={<>Nie posiadasz aktywnego profilu u wybranego kontrahenta. Przejdź do <Link to="/profile">ustawień profilu</Link> i stwórz profil lub aktywuj roboczy</>} style={{ marginBottom: 40 }} />
			}

			{
				auth.hasProfileIncomeUpdate()
					? <Alert className="zfss-no-profile" type="error" message={<>Wymagana aktualizacja dochodów w <Link to="/profile">profilu</Link>. Wprowadź nowe wartości i prześlij profil do akceptacji.</>} style={{ marginBottom: 40 }} />
					: null
			}

			{
				hasAnyPendingApp()
					? <Alert type="info" message={<>
						Posiadasz wnioski, które mogą zostać przekazane do akceptacji.<br/>
						Jeśli wniosek jest gotowy do przekazania możesz to zrobić na poniższej liście złożonych wniosków.
					</>} style={{ marginBottom: 40 }} />
					: null
			}

			{
				auth.hasActiveProfile()
					? (availableBenefits === null)
						? <Skeleton active />
						: availableBenefits.length
							? <AppGrid className="appgrid">
								{
									availableBenefits.map(el => {
										const benefit = convertTableToNames(el);

										return (
											<AppCard title={benefit.title} subtitle={benefit.subtitle} icon={benefit.icon} key={el + Math.random()}>
												<AppCard.BodyActions>
													{
														(!auth.hasActiveProfile() || !benefitsWhitelist.includes(el))
															? <Link to="/" className="ant-btn ant-btn-primary app-grid-btn-apply-disabled" disabled onClick={handleAppClick}>Złóż wniosek</Link>
															: <Link to={benefit.url} className="ant-btn ant-btn-primary app-grid-btn-apply">Złóż wniosek</Link>
													}
												</AppCard.BodyActions>
											</AppCard>
										);
									})
								}
							</AppGrid>
							: <Card>
								<Empty description={<>Nie znaleziono żadnych świadczeń, o które możesz wnioskować.<br/>Jeśli jesteś przekonany, że wystąpił błąd skontaktuj się z administratorem serwisu w celu wyjaśnienia.</>} />
							</Card>
					: <Card>
						<Result className="zfss-appgrid-no-profile"
							status="warning"
							title="Brak aktywnego profilu"
							subTitle="Do złożenia wniosku potrzebny jest aktywny profil"
							extra={<>
								<Link to="/profile" className="ant-btn ant-btn-primary ant-btn-sm zfss-profile-btn">
									Przejdź do ustawień profilu
								</Link>
							</>}
						/>
					</Card>
			}

			{
				auth.hasActiveProfile()
					? <Card title="Lista złożonych wniosków" loading={!data} style={{ marginTop: 50 }} className="applist">
						<div style={{ display: 'block', width: '100%' , overflowX: 'auto' }}>
							<Table dataSource={data} columns={columns} rowKey={() => Math.random()} />
						</div>
					</Card>
					: null
			}

			<Tour
				steps={steps}
				isOpen={isTourOpen}
				onRequestClose={() => setIsTourOpen(false)}
				closeWithMask={false}
				className="appTour"
			/>
		</>
	);
};

export default Dashboard;
