import React from "react";
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../contexts/User';

function PrivateRoute({ component: Component, ...rest }) {
	const auth = useAuth();

	return (
		<Route {...rest} render={props =>
			auth.token ? (
				(Component ?? null) ? (typeof Component === 'function' ? <Component {...props} /> : Component) : rest.children
			) : (
				<Redirect
					to={{
						pathname: '/login',
						state: { from: props.location }
					}} />
			)
		} />
	);
}

export default PrivateRoute;
