import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

import { useAuth } from '../../../contexts/User';

import { objectMap } from '../../../utils/functions/objectMap';

import { FlexBox } from '../../../helpers/UI';

import Subheader from '../../../components/Subheader';
import CardTabbed from '../../../components/CardTabbed';

import { Row, Col, Card, Form, Button, Input, Select, DatePicker, Switch, Menu, Skeleton, Empty, Tabs, Table, Tag, Modal } from 'antd';

import { backendApi } from '../../../config/paths';
import { APIBackend as API } from '../../../api';

import moment from 'moment';

import createPersistedState from '../../../hooks/usePersistedState';

const { Option } = Select;

const useUserStageState = createPersistedState('userStage');
const useContractorState = createPersistedState('contractor');

const FormItem = styled.div`
	display: flex;
	flex-direction: column;
`;

const FormItemLabel = styled.label`
	font-weight: 600;
`;

const FormItemValue = styled.span`

`;

const FileList = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
`;

const FileListItem = styled.li`

`;

const Dashboard_Profile_Active = ({ ...props }) => {
	const auth = useAuth();
	const history = useHistory();

	const [loading, setLoading] = React.useState(true);
	const [profileStatus, setProfileStatus] = React.useState(null);
	const [profileData, setProfileData] = React.useState(null);
	const [profileExists, setProfileExists] = React.useState(false);

	const [userStage, setUserStage] = useUserStageState(0);
	const [contractor, setContractor] = useContractorState(null);

	const [relationData, setRelationData] = React.useState(null);

	React.useEffect(() => {
		API.relations(['dctmaritials', 'dctfiletypes']).then(data => {
			setRelationData(data);
		}).catch(err => {
			console.log(err);
		});
	}, []);

	React.useEffect(() => {
		auth.hasAnyProfile().then(staprofile_id => {
			if(staprofile_id) {
				setProfileStatus(staprofile_id);
				API.getActiveProfile(auth.user?.id, contractor).then(data => {
					setProfileExists(true);
					setProfileData(data);
				}).catch(err => {
					if(!['PROFILE-NOT-FOUND'].includes(err.getCode())) {
						props.changePage('drafts');
					}
					setProfileExists(false);
				}).finally(() => {
					setLoading(false);
				});
			} else {
				setLoading(false);
			}
		}).catch(err => {
			setLoading(false);
			setProfileStatus(null);
		});
	}, []);

	const getMaritial = (id) => {
		if(relationData && relationData.hasOwnProperty('dctmaritials')) {
			return relationData.dctmaritials.filter(el => el.is_active).find(el => el.id === id);
		}

		return null;
	};

	const getFileType = (name) => {
		if(relationData && relationData.hasOwnProperty('dctfiletypes')) {
			return relationData.dctfiletypes.filter(el => el.is_active).find(el => el.name === name);
		}

		return null;
	};

	const familySource = profileData?.families;
	const familyColumns = [
		{
			title: 'Imię',
			dataIndex: 'first_name',
			key: 'first_name'
		},
		{
			title: 'Nazwisko',
			dataIndex: 'last_name',
			key: 'last_name'
		},
		{
			title: 'PESEL',
			dataIndex: 'pesel',
			key: 'pesel'
		},
		{
			title: 'Data urodzenia',
			dataIndex: 'birth_date',
			key: 'birth_date'
		},
		{
			title: 'Obecny stopień edukacji (dot. tylko dzieci)',
			dataIndex: 'dcteducation_name',
			key: 'dcteducation_name',
			render: (text, record) => text ?? '-'
		},
		{
			title: 'Obecny stopień niepełnosprawności (dot. tylko dzieci)',
			dataIndex: 'dctdisability_name',
			key: 'dctdisability_name',
			render: (text, record) => text ?? '-'
		},
		{
			title: 'Rodzaj pokrewieństwa',
			dataIndex: 'dctrelationship_name',
			key: 'dctrelationship_name',
			render: (text, record) => text ?? '-'
		},
		{
			title: 'Czy rodzic zmarł (dot. tylko dzieci)',
			dataIndex: 'is_parent_died',
			key: 'is_parent_died',
			render: (text, record, index) => {
				return text ? <Tag color="success">Tak</Tag> : <Tag color="error">Nie</Tag>;
			}
		},
		{
			title: 'Autoryzowany',
			dataIndex: 'is_authorized',
			key: 'is_authorized',
			render: (text, record, index) => {
				return text ? <Tag color="success">Tak</Tag> : <Tag color="error">Nie</Tag>;
			}
		},
	];

	const incomesSource = profileData?.incomeothers;
	const incomesColumns = [
		{
			title: 'Rodzaj dochodu',
			dataIndex: 'dctincometype_name',
			key: 'dctincometype_name'
		},
		{
			title: 'Wartość dochodu',
			dataIndex: 'quote',
			key: 'quote'
		},
	];

	const awaitingForAcceptationOrBackToCorrect = profileStatus && (+profileStatus === 6 || +profileStatus === 7) ? <>
		<Empty className="zfss-no-active-profile-card" image={Empty.PRESENTED_IMAGE_SIMPLE} description={
			<>
				Posiadasz już profil, który oczekuje na akceptację.
				<br />
				Prosimy o cierpliwość, dostaniesz powiadomienie o statusie swojego profilu za pomocą wiadomości email.
			</>
		} />
	</> : profileStatus && +profileStatus === 1 ? <>
		<Empty className="zfss-no-active-profile-card" image={Empty.PRESENTED_IMAGE_SIMPLE} description={
			<>
				Posiadasz już profil, który jest w trakcie wprowadzania.
				<br />
				Dokończ wprowadzanie lub aktualizację danych w profilu i wyślij profil do akceptacji.
				<br />
				<Button className="zfss-create-profile-btn" type="primary" size="small" onClick={() => {
					props.changePage('drafts');
				}} style={{ marginTop: 10, marginBottom: 10 }}>Lista profili</Button>
			</>
		} />
	</> : <>
		<Empty className="zfss-no-active-profile-card" image={Empty.PRESENTED_IMAGE_SIMPLE} description={
			<>
				Posiadasz już profil, który został zwrócony do korekty.
				<br />
				Prosimy o poprawienie danych w profilu oraz ponowne przekazanie do akceptacji.
				<br />
				<Button className="zfss-create-profile-btn" type="primary" size="small" onClick={() => {
					props.changePage('drafts');
				}} style={{ marginTop: 10, marginBottom: 10 }}>Lista profili</Button>
			</>
		} />
	</>;

	const emptyStateOrAwaitingForAcceptation = profileStatus && [1,4,6,7].includes(+profileStatus) ? awaitingForAcceptationOrBackToCorrect : <>
		<Empty className="zfss-no-active-profile-card" image={Empty.PRESENTED_IMAGE_SIMPLE} description={
			<>
				Brak aktywnego profilu dla wybranego kontrahenta.
				<br />
				Jeśli profil został przekazany do akceptacji oczekuj na jego zaakceptowanie, w innym przypadku
				<br />
				<Button className="zfss-create-profile-btn" type="primary" size="small" onClick={() => {
					props.changePage('create');
					if(props.tourRef?.current) {
						props.tourRef.current.gotoStep(7);
					}
				}} style={{ marginTop: 10, marginBottom: 10 }}>Utwórz profil</Button>
				<br />
				lub przekaż do akceptacji istniejący już profil.
			</>
		} />
	</>;

	return (
		<>
			{
				(loading || !relationData)
					? <Skeleton active />
					: profileExists
						? <>
							<FlexBox justifyEnd>
								<Button type="primary" onClick={() => {
									Modal.confirm({
										title: 'Potwierdź akcję',
										content: <>
											Czy jesteś pewny/a, że chcesz zmodyfikować aktywny profil?
											<br/><br/>
											<b>UWAGA:</b> Edycja profilu spowoduje zablokowanie możliwości składania wniosków na czas akceptacji i anulowanie wszystkich wniosków złożonych na modyfikowanym profilu.
										</>,
										okText: 'Tak, edytuj',
										onOk() {
											props.changePage('edit', {
												id: profileData.id
											});
										}
									});
								}}>Edytuj profil</Button>
							</FlexBox>

							<Tabs
								size="large"
								// forceRender
							>
								<Tabs.TabPane tab="Dane podstawowe" key="general">
									<Row gutter={[16, 24]} style={{ marginTop: 15 }}>
										<Col sm={24} md={12}>
											<FormItem>
												<FormItemLabel>
													Stan cywilny
												</FormItemLabel>
												<FormItemValue>
													{getMaritial(profileData?.dctmaritial_id)?.name ?? '(brak)'}
												</FormItemValue>
											</FormItem>
										</Col>

										<Col sm={24} md={12}>
											<FormItem>
												<FormItemLabel>
													Data urodzenia
												</FormItemLabel>
												<FormItemValue>
													{profileData?.birth_date ?? '(brak)'}
												</FormItemValue>
											</FormItem>
										</Col>

										<Col sm={24} md={12}>
											<FormItem>
												<FormItemLabel>
													Numer ewidencyjny
												</FormItemLabel>
												<FormItemValue>
													{profileData?.registration_number ?? '(brak)'}
												</FormItemValue>
											</FormItem>
										</Col>

										<Col sm={24} md={12}>
											<FormItem>
												<FormItemLabel>
													Symbol komórki organizacyjnej
												</FormItemLabel>
												<FormItemValue>
													{profileData?.department_symbol ?? '(brak)'}
												</FormItemValue>
											</FormItem>
										</Col>

										<Col sm={24} md={12}>
											<FormItem>
												<FormItemLabel>
													Ulica i nr domu/mieszkania
												</FormItemLabel>
												<FormItemValue>
													{profileData?.street ?? '(brak)'}
												</FormItemValue>
											</FormItem>
										</Col>

										<Col sm={24} md={5}>
											<FormItem>
												<FormItemLabel>
													Kod pocztowy
												</FormItemLabel>
												<FormItemValue>
													{profileData?.zip ?? '(brak)'}
												</FormItemValue>
											</FormItem>
										</Col>

										<Col sm={24} md={7}>
											<FormItem>
												<FormItemLabel>
													Miejscowość
												</FormItemLabel>
												<FormItemValue>
													{profileData?.town ?? '(brak)'}
												</FormItemValue>
											</FormItem>
										</Col>

										<Col span={24}>
											<FormItem>
												<FormItemLabel>
													Numer rachunku bankowego
												</FormItemLabel>
												<FormItemValue>
													{profileData?.bank_account ?? '(brak)'}
												</FormItemValue>
											</FormItem>
										</Col>

										<Col sm={24} md={12}>
											<FormItem>
												<FormItemLabel>
													Numer kontaktowy
												</FormItemLabel>
												<FormItemValue>
													{profileData?.phone ?? '(brak)'}
												</FormItemValue>
											</FormItem>
										</Col>

										<Col sm={24} md={12}>
											<FormItem>
												<FormItemLabel>
													Emeryt
												</FormItemLabel>
												<FormItemValue>
													{profileData?.is_pension_declaration ? 'TAK' : 'NIE' ?? '(brak)'}
												</FormItemValue>
											</FormItem>
										</Col>
									</Row>
								</Tabs.TabPane>

								<Tabs.TabPane tab="Rodzina" key="family">
									<FormItem>
										<FormItemLabel>
											Rodzina
										</FormItemLabel>
										<FormItemValue>
											<div style={{ display: 'block', width: '100%' , overflowX: 'auto' }}>
												<Table dataSource={familySource} columns={familyColumns} />
											</div>
										</FormItemValue>
									</FormItem>
								</Tabs.TabPane>

								<Tabs.TabPane tab="Dochody" key="incomes">
									<Row gutter={[16, 24]}>
										<Col sm={24} md={12}>
											<FormItem>
												<FormItemLabel>
													Twoje dochody
												</FormItemLabel>
												<FormItemValue>
													{profileData?.client_income ?? '(brak)'}
												</FormItemValue>
											</FormItem>
										</Col>

										<Col sm={24} md={12}>
											<FormItem>
												<FormItemLabel>
													Dochody współmałżonka
												</FormItemLabel>
												<FormItemValue>
													{profileData?.spouse_income ?? '(brak)'}
												</FormItemValue>
											</FormItem>
										</Col>

										<Col span={24}>
											<FormItem>
												<FormItemLabel>
													Dochody
												</FormItemLabel>
												<FormItemValue>
													<div style={{ display: 'block', width: '100%' , overflowX: 'auto' }}>
														<Table dataSource={incomesSource} columns={incomesColumns} />
													</div>
												</FormItemValue>
											</FormItem>
										</Col>

										<Col sm={24} md={12}>
											<FormItem>
												<FormItemLabel>
													Średnie dochody
												</FormItemLabel>
												<FormItemValue>
													{profileData?.manual_income ?? profileData?.average_incomes ?? '(brak)'}
												</FormItemValue>
											</FormItem>
										</Col>
									</Row>
								</Tabs.TabPane>

								<Tabs.TabPane tab="Załączniki" key="attachments">
									<Row gutter={[16, 24]}>
										<Col sm={24} md={12}>
											<FormItem>
												<FormItemLabel>
													Wnioski
												</FormItemLabel>
												<FormItemValue>
													<FileList>
														{
															(profileData?.attachments && profileData.attachments.filter(file => file.dctfiletype_id === getFileType('Wnioski')?.id).length)
																? profileData.attachments.filter(file => file.dctfiletype_id === getFileType('Wnioski')?.id).map(file => (
																	<FileListItem key={['fileList_item', file.uid]}>
																		<a href={`${backendApi}/storage/app${file.file_path}`}>
																			{file.file_name}
																		</a>
																	</FileListItem>
																))
																: '(brak)'
														}
													</FileList>
												</FormItemValue>
											</FormItem>
										</Col>

										<Col sm={24} md={12}>
											<FormItem>
												<FormItemLabel>
													Zaświadczenia
												</FormItemLabel>
												<FormItemValue>
													<FileList>
														{
															(profileData?.attachments && profileData.attachments.filter(file => file.dctfiletype_id === getFileType('Zaświadczenia')?.id).length)
																? profileData.attachments.filter(file => file.dctfiletype_id === getFileType('Zaświadczenia')?.id).map(file => (
																	<FileListItem key={['fileList_item', file.uid]}>
																		<a href={`${backendApi}/storage/app${file.file_path}`}>
																			{file.file_name}
																		</a>
																	</FileListItem>
																))
																: '(brak)'
														}
													</FileList>
												</FormItemValue>
											</FormItem>
										</Col>
									</Row>
								</Tabs.TabPane>
							</Tabs>
						</>
						: emptyStateOrAwaitingForAcceptation
			}
		</>
	);
};

export default Dashboard_Profile_Active;
