import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { UserContext } from './';

import { APIBackend as API } from '../../api';

import createPersistedState from '../../hooks/usePersistedState';
import { TYPE as FILE_TYPE } from '../../helpers/FilesHelper';

import { useCookies } from 'react-cookie';

import moment from 'moment';

const PERMISSIONS = {
	USER: 0,
	EXTERNAL_CLIENT: 5,
	COMMITTEE: [30, 35],
	OPERATOR: 60,
	ADMIN: 70
};

const useBranchState = createPersistedState('branch');
const useContractorState = createPersistedState('contractor');
const useUserIdState = createPersistedState('lastUserId');

const UserProvider = ({ children, ...props }) => {
	const [token, setToken] = React.useState(localStorage.getItem('token') ?? null);
	const [user, setUser] = React.useState();
	const [error, setError] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [profileLoaded, setProfileLoaded] = React.useState(false);

	const [contractor, setContractor] = useContractorState(null);
	const [branch, setBranch] = useBranchState(null);
	const [lastUserID, setLastUserID] = useUserIdState(null);

	const [activeProfile, setActiveProfile] = React.useState(null);
	const [forceIncomeProfile, setForceIncomeProfile] = React.useState(false);
	const [contractorRegulation, setContractorRegulation] = React.useState(null);
	const [contractorGDPR, setContractorGDPR] = React.useState(null);

	const [cookies, setCookie, removeCookie] = useCookies(['zfss-'+token+'-fl', 'zfss-'+token+'-ft']);

	const history = useHistory();
	const location = useLocation();

	const can = (permission_code) => {
		if(!user) return false;

		if(!Array.isArray(permission_code)) permission_code = [permission_code];

		return permission_code.some(el => user?.permission_code >= el);
	};

	const hasActiveProfile = () => {
		return activeProfile && (activeProfile?.id ? true : false);
	};

	const hasProfileIncomeUpdate = () => {
		return forceIncomeProfile;
	};

	const isFirstLogin = () => {
		return cookies.hasOwnProperty('zfss-'+token+'-fl')
			? cookies['zfss-'+token+'-fl'] === '1'
				? true
				: false
			: false;
	};

	const afterFirstTour = () => {
		if(!profileLoaded) return true;

		return cookies.hasOwnProperty('zfss-'+token+'-ft')
			? (cookies['zfss-'+token+'-ft'] === '1')
				? true
				: false
			: false;
	};

	const setAfterFirstTour = () => {
		setCookie('zfss-'+token+'-ft', 1, {
			expires: moment().add(30, 'days').toDate()
		});
	};

	const hasAcceptedContractor = (contractor_id) => {
		return cookies.hasOwnProperty('zfss-'+token+'-rac-'+contractor_id)
			? cookies['zfss-'+token+'-rac-'+contractor_id] === '1'
				? true
				: false
			: false;
	};

	const setAcceptedContractor = (contractor_id, value = 0) => {
		setCookie('zfss-'+token+'-rac-'+contractor_id, value, {
			expires: moment().add(30, 'days').toDate()
		});
		window.location.reload();
	};

	const hasAnyProfile = () => {
		if(user?.id) {
			return API.hasAnyProfile(user.id, contractor);
		}

		return Promise.resolve(false);
	};

	const fetchActiveProfile = () => {
		if(user?.id) {
			setLoading(true);
			API.getActiveProfile(user.id, contractor).then(data => {
				setActiveProfile(data);
				setAfterFirstTour(true);
			}).catch(err => {
				setActiveProfile(false);
				if(err.getStatus() === 404 && ['PROFILE-INCOME-UPDATE'].includes(err.getCode())) {
					setForceIncomeProfile(true);
				} else {
					setForceIncomeProfile(null);
				}
			}).finally(() => {
				setLoading(false);
				setProfileLoaded(true);
			})
		}
	};

	const getRegulations = () => {
		if(user && contractor) {
			const _contractor = user.contractors.find(el => el?.id === contractor);
			if(_contractor && _contractor.hasOwnProperty('attachments')) {
				if(_contractor?.attachments.length && _contractor.attachments.filter(el => el?.dctfiletype_id === FILE_TYPE.REGS).length) {
					setContractorRegulation(_contractor.attachments.filter(el => el?.dctfiletype_id === FILE_TYPE.REGS)[0]?.file_path);
				}

				if(_contractor?.attachments.length && _contractor.attachments.filter(el => el?.dctfiletype_id === FILE_TYPE.GDPR).length) {
					const gdpr = _contractor.attachments.find(el => el?.dctfiletype_id === FILE_TYPE.GDPR);
					setContractorGDPR(`${gdpr.file_path.split('/').slice(0, -1).join('/')}/${encodeURIComponent(gdpr.file_name)}`);
				}
			}
		}
	};

	React.useEffect(() => {
		if(error) setError(null);
	}, [location.pathname]);

	React.useEffect(() => {
		fetchActiveProfile();
	}, [user]);

	React.useEffect(() => {
		getRegulations();
	}, [user, contractor, setContractor]);

	React.useEffect(() => {

	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const authenticate = () => {
		setLoading(true);

		return new Promise((resolve, reject) => {
			if(token) {
				API.getMe().then(data => {
					setUser(data);
					fetchActiveProfile();
					getRegulations();
					resolve(data);
				}).catch(err => {
					logout().then(() => {
						reject();
					}).catch(err => {
						reject();
					}).finally(() => {
						localStorage.removeItem('token');
						setToken(null);
					});
				}).finally(() => {
					setLoading(false);
				});
			} else {
				setLoading(false);
				reject(false);
			}
		});
	};

	const login = (values) => {
		setLoading(true);

		return new Promise((resolve, reject) => {
			API.login(values.email, values.password).then(data => {
				localStorage.setItem('token', data.user.id);
				setToken(data.user.id);
				setUser(data.user);
				if(!cookies.hasOwnProperty('zfss-fl')) {
					setCookie('zfss-fl', 1);
				} else {
					setCookie('zfss-fl', 0);
				}

				if(lastUserID !== data.user.id) {
					setContractor(null);
					setBranch(null);
				}

				if(data.user.contractors.length === 1) {
					setContractor(data.user.contractors[0].id);

					if(data.user.contractors[0].branches.length === 1) {
						setBranch(data.user.contractors[0].branches[0].id);
					} else {
						history.push('/contractor');
					}
				}

				setLastUserID(data.user.id);

				resolve(data);
			}).catch(err => {
				setUser(null);
				reject(err);
			}).finally(() => {
				setLoading(false);
			});
		});
	};

	const register = (values) => {
		setLoading(true);

		return new Promise((resolve, reject) => {
			API.register(values).then(data => {
				resolve(data);
			}).catch(err => {
				reject(err);
			}).finally(() => {
				setLoading(false);
			});
		});
	};

	const logout = () => {
		setLoading(true);

		return new Promise((resolve, reject) => {
			API.logout().then(data => {
				localStorage.removeItem('token');
				setToken(null);
				setUser(null);
				resolve();
			}).catch(err => {
				reject(err);
			}).finally(() => {
				setLoading(false);
			});
		});
	};

	const memoedValue = React.useMemo(() => ({
		token,
		user,
		loading,
		profileLoaded,
		error,

		contractor,
		branch,
		activeProfile,
		contractorRegulation,
		contractorGDPR,

		authenticate,
		login,
		register,
		logout,
		hasActiveProfile,
		hasProfileIncomeUpdate,
		fetchActiveProfile,
		isFirstLogin,
		afterFirstTour,
		setAfterFirstTour,
		hasAcceptedContractor,
		setAcceptedContractor,
		can,
		hasAnyProfile,
	}), [token, user, loading, profileLoaded, setProfileLoaded, error, contractor, setContractor, branch, setBranch, activeProfile, cookies, contractorRegulation, contractorGDPR, hasAcceptedContractor, setAcceptedContractor, afterFirstTour, setAfterFirstTour, setContractor, setBranch]);

	return (
		<UserContext.Provider value={memoedValue}>
			{children}
		</UserContext.Provider>
	);
};

export default UserProvider;

export function useAuth() {
	return React.useContext(UserContext);
};

export { PERMISSIONS };
