import iconDefault from './default.png';
import iconCamps from './camps.png';
import iconExtras from './extras.png';
import iconInfants from './infants.png';
import iconMoney from './money.png';
import iconMultis from './multis.png';
import iconPears from './pears.png';
import iconRehabs from './rehabs.png';
import iconTickets from './tickets.png';
import iconTourists from './tourists.png';
import iconTrips from './trips.png';

export default {
	camps: iconCamps,
	default: iconDefault,
	extras: iconExtras,
	infants: iconInfants,
	money: iconMoney,
	multis: iconMultis,
	pears: iconPears,
	rehabs: iconRehabs,
	tickets: iconTickets,
	tourists: iconTourists,
	trips: iconTrips
};
