import { APIBackend as API } from '../../api';

const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const STATUS = {
	STA_REGISTERED: 1,
	STA_IN_ACCEPTATION: 2,
	STA_ACCEPTED: 3,
	STA_BACK_TO_CORRECTION: 4,
	STA_REFUSED: 5,
	STA_IN_COMMISSION: 6,
	STA_CANCELED: 7,
	STA_OUTSIDE_SERVICE: 8
};

export const hasStatus = (sid, statuses) => {
	statuses = Array.isArray(statuses) ? statuses : [statuses];

	return statuses.includes(sid ?? null);
};

export const handleCountClick = (appType, id) => {
	if(!API.hasOwnProperty(`count${capitalizeFirstLetter(appType)}`)) return Promise.reject({
		errcode: 'STA-UNKNOWN-TYPE',
		message: 'Nieznany rodzaj świadczenia',
		appType
	});

	return API[`count${capitalizeFirstLetter(appType)}`](id);
};

export const convertTableToName = (table) => {
	switch(table) {
		case 'apppears':
			return 'Wczasy pod gruszą';
		case 'appmultis':
			return 'Karty Sportowe';
		case 'appcamps':
			return 'Wypoczynek dzieci';
		case 'appgrants':
			return 'Zapomogi';
		case 'appinfants':
			return 'Przedszkola i żłobki';
		case 'apptickets':
			return 'Bilety i karnety';
		case 'apptrips':
			return 'Wyjazdy';
		case 'apptourists':
			return 'Świadczenia TUR-REK';
		case 'apploans':
			return 'Pożyczki';
		case 'appextras':
			return 'Świadczenia dodatkowe';
		case 'appreftourists':
			return 'Świadczenia TUR-REK - refundacja';
		case 'appreftrips':
			return 'Wyjazdy - refundacja';
		case 'apprefcamps':
			return 'Wypoczynek dzieci - refundacja';
		case 'apprehabs':
			return 'Zajęcia rekreacyjne';
		case 'appinfantpayments':
			return 'Wpłaty do przedszkoli/żłobków';
		default:
			return table;
	};
};

export const convertTableToNames = (table) => {
	switch(table) {
		case 'apppears':
			return {
				title: 'Wczasy',
				subtitle: 'pod gruszą',
				icon: 'pears',
				url: '/pears',
				description: ''
			};
		case 'appmultis':
			return {
				title: 'Karty',
				subtitle: 'Sportowe',
				icon: 'multis',
				url: '/multis',
				description: ''
			};
		case 'appcamps':
			return {
				title: 'Wypoczynek dzieci',
				subtitle: 'dofinansowanie',
				icon: 'camps',
				url: '/camps',
				description: ''
			};
		case 'appgrants':
			return {
				title: 'Zapomogi',
				subtitle: null,
				icon: 'money',
				url: '/grants',
				description: ''
			};
		case 'appinfants':
			return {
				title: 'Przedszkola',
				subtitle: 'i żłobki',
				icon: 'infants',
				url: '/infants',
				description: ''
			};
		case 'apptickets':
			return {
				title: 'Bilety',
				subtitle: 'i karnety',
				icon: 'tickets',
				url: '/tickets',
				description: ''
			};
		case 'apptrips':
			return {
				title: 'Wyjazdy',
				subtitle: 'dofinansowanie',
				icon: 'trips',
				url: '/trips',
				description: ''
			};
		case 'apptourists':
			return {
				title: 'Świadczenia TUR-REK',
				subtitle: 'dofinansowanie',
				icon: 'tourists',
				url: '/tourists',
				description: ''
			};
		case 'apploans':
			return {
				title: 'Pożyczki',
				subtitle: null,
				icon: 'money',
				url: '/loans',
				description: ''
			};
		case 'appextras':
			return {
				title: 'Świadczenia',
				subtitle: 'Dodatkowe',
				icon: 'extras',
				url: '/extras',
				description: ''
			};
		case 'appreftourists':
			return {
				title: 'Świadczenia TUR-REK',
				subtitle: 'refundacja',
				icon: 'tourists',
				url: '/reftourists',
				description: ''
			};
		case 'appreftrips':
			return {
				title: 'Wyjazdy',
				subtitle: 'refundacja',
				icon: 'trips',
				url: '/reftrips',
				description: ''
			};
		case 'apprefcamps':
			return {
				title: 'Wypoczynek dzieci',
				subtitle: 'refundacja',
				icon: 'camps',
				url: '/refcamps',
				description: ''
			};
		case 'apprehabs':
			return {
				title: 'Zajęcia rekreacyjne',
				subtitle: null,
				icon: 'rehabs',
				url: '/rehabs',
				description: ''
			};
		default:
			return table;
	};
};
