import { useState, useEffect, useRef, useCallback } from 'react';
import useEventListener from '@use-it/event-listener';

import createGlobalState from './createGlobalState';

const usePersistedState = (initialState, key, { get, set }) => {
	const globalState = useRef(null);
	const [state, setState] = useState(() => get(key, initialState));

	useEventListener('storage', ({ key: k, newValue }) => {
		if(k === key) {
			const newState = JSON.parse(newValue);
			if(state !== newState) {
				setState(newState);
			}
		}
	});

	useEffect(() => {
		globalState.current = createGlobalState(key, setState, initialState);

		return () => {
			globalState.current.unregister();
		};
	}, [initialState, key]);

	const persistentSetState = useCallback(newState => {
		const newStateValue = typeof newState === 'function' ? newState(state) : newState;

		set(key, newStateValue);
		setState(newStateValue);

		globalState.current.emit(newState);
	}, [state, set, key]);

	return [state, persistentSetState];
};

export default usePersistedState;
