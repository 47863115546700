import { v4 as uuid4 } from 'uuid';

class ZFSS_Error {
	_id = null;
	_code = null;
	_status = 500;
	_statusText;
	_message;
	_rawMessage;
	_rawSystemMessage;
	_line;
	_column;
	_file;
	_name;

	constructor(err) {
		this._id = uuid4();
		this._file = err?.fileName;
		this._line = err?.lineNumber;
		this._column = err?.columnNumber;
		this._name = err.name;
		this._rawMessage = err?.message;

		if(err.hasOwnProperty('response')) {
			this._parseResponse(err.response);
		}
	}

	_parseResponse(res) {
		if(res) {
			this._status = res?.status ?? 500;
			this._statusText = res?.statusText ?? 'Internal Server Error';

			if(res.hasOwnProperty('data')) {
				this._code = res.data?.errcode ?? 'ERR-UNDEFINED';
				this._message = res.data?.message ?? '';
				this._rawSystemMessage = res.data?.message ?? '(brak)';
			}
		}
	}

	getId() {
		return this._id;
	}

	getCode() {
		return this._code;
	}

	getStatus() {
		return this._status;
	}

	getStatusText() {
		return this._statusText;
	}

	getMessage() {
		return this._message;
	}

	setMessage(message) {
		this._message = message;
	}

	getRawMessage() {
		return this._rawMessage;
	}

	getSystemMessage() {
		return this._rawSystemMessage;
	}
}

export default ZFSS_Error;
