class PromiseQueue {
	static queue = [];
	static working = false;
	static stop = false;

	static enqueue(promise) {
		return new Promise((resolve, reject) => {
			this.queue.push({
				promise,
				resolve,
				reject
			});

			this.dequeue();
		});
	}

	static enqueueForce(promise) {
		return new Promise((resolve, reject) => {
			this.queue.unshift({
				promise,
				resolve,
				reject
			});

			this.dequeue();
		});
	}

	static dequeue() {
		if(this.working) return false;

		if(this.stop) {
			this.queue = [];
			this.stop = false;
			return;
		}

		const item = this.queue.shift();

		if(!item) return false;

		try {
			this.working = true;

			item.promise().then(response => {
				item.resolve(response);
			}).catch(err => {
				item.reject(err);
			}).finally(() => {
				this.working = false;
				this.dequeue();
			});
		} catch(err) {
			this.working = false;
			item.reject(err);
			this.dequeue();
		}

		return true;
	}

	static cancel() {
		this.stop = true;
	}
}

export default PromiseQueue;
