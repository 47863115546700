import React from 'react';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';

import { useAuth } from '../../../contexts/User';

import Subheader from '../../../components/Subheader';
import CardTabbed from '../../../components/CardTabbed';
import DraggerUpload from '../../../components/DraggerUpload';

import { InboxOutlined } from '@ant-design/icons';
import { Row, Col, Card, Form, Button, Input, InputNumber, Select, DatePicker, Switch, message, Space, Checkbox, Empty, Modal } from 'antd';

import { APIBackend as API } from '../../../api';

import moment from 'moment';

import createPersistedState from '../../../hooks/usePersistedState';
import {onApplyHook} from "../../../common/on-apply-hook/on-apply.hook";

const { Option } = Select;

const useUserStageState = createPersistedState('userStage');
const useContractorState = createPersistedState('contractor');

const FlexSpace = styled(Space)`
	display: flex;
	align-items: baseline;
	margin-bottom: 8px;

	& .ant-space-item {
		flex: 1;

		&:last-child {
			flex: 0;
		}
	}
`;

const AntTable = styled.div`
	& .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
		display: none;
	}
`;

const Dashboard_Tickets = ({ ...props }) => {
	const auth = useAuth();
	const history = useHistory();

	const [form] = Form.useForm();

	const [profileData, setProfileData] = React.useState({});
	const [loading, setLoading] = React.useState(true);
	const [saving, setSaving] = React.useState(false);
	const [loadingUser, setLoadingUser] = React.useState(true);
	const [relationData, setRelationData] = React.useState(null);
	const [currentTab, setCurrentTab] = React.useState('general');
	const [changes, setChanges] = React.useState({});

	const [userStage, setUserStage] = useUserStageState(0);
	const [contractor, setContractor] = useContractorState(null);

	const [attachments, setAttachments] = React.useState({
		list: [],
		add: [],
		del: []
	});

	React.useEffect(() => {
		API.getActiveProfile(auth.user?.id, contractor).then(data => {
			setProfileData(data);
		}).catch(err => {

		}).finally(() => {
			setLoading(false);
		});

		API.relations(['appticketmembers', 'dctikotypes', 'dctirotypes', 'offikos', 'offiros', 'ikoprices', 'iroprices', 'dctfiletypes']).then(data => {
			setRelationData(data);
		}).catch(err => {
			console.log(err);
		});
	}, []);

	React.useEffect(() => {
		setLoadingUser(false);
	}, [auth, auth.user]);

	const onSave = async (values) => {
		setSaving(true);

		if(!(await onApplyHook('tickets'))) {
			setSaving(false);
			return false;
		}

		Object.keys(values).forEach(el => {
			if(['application_date', 'payment_date', 'from', 'to'].includes(el)) {
				values[el] = values[el] ? values[el].format('YYYY-MM-DD') : null;
			} else if(['attachments'].includes(el)) {
				if(attachments.hasOwnProperty('add')) {
					attachments.add.forEach(el => {
						if(el) {
							values[`files[${el.category}/${el.name}]`] = el.file;
						}
					});
				}

				if(attachments.hasOwnProperty('del')) {
					attachments.del.forEach(el => {
						if(el) {
							values[`delete[${el.category}/${el.name}]`] = el.uid;
						}
					});
				}

				delete values[el];
			} else if(['appticketmembers'].includes(el)) {
				const newValues = values[el] ?? [];

				if(values?.client_is_member) {
					newValues.push({
						is_member: 1,
						ikoprice_id: values?.client_ikoprice_id ?? null,
						iroprice_id: values?.client_iroprice_id ?? null,
						tickets_count: values?.client_tickets_count ?? null,
					});
				}

				values[el] = JSON.stringify(newValues);
			}
		});

		const _data = new FormData();

		for(let k in values) {
			let val = values[k];

			if(val !== null && val !== undefined && val !== '') {
				_data.append(k, val);
			}
		}

		API.applyTickets(_data).then(r => {
            message.success('Wniosek został zapisany');
			Modal.confirm({
				title: 'Wniosek został zapisany.',
				content: <>Chcesz przekazać wniosek do akceptacji?</>,
				okText: 'Przekaż do akceptacji',
				cancelText: 'Nie',
				onOk() {
					return API.countApp('apptickets', r.id).then((res) => {
                        message.success(`Wysokość dofinansowania: ${res?.subsidy_total ?? 0} PLN`, 3);
						return API.acceptApp('apptickets', r.id).then(() => {
							message.success('Wniosek został przekazany do akceptacji');
						}).catch(err => {
							message.error(err.getMessage());
						}).finally(() => {
							history.push('/');
						});
					}).catch(err => {
						message.error(err.getMessage());
					}).finally(() => {
                        history.push('/');
                    });
				},
                onCancel() {
					history.push('/');
				}
			});
		}).catch(err => {
			message.error(err.getMessage());
			console.log(err);
		}).finally(() => {
			setSaving(false);
		});
	};

	const getRelation = (name) => {
		if(relationData && relationData.hasOwnProperty(name)) {
			if(relationData[name]) {
				return relationData[name].filter(el => el?.is_active ?? true);
			}
		}

		return [];
	};

	const onChange = (field, allFields) => {
		setChanges(allFields);

		const keys = Object.keys(field);

		if(!keys.length) return;

		const value = field[keys[0]];
	};

	const tabs = [
		{ key: 'general', tab: 'Dane podstawowe' },
		{ key: 'members', tab: 'Uczestnicy' },
		{ key: 'attachments', tab: 'Załączniki' }
	];

	const tabContent = {
		general: <>
			<Row gutter={[16, 24]}>
				<Col sm={24} md={12}>
					<Form.Item
						label="Wnioskujący"
						name="profile_id"
						initialValue={profileData.id}
					>
						<Select disabled>
							<Option value={profileData.id}>{auth.user?.full_name}</Option>
						</Select>
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Data złożenia wniosku"
						name="application_date"
						initialValue={moment()}
					>
						<DatePicker style={{ width: '100%' }} disabled />
					</Form.Item>
				</Col>

				<Col span={24}>
					<Form.Item
						label="Rodzaj"
						name="type"
						rules={[
							{
								required: true,
								message: 'Proszę wybrać rodzaj świadczenia'
							}
						]}
					>
						<Select>
							<Option value="K">Imprezy Kulturalno-Oświatowe</Option>
							<Option value="R">Imprezy Rekreacyjno-Oświatowe</Option>
						</Select>
					</Form.Item>
				</Col>

				{
					changes?.type === 'K'
						? <>
							<Col sm={24} md={12}>
								<Form.Item
									label="Rodzaj placówki"
									name="dctikotype_id"
									rules={[
										{
											required: true,
											message: 'Proszę wybrać rodzaj placówki'
										}
									]}
								>
									<Select
										showSearch
										optionFilterProp="children"
									>
										{getRelation('dctikotypes').map(el => {
											return (
												<Option
													key={['dctikotype', el.id]}
													value={el.id}
												>
													{el.name}
												</Option>
											);
										})}
									</Select>
								</Form.Item>
							</Col>

							<Col sm={24} md={12}>
								<Form.Item
									label="Oferta"
									name="offiko_id"
								>
									<Select
										showSearch
										optionFilterProp="children"
									>
										{getRelation('offikos').map(el => {
											return (
												<Option
													key={['offiko', el.id]}
													value={el.id}
												>
													{el.name} ({el.dctikotype_name})
												</Option>
											);
										})}
									</Select>
								</Form.Item>
							</Col>
						</>
						: changes?.type === 'R'
							? <>
								<Col sm={24} md={12}>
									<Form.Item
										label="Rodzaj placówki"
										name="dctirotype_id"
										rules={[
											{
												required: true,
												message: 'Proszę wybrać rodzaj placówki'
											}
										]}
									>
										<Select
											showSearch
											optionFilterProp="children"
										>
											{getRelation('dctirotypes').map(el => {
												return (
													<Option
														key={['dctirotype', el.id]}
														value={el.id}
													>
														{el.name}
													</Option>
												);
											})}
										</Select>
									</Form.Item>
								</Col>

								<Col sm={24} md={12}>
									<Form.Item
										label="Oferta"
										name="offiro_id"
									>
										<Select
											showSearch
											optionFilterProp="children"
										>
											{getRelation('offiros').map(el => {
												return (
													<Option
														key={['offiro', el.id]}
														value={el.id}
													>
														{el.name} ({el.dctirotype_name})
													</Option>
												);
											})}
										</Select>
									</Form.Item>
								</Col>
							</>
							: null
				}

				<Col sm={24} md={12}>
					<Form.Item
						label="Kwota podlegająca dofinansowaniu"
						name="subsidy_value"
					>
						<InputNumber precision={2} decimalSeparator="." formatter={value => value.replace(/[^0-9\.]+/g, '')} style={{ width: '100%' }} />
					</Form.Item>
				</Col>
			</Row>
		</>,
		members: <>
			<Row gutter={[16, 24]}>
				{
					changes?.type === 'K'
						? <>
							<Col span={24}>
								<AntTable className="ant-table">
									<div className="ant-table-container">
										<div className="ant-table-content">
											<table style={{ tableLayout: 'auto' }}>
												<thead className="ant-table-thead">
													<tr className="ant-table-row">
														<th className="ant-table-cell">
															<strong>Uczestnik</strong>
														</th>
														<th className="ant-table-cell">
															<strong>Pozycja cennika</strong>
														</th>
														<th className="ant-table-cell">
															<strong>Ilość biletów</strong>
														</th>
														<th className="ant-table-cell">
															<strong>Korzysta ze świadczenia?</strong>
														</th>
													</tr>
												</thead>
												<tbody className="ant-table-tbody">
													<tr className="ant-table-row" valign="baseline">
														<td className="ant-table-cell">
															{auth.user?.full_name ?? '?'}
														</td>
														<td className="ant-table-cell">
															<Form.Item
																name="client_ikoprice_id"
															>
																<Select placeholder="Wybierz pozycję cennika..." disabled={!changes?.offiko_id}>
																	{getRelation('ikoprices').filter(el => el?.offiko_id === changes?.offiko_id).map(el => {
																		return (
																			<Option
																				key={['ikoprice', el.id]}
																				value={el.id}
																			>
																				{el.service_name} ({el.price} PLN)
																			</Option>
																		);
																	})}
																</Select>
															</Form.Item>
														</td>
														<td className="ant-table-cell">
															<Form.Item
																name="client_tickets_count"
															>
																<InputNumber style={{ width: '100%' }} precision={0} />
															</Form.Item>
														</td>
														<td className="ant-table-cell">
															<Form.Item
																name="client_is_member"
																valuePropName="checked"
															>
																<Checkbox />
															</Form.Item>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</AntTable>
							</Col>
							<Col span={24}>
								{
									changes?.appticketmembers?.length
										? <FlexSpace>
											<strong>Uczestnik</strong>
											<strong>Pozycja cennika</strong>
											<strong>Ilość biletów</strong>
											<span style={{ display: 'block', width: 64 }}></span>
										</FlexSpace>
										: null
								}
								<Form.List name="appticketmembers">
									{(fields, { add, remove }) => (
										<>
											{fields.map(({ key, name, fieldKey, ...restField }) => (
												<FlexSpace key={key}>
													<Form.Item
														{...restField}
														name={[name, 'family_id']}
														fieldKey={[fieldKey, 'family_id']}
														rules={[
															{
																required: true,
																message: '[Uczestnicy] Proszę wybrać uczestnika'
															}
														]}
													>
														<Select placeholder="Wybierz uczestnika...">
															{
																profileData && profileData.hasOwnProperty('families') && profileData.families.map(el => (
																	<Option
																		key={el.id}
																		value={el.id}
																	>
																		{el.first_name} {el.last_name} ({el.birth_date})
																	</Option>
																))
															}
														</Select>
													</Form.Item>

													<Form.Item
														{...restField}
														name={[name, 'ikoprice_id']}
														fieldKey={[fieldKey, 'ikoprice_id']}
													>
														<Select placeholder="Wybierz pozycję cennika..." disabled={!changes?.offiko_id}>
															{getRelation('ikoprices').filter(el => el?.offiko_id === changes?.offiko_id).map(el => {
																return (
																	<Option
																		key={['ikoprice', el.id]}
																		value={el.id}
																	>
																		{el.service_name} ({el.price} PLN)
																	</Option>
																);
															})}
														</Select>
													</Form.Item>

													<Form.Item
														{...restField}
														name={[name, 'tickets_count']}
														fieldKey={[fieldKey, 'tickets_count']}
													>
														<InputNumber style={{ width: '100%' }} precision={0} />
													</Form.Item>

													<Button danger block onClick={() => remove(name)}>
														Usuń
													</Button>
												</FlexSpace>
											))}

											<Form.Item>
												<Button type="dashed" onClick={() => add()} block>
													Dodaj uczestnika
												</Button>
											</Form.Item>
										</>
									)}
								</Form.List>
							</Col>
						</>
						: changes?.type === 'R'
							? <>
								<Col span={24}>
									<AntTable className="ant-table">
										<div className="ant-table-container">
											<div className="ant-table-content">
												<table style={{ tableLayout: 'auto' }}>
													<thead className="ant-table-thead">
														<tr className="ant-table-row">
															<th className="ant-table-cell">
																<strong>Uczestnik</strong>
															</th>
															<th className="ant-table-cell">
																<strong>Pozycja cennika</strong>
															</th>
															<th className="ant-table-cell">
																<strong>Ilość biletów</strong>
															</th>
															<th className="ant-table-cell">
																<strong>Korzysta ze świadczenia?</strong>
															</th>
														</tr>
													</thead>
													<tbody className="ant-table-tbody">
														<tr className="ant-table-row" valign="baseline">
															<td className="ant-table-cell">
																{auth.user?.full_name ?? '?'}
															</td>
															<td className="ant-table-cell">
																<Form.Item
																	name="client_iroprice_id"
																>
																	<Select placeholder="Wybierz pozycję cennika..." disabled={!changes?.offiro_id}>
																		{getRelation('iroprices').filter(el => el?.offiro_id === changes?.offiro_id).map(el => {
																			return (
																				<Option
																					key={['iroprice', el.id]}
																					value={el.id}
																				>
																					{el.service_name} ({el.price} PLN)
																				</Option>
																			);
																		})}
																	</Select>
																</Form.Item>
															</td>
															<td className="ant-table-cell">
																<Form.Item
																	name="client_tickets_count"
																>
																	<InputNumber style={{ width: '100%' }} precision={0} />
																</Form.Item>
															</td>
															<td className="ant-table-cell">
																<Form.Item
																	name="client_is_member"
																	valuePropName="checked"
																>
																	<Checkbox />
																</Form.Item>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</AntTable>
								</Col>
								<Col span={24}>
									{
										changes?.appticketmembers?.length
											? <FlexSpace>
												<strong>Uczestnik</strong>
												<strong>Pozycja cennika</strong>
												<strong>Ilość biletów</strong>
												<span style={{ display: 'block', width: 64 }}></span>
											</FlexSpace>
											: null
									}
									<Form.List name="appticketmembers">
										{(fields, { add, remove }) => (
											<>
												{fields.map(({ key, name, fieldKey, ...restField }) => (
													<FlexSpace key={key}>
														<Form.Item
															{...restField}
															name={[name, 'family_id']}
															fieldKey={[fieldKey, 'family_id']}
															rules={[
																{
																	required: true,
																	message: '[Uczestnicy] Proszę wybrać uczestnika'
																}
															]}
														>
															<Select placeholder="Wybierz uczestnika...">
																{
																	profileData && profileData.hasOwnProperty('families') && profileData.families.map(el => (
																		<Option
																			key={el.id}
																			value={el.id}
																		>
																			{el.first_name} {el.last_name} ({el.birth_date})
																		</Option>
																	))
																}
															</Select>
														</Form.Item>

														<Form.Item
															{...restField}
															name={[name, 'iroprice_id']}
															fieldKey={[fieldKey, 'iroprice_id']}
														>
															<Select placeholder="Wybierz pozycję cennika..." disabled={!changes?.offiro_id}>
																{getRelation('iroprices').filter(el => el?.offiro_id === changes?.offiro_id).map(el => {
																	return (
																		<Option
																			key={['iroprice', el.id]}
																			value={el.id}
																		>
																			{el.service_name} ({el.price} PLN)
																		</Option>
																	);
																})}
															</Select>
														</Form.Item>

														<Form.Item
															{...restField}
															name={[name, 'tickets_count']}
															fieldKey={[fieldKey, 'tickets_count']}
														>
															<InputNumber style={{ width: '100%' }} precision={0} />
														</Form.Item>

														<Button danger block onClick={() => remove(name)}>
															Usuń
														</Button>
													</FlexSpace>
												))}

												<Form.Item>
													<Button type="dashed" onClick={() => add()} block>
														Dodaj uczestnika
													</Button>
												</Form.Item>
											</>
										)}
									</Form.List>
								</Col>
							</>
							: <Col span={24}>
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Proszę najpierw wybrać rodzaj świadczenia" />
							</Col>
				}
			</Row>
		</>,
		attachments: <>
			<Row gutter={[16, 24]}>
				<Col sm={24} md={12}>
					<Form.Item
						label="Ogólny"
						name="attachments"
						getValueFromEvent={(e) => {
							if(Array.isArray(e)) {
								return e;
							}

							return e && e.fileList;
						}}
					>
						<DraggerUpload
							fileType={
								(relationData && relationData.hasOwnProperty('dctfiletypes'))
									? relationData.dctfiletypes.find(el => el.name === 'Ogólny') ?? null
									: null
							}
							updateAttachments={setAttachments}
							data={attachments}
						>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>

							<p className="ant-upload-text">Kliknij lub przeciągnij plik do tego obszaru, aby dodać załącznik</p>
						</DraggerUpload>
					</Form.Item>
				</Col>
			</Row>
		</>,
	};

	return (
		<>
			<Form form={form} layout="vertical" onFinish={onSave} onValuesChange={onChange}>
				<Subheader title={`Bilety i karnety`} extra={<Subheader.Actions>
					<Link to="/" style={{ marginRight: 15 }}>Wróć do pulpitu</Link>
					<Button type="primary" htmlType="submit" disabled={loading} loading={saving}>Zapisz wniosek</Button>
				</Subheader.Actions>} />

				<CardTabbed loading={loading || loadingUser} tabList={tabs} tabContent={tabContent} activeTabKey={currentTab} onTabChange={key => { setCurrentTab(key); }} />
			</Form>
		</>
	);
};

export default Dashboard_Tickets;
