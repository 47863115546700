import React from "react";

import { Row, Col, Card, Tabs } from 'antd';

/**
 * Card with extended functionality of tabs (forced rendering of tabs). Ability to collect form data from other tabs than just active one.
 *
 */
const CardTabbed = ({ tabContent, onTabChange, ...props }) => {
	/**
	 * Key of active tab
	 * @type {String}
	 */
	const [activeTab, setActiveTab] = React.useState(
		props?.activeTabKey
			?
			props?.activeTabKey
			:
			(props?.tabList && props.tabList.length)
				?
				(props?.tabList[0]?.key ?? null)
				:
				null
	);

	/**
	 * General callback for tab change.
	 * @param  {String} key Key of tab
	 */
	const _onTabChange = key => {
		setActiveTab(key);
	};

	/**
	 * Extra props needed for tabs.
	 * @type {Object}
	 */
	const {
		tabProps = {},
		activeTabKey,
		defaultActiveTabKey,
		tabBarExtraContent,
		forceRender = true
	} = props;

	/**
	 * Do component have activeTabKey prop.
	 * @type {Boolean}
	 */
	const hasActiveTabKey = activeTab !== null;

	/**
	 * Prepared props for tabs.
	 * @type {Object}
	 */
	const extraProps = {
		...tabProps,
		[hasActiveTabKey ? 'activeKey' : 'defaultActiveKey']: hasActiveTabKey
			? activeTab
			: defaultActiveTabKey,
		tabBarExtraContent,
	};

	React.useEffect(() => {
		if(onTabChange && typeof onTabChange === 'function') {
			onTabChange(activeTab);
		}
	}, [activeTab, setActiveTab]);

	/**
	 * Prepared React.ReactNode[] of tabs.
	 * @type {React.ReactNode[]}
	 */
	const tabsContent = props?.tabList && props.tabList.length ? (
		<Tabs
			size="large"
			{...extraProps}
			onChange={_onTabChange}
			renderTabBar={ function() { return null; } }
		>
			{props.tabList.map(item => (
				<Tabs.TabPane tab={item.tab} disabled={item.disabled} key={item.key} forceRender={forceRender ?? false}>
					{ tabContent[item.key] ?? null }
				</Tabs.TabPane>
			))}
		</Tabs>
	) : null;

	return (
		<Card onTabChange={_onTabChange} tabProps={tabProps} activeTabKey={activeTab} defaultActiveTabKey={defaultActiveTabKey} tabBarExtraContent={tabBarExtraContent} {...props}>
			{tabsContent}
		</Card>
	);
};

export default CardTabbed;
