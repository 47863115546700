import React from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import styled from 'styled-components';

import { useAuth } from '../../../contexts/User';

import Subheader from '../../../components/Subheader';
import CardTabbed from '../../../components/CardTabbed';
import DraggerUpload from '../../../components/DraggerUpload';

import { InboxOutlined } from '@ant-design/icons';
import { Row, Col, Card, Form, Button, Input, InputNumber, Select, DatePicker, Switch, message, Modal, Table } from 'antd';

import { STATUS as STASTATUS, hasStatus, handleCountClick } from '../../../helpers/AppsHelper';
import { checkRelationTablesService as checkRelationTables } from '../../../helpers/ApiHelper';

import { backendApi } from '../../../config/paths';
import { APIBackend as API } from '../../../api';

import moment from 'moment';

const { Option } = Select;

const FormItem = styled.div`
	display: flex;
	flex-direction: column;
`;

const FormItemLabel = styled.label`
	font-weight: 600;
`;

const FormItemValue = styled.span`

`;

const FileList = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
`;

const FileListItem = styled.li`

`;

const paymentColumns = [
	{
		title: 'Nr dowodu',
		dataIndex: 'number'
	},
	{
		title: 'Okres płatności',
		dataIndex: 'period'
	},
	{
		dataIndex: 'real_price',
		title: 'Rzeczywista kwota płatności',
		render: text => {
			return `${text} PLN`;
		}
	},
	{
		dataIndex: 'subsidy_value',
		title: 'Kwota podlegająca dofinansowaniu'
	},
	{
		dataIndex: 'subsidy_total',
		title: 'Wysokość dofinansowania'
	}
];

const Dashboard_Infants = ({ ...props }) => {
	const auth = useAuth();
	const history = useHistory();

	const { id } = useParams();

	const [data, setData] = React.useState(null);
	const [relationData, setRelationData] = React.useState(null);
	const [currentTab, setCurrentTab] = React.useState('general');
	const [isModalVisible, setIsModalVisible] = React.useState(false);
	const [paymentForm] = Form.useForm();

	const relationTypes = ['dctconditions', 'offtourists', 'touristprices', 'dctpaymethods', 'dctfiletypes'];

	const [attachments, setAttachments] = React.useState({
		list: [],
		add: [],
		del: []
	});

	React.useEffect(() => {
		API.infants(id).then(data => {
			setData(data);
			const filterObj = checkRelationTables(relationTypes, data);
			API.relations(relationTypes, null, filterObj).then(data => {
				setRelationData(data);
			}).catch(err => {
				console.log(err);
			});
		}).catch(err => {
			console.log(err);
		});
	}, []);

	const allowedEditIds = [1,4];

	const handlePaymentClick = (e) => {
		// setIsModalVisible(true);
		history.push(`/infantpayments/${id}`);
	};

	const handleOk = () => {
		if(paymentForm) {
			paymentForm.submit();
		}
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const addPayment = (values) => {
		values.payment_date = values.payment_date.format('YYYY-MM-DD');

		API.addInfantPayment(data?.id, values).then(r => {
			if(paymentForm) {
				paymentForm.resetFields();
			}
			setIsModalVisible(false);
			message.success('Twoja wpłata została zarejestrowana');
		}).catch(err => {
			message.error(<>Wystąpił błąd podczas dodawania wpłaty:<br/>{err.getMessage()}</>);
		});
	};

	const handleEditClick = (e) => {
		if(data && allowedEditIds.includes(data?.staapp_id)) {
			history.push(`/infants/edit/${id}`);
		}
	};

	const getFileType = (name) => {
		if(relationData && relationData.hasOwnProperty('dctfiletypes')) {
			return relationData.dctfiletypes.filter(el => el.is_active).find(el => el.name === name);
		}

		return null;
	};

	const getRelation = (name) => {
		if(relationData && relationData.hasOwnProperty(name)) {
			if(relationData[name]) {
				return relationData[name].filter(el => el?.is_active ?? true);
			}
		}

		return [];
	};

	const tabs = [
		{ key: 'general', tab: 'Dane podstawowe' },
		{ key: 'payments', tab: 'Rejestr wpłat' },
		{ key: 'attachments', tab: 'Załączniki' }
	];

	const tabContent = {
		general: <>
			<Row gutter={[16, 24]}>
				<Col span={24}>
					<FormItem>
						<FormItemLabel>
							Status wniosku
						</FormItemLabel>
						<FormItemValue>
							{data?.staapp_name}
						</FormItemValue>
					</FormItem>
				</Col>

				<Col sm={24} md={12}>
					<FormItem>
						<FormItemLabel>
							Wnioskujący
						</FormItemLabel>
						<FormItemValue>
							{data?.full_name}
						</FormItemValue>
					</FormItem>
				</Col>

				<Col sm={24} md={12}>
					<FormItem>
						<FormItemLabel>
							Data złożenia wniosku
						</FormItemLabel>
						<FormItemValue>
							{data?.application_date}
						</FormItemValue>
					</FormItem>
				</Col>

				<Col sm={24} md={12}>
					<FormItem>
						<FormItemLabel>
							Uczestnik
						</FormItemLabel>
						<FormItemValue>
							{data?.family_first_name} {data?.family_last_name} ({data?.family_birth_date})
						</FormItemValue>
					</FormItem>
				</Col>

				<Col sm={24} md={12}>
					<FormItem>
						<FormItemLabel>
							Rok szkolny
						</FormItemLabel>
						<FormItemValue>
							{data?.school_year}
						</FormItemValue>
					</FormItem>
				</Col>

				<Col span={24}>
					<FormItem>
						<FormItemLabel>
							Rodzaj placówki
						</FormItemLabel>
						<FormItemValue>
							{data?.infant_type === 'I' ? 'Wewnętrzna' : 'Zewnętrzna'}
						</FormItemValue>
					</FormItem>
				</Col>

				{
					data?.infant_type === 'I'
						? <>
							<FormItem>
								<FormItemLabel>
									Przedszkole/żłobek
								</FormItemLabel>
								<FormItemValue>
									{data?.infant_name}
								</FormItemValue>
							</FormItem>
						</>
						: null
				}

				{
					data?.infant_type === 'E'
						? <>
							<Col span={24}>
								<FormItem>
									<FormItemLabel>
										Nazwa obiektu
									</FormItemLabel>
									<FormItemValue>
										{data?.name}
									</FormItemValue>
								</FormItem>
							</Col>

							<Col sm={24} md={12}>
								<FormItem>
									<FormItemLabel>
										Ulica obiektu
									</FormItemLabel>
									<FormItemValue>
										{data?.street}
									</FormItemValue>
								</FormItem>
							</Col>

							<Col sm={24} md={5}>
								<FormItem>
									<FormItemLabel>
										Kod pocztowy obiektu
									</FormItemLabel>
									<FormItemValue>
										{data?.zip}
									</FormItemValue>
								</FormItem>
							</Col>

							<Col sm={24} md={7}>
								<FormItem>
									<FormItemLabel>
										Miejscowość obiektu
									</FormItemLabel>
									<FormItemValue>
										{data?.town}
									</FormItemValue>
								</FormItem>
							</Col>
						</>
						: null
				}

				{
					(data?.remarks)
						? <Col span={24}>
							<FormItem>
								<FormItemLabel>
									Uwagi
								</FormItemLabel>
								<FormItemValue>
									{data?.remarks ?? '(brak)'}
								</FormItemValue>
							</FormItem>
						</Col>
						: null
				}

				{
					(data?.to_correct)
						? <Col span={24}>
							<FormItem>
								<FormItemLabel>
									Uwagi (do korekty)
								</FormItemLabel>
								<FormItemValue>
									{data?.to_correct ?? '(brak)'}
								</FormItemValue>
							</FormItem>
						</Col>
						: null
				}
			</Row>
		</>,
		payments: <>
			<Row gutter={[16, 24]}>
				<Col span={24}>
					<Table columns={paymentColumns} dataSource={data?.appinfantpayments ?? null} />
				</Col>
			</Row>
		</>,
		attachments: <>
			<Row gutter={[16, 24]}>
				<Col sm={24} md={12}>
					<FormItem>
						<FormItemLabel>
							Ogólne
						</FormItemLabel>
						<FormItemValue>
							<FileList>
								{
									(data?.attachments && data.attachments.filter(file => file.dctfiletype_id === getFileType('Ogólny')?.id).length)
										? data.attachments.filter(file => file.dctfiletype_id === getFileType('Ogólny')?.id).map(file => (
											<FileListItem key={['fileList_item', file.uid]}>
												<a href={`${backendApi}/storage/app${file.file_path}`}>
													{file.file_name}
												</a>
											</FileListItem>
										))
										: '(brak)'
								}
							</FileList>
						</FormItemValue>
					</FormItem>
				</Col>

				<Col sm={24} md={12}>
					<FormItem>
						<FormItemLabel>
							Umowy
						</FormItemLabel>
						<FormItemValue>
							<FileList>
								{
									(data?.attachments && data.attachments.filter(file => file.dctfiletype_id === getFileType('Umowy')?.id).length)
										? data.attachments.filter(file => file.dctfiletype_id === getFileType('Umowy')?.id).map(file => (
											<FileListItem key={['fileList_item', file.uid]}>
												<a href={`${backendApi}/storage/app${file.file_path}`}>
													{file.file_name}
												</a>
											</FileListItem>
										))
										: '(brak)'
								}
							</FileList>
						</FormItemValue>
					</FormItem>
				</Col>
			</Row>
		</>,
	};

	return (
		<>
			<Subheader title={`Przedszkola i żłobki`} extra={<Subheader.Actions>
				<Link to="/infants" style={{ marginRight: 15 }}>Wróć</Link>
				<Button type="primary" onClick={handlePaymentClick}>Dodaj wpłatę</Button>
				<Button style={{ marginLeft: 5 }} type="primary" onClick={handleEditClick} disabled={!(data && allowedEditIds.includes(data?.staapp_id))}>Edytuj wniosek</Button>
			</Subheader.Actions>} />

			{
				(data === null || relationData === null)
					? <Card loading={true} />
					: <CardTabbed tabList={tabs} tabContent={tabContent} activeTabKey={currentTab} onTabChange={key => { setCurrentTab(key); }} />
			}

			<Modal title="Dodawanie wpłaty" visible={isModalVisible} okText="Dodaj" onOk={handleOk} onCancel={handleCancel}>
				<Form
					name="appinfantpayments"
					form={paymentForm}
					layout="vertical"
					onFinish={addPayment}
				>
					<Row gutter={[16,16]}>
						<Col sm={24} md={12}>
							<Form.Item
								name="period"
								label="Okres płatności"
								rules={
									[
										{
											type: 'string',
											max: 7,
											message: 'Pole może zawierać maksymalnie 7 znaków. Format: RRRR/MM, np. 2021/07'
										},
										{
											pattern: /(\d{4}\/\d{2})/g,
											message: 'Nieprawidłowy format, proszę uzupełnić pole w następującym formacie: RRRR/MM, np. 2021/07'
										},
										{
											required: true,
											message: 'Proszę uzupełnić okres płatności'
										}
									]
								}
							>
								<Input placeholder="RRRR/MM" />
							</Form.Item>
						</Col>

						<Col sm={24} md={12}>
							<Form.Item
								name="real_price"
								label="Rzeczywista kwota płatności"
								rules={
									[
										{
											required: true,
											message: 'Proszę uzupełnić rzeczywistą kwotę płatności'
										}
									]
								}
							>
								<InputNumber precision={2} decimalSeparator="," formatter={(value) => value.replace(/,/, '.')} style={{ width: '100%' }} />
							</Form.Item>
						</Col>

						<Col sm={24} md={12}>
							<Form.Item
								name="payment_date"
								label="Data płatności"
								rules={
									[
										{
											required: true,
											message: 'Proszę uzupełnić datę płatności'
										}
									]
								}
							>
								<DatePicker placeholder="RRRR-MM-DD" style={{ width: '100%' }} />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	);
};

export default Dashboard_Infants;
