import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Tour from 'reactour';

import { useAuth } from '../../../contexts/User';

import Subheader from '../../../components/Subheader';

import { Row, Col, Card, Menu, Alert, Button } from 'antd';

import { APIBackend as API } from '../../../api';

import createPersistedState from '../../../hooks/usePersistedState';

import ProfileDrafts from './draftList';
import ProfileActive from './activeProfile';
import ProfileCreate from './createProfile';
import ProfileEdit from './editProfile';

const TourTitle = styled.h1`
	font-size: 1.8rem;
	margin: 0;
	padding: 0;
	text-transform: uppercase;
`;

const useUserStageState = createPersistedState('userStage');
const useContractorState = createPersistedState('contractor');

const Dashboard_Profile = ({ ...props }) => {
	const auth = useAuth();

	const tourRef = React.useRef();

	const [activeSubpage, setActiveSubpage] = React.useState('active');
	const [subpageProps, setSubpageProps] = React.useState({});

	const [profileExists, setProfileExists] = React.useState(false);
	const [error, setError] = React.useState(null);
	const [message, setMessage] = React.useState(null);
	const [data, setData] = React.useState(null);
	const [loading, setLoading] = React.useState(true);

	const [userStage, setUserStage] = useUserStageState(0);
	const [contractor, setContractor] = useContractorState(null);

	const [isTourOpen, setIsTourOpen] = React.useState(false);

	const steps = [
		{
			content: <>
				Czas na przyjrzenie się Twojemu profilowi!
				<br/>
				Profil to zestaw informacji oraz odpowiednich dokumentów. Jest on wymagany do złożenia wniosków.
			</>,
			stepInteraction: false,
		},
		{
			selector: '.zfss-menu-active-profile',
			content: <>
				Po kliknięciu w to miejsce będziesz miał(a) możliwość podejrzenia swoich danych oraz ewentualnie możesz je tutaj modyfikować.
			</>,
			stepInteraction: false,
		},
		{
			selector: '.zfss-menu-profile-list',
			content: <>
				Tutaj znajduje się lista Twoich profili. Znajdziesz tutaj profile, które utworzyłeś/łaś lub zostały odesłane do korekty.
				<br/>
				Z tego miejsca będziesz miał(a) również możliwość przekazania profilu do akceptacji.
			</>,
			stepInteraction: false,
		},
		{
			selector: '.zfss-no-active-profile-card',
			content: <>
				Pamiętaj o tym, aby zwracać uwagę na wiadomości, które pojawiają się na ekranie - mogą szybko nakierować na to, co trzeba dalej zrobić.
			</>,
			stepInteraction: false,
		},
		{
			selector: '.zfss-create-profile-btn',
			content: <>
				Pora na utworzenie Twojego pierwszego profilu!
				Kliknij przycisk "Utwórz profil" i przejdźmy dalej!
			</>,
		}
	];

	React.useEffect(() => {
		API.getActiveProfile(auth.user?.id, contractor).then(data => {
			setProfileExists(true);
		}).catch(err => {

		}).finally(() => {
			if(!auth.afterFirstTour()) {
				setIsTourOpen(true);
			}
		});

		API.profiles(auth.user?.id, contractor).then(data => {
			setData(data.filter(el => ![2,3].includes(el.staprofile_id) && el.contractor_id === contractor && el.user_id === auth.user.id));
		}).catch(err => {

		}).finally(() => {
			setLoading(false);
		});
	}, []);

	React.useEffect(() => {
		setError(null);
	}, [activeSubpage, setActiveSubpage]);

	const changeSubpage = (page, _props = {}) => {
		setMessage(null);
		setSubpageProps(_props);
		setActiveSubpage(page);
	};

	const getSubpageContent = () => {
		switch(activeSubpage) {
			case 'active':
				return <ProfileActive changePage={changeSubpage} setMessage={setMessage} setError={setError} tourRef={tourRef} {...subpageProps} />;
				break;
			case 'drafts':
				return <ProfileDrafts changePage={changeSubpage} setMessage={setMessage} setError={setError} tourRef={tourRef} {...subpageProps} />;
				break;
			case 'create':
				return <ProfileCreate changePage={changeSubpage} setMessage={setMessage} setError={setError} tourRef={tourRef} {...subpageProps} />;
				break;
			case 'edit':
				return <ProfileEdit changePage={changeSubpage} setMessage={setMessage} setError={setError} tourRef={tourRef} {...subpageProps} />;
				break;
			default:
				return <></>;
		}
	};

	return (
		<>
			<Subheader title={`Ustawienia profilu`} extra={<Subheader.Actions>
				<Link to="/">Wróć do pulpitu</Link>
			</Subheader.Actions>} />

			{
				auth.hasProfileIncomeUpdate()
					? <Alert className="zfss-no-profile" type="error" message={<>Wymagana aktualizacja dochodów w profilu. Wprowadź nowe wartości i prześlij profil do akceptacji.</>} style={{ marginBottom: 40 }} />
					: null
			}

			<Row gutter={[32, 0]}>
				<Col sm={24} md={6}>
					<Card title="Nawigacja" bodyStyle={{ padding: 0 }}>
						<Menu selectedKeys={activeSubpage} style={{ border: 0 }}>
							<Menu.Item className="zfss-menu-active-profile" key="active" onClick={() => { setActiveSubpage('active'); }}>
								Aktywny profil
							</Menu.Item>
							<Menu.Divider style={{ marginBottom: 8 }} />
							<Menu.Item className="zfss-menu-profile-list" key="drafts" onClick={() => { setActiveSubpage('drafts'); }}>
								Lista profili
							</Menu.Item>
						</Menu>
					</Card>
				</Col>

				<Col sm={24} md={18}>
					{error && <Alert type="error" message={error.getMessage()} style={{ marginBottom: 20 }} />}
					{message && <Alert type="info" message={message} style={{ marginBottom: 20 }} />}
					<Card>
						{getSubpageContent()}
					</Card>
				</Col>
			</Row>

			<Tour
				ref={tourRef}
				steps={steps}
				isOpen={isTourOpen}
				onRequestClose={() => setIsTourOpen(false)}
				closeWithMask={false}
				lastStepNextButton={<Button size="small" onClick={() => {
					setIsTourOpen(false);
				}}>Rozumiem!</Button>}
				className="appTour"
			/>
		</>
	);
};

export default Dashboard_Profile;
