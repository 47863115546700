import React from 'react';
import { useHistory, Link } from 'react-router-dom';

import { useAuth } from '../../../contexts/User';

import Subheader from '../../../components/Subheader';
import CardTabbed from '../../../components/CardTabbed';
import DraggerUpload from '../../../components/DraggerUpload';

import { InboxOutlined } from '@ant-design/icons';
import { Row, Col, Card, Form, Button, Input, InputNumber, Select, DatePicker, Switch, message, Modal } from 'antd';

import { APIBackend as API } from '../../../api';

import moment from 'moment';

import createPersistedState from '../../../hooks/usePersistedState';
import {onApplyHook} from "../../../common/on-apply-hook/on-apply.hook";

const { Option } = Select;

const useUserStageState = createPersistedState('userStage');
const useContractorState = createPersistedState('contractor');

const Dashboard_Refcamps = ({ ...props }) => {
    const auth = useAuth();
    const history = useHistory();

    const [form] = Form.useForm();

    const [profileData, setProfileData] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [saving, setSaving] = React.useState(false);
    const [loadingUser, setLoadingUser] = React.useState(true);
    const [relationData, setRelationData] = React.useState(null);
    const [currentTab, setCurrentTab] = React.useState('general');
    const [changes, setChanges] = React.useState({});

    const [userStage, setUserStage] = useUserStageState(0);
    const [contractor, setContractor] = useContractorState(null);

    const [attachments, setAttachments] = React.useState({
        list: [],
        add: [],
        del: []
    });

    React.useEffect(() => {
        API.getActiveProfile(auth.user?.id, contractor).then(data => {
            setProfileData(data);
        }).catch(err => {

        }).finally(() => {
            setLoading(false);
        });

        API.relations(['dctcamptypes', 'dctpaymethods', 'dctfiletypes']).then(data => {
            setRelationData(data);
        }).catch(err => {
            console.log(err);
        });
    }, []);

    React.useEffect(() => {
        setLoadingUser(false);
    }, [auth, auth.user]);

    const onSave = async (values) => {
        setSaving(true);

        if(!(await onApplyHook('refcamps'))) {
            setSaving(false);
            return false;
        }

        Object.keys(values).forEach(el => {
            if (['application_date', 'payment_date', 'from', 'to'].includes(el)) {
                values[el] = values[el] ? values[el].format('YYYY-MM-DD') : null;
            } else if (['attachments'].includes(el)) {
                if (attachments.hasOwnProperty('add')) {
                    attachments.add.forEach(el => {
                        if (el) {
                            values[`files[${el.category}/${el.name}]`] = el.file;
                        }
                    });
                }

                if (attachments.hasOwnProperty('del')) {
                    attachments.del.forEach(el => {
                        if (el) {
                            values[`delete[${el.category}/${el.name}]`] = el.uid;
                        }
                    });
                }

                delete values[el];
            }
        });

        const _data = new FormData();

        for (let k in values) {
            let val = values[k];

            if (val !== null && val !== undefined && val !== '') {
                _data.append(k, val);
            }
        }

        API.applyRefcamps(_data).then(r => {
            message.success('Wniosek został zapisany');
			Modal.confirm({
				title: 'Wniosek został zapisany.',
				content: <>Chcesz przekazać wniosek do akceptacji?</>,
				okText: 'Przekaż do akceptacji',
				cancelText: 'Nie',
				onOk() {
					return API.countApp('apprefcamps', r.id).then((res) => {
                        message.success(`Wysokość dofinansowania: ${res?.subsidy_total ?? 0} PLN`, 3);
						return API.acceptApp('apprefcamps', r.id).then(() => {
							message.success('Wniosek został przekazany do akceptacji');
						}).catch(err => {
							message.error(err.getMessage());
						}).finally(() => {
							history.push('/');
						});
					}).catch(err => {
						message.error(err.getMessage());
					}).finally(() => {
                        history.push('/');
                    });
				},
                onCancel() {
					history.push('/');
				}
			});
        }).catch(err => {
            message.error(err.getMessage());
            console.log(err);
        }).finally(() => {
            setSaving(false);
        });
    };

    const getRelation = (name) => {
        if (relationData && relationData.hasOwnProperty(name)) {
            if (relationData[name]) {
                return relationData[name].filter(el => el?.is_active ?? true);
            }
        }

        return [];
    };

    const onChange = (field, allFields) => {
        setChanges(allFields);

        const keys = Object.keys(field);

        if (!keys.length) return;

        const value = field[keys[0]];
    };

    const tabs = [
        { key: 'general', tab: 'Dane podstawowe' },
        { key: 'attachments', tab: 'Załączniki' }
    ];

    const tabContent = {
        general: <>
            <Row gutter={[16, 24]}>
                <Col sm={24} md={12}>
                    <Form.Item
                        label="Wnioskujący"
                        name="profile_id"
                        initialValue={profileData.id}
                    >
                        <Select disabled>
                            <Option value={profileData.id}>{auth.user?.full_name}</Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col sm={24} md={12}>
                    <Form.Item
                        label="Data złożenia wniosku"
                        name="application_date"
                        initialValue={moment()}
                    >
                        <DatePicker style={{ width: '100%' }} disabled />
                    </Form.Item>
                </Col>

                <Col sm={24} md={12}>
                    <Form.Item
                        label="Rodzaj wypoczynku dzieci"
                        name="dctcamptype_id"
                        rules={[
                            { required: true, message: 'Proszę uzupełnić rodzaj wypoczynku dzieci' }
                        ]}
                    >
                        <Select
                            showSearch
                            optionFilterProp="children"
                        >
                            {getRelation('dctcamptypes').map(el => {
                                return (
                                    <Option
                                        key={['dctcamptype', el.id]}
                                        value={el.id}
                                    >
                                        {el.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col sm={24} md={12}>
                    <Form.Item
                        label="Oferta / Obiekt"
                        name="object"
                    >
                        <Input />
                    </Form.Item>
                </Col>

                <Col sm={24} md={12}>
                    <Form.Item
                        label="Termin od"
                        name="from"
						dependencies={['to']}
                        rules={[
                            {
                                required: true,
                                message: 'Proszę uzupełnić terminy wyjazdu'
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (value.diff(getFieldValue('to'), 'days') < 0) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('Data początku terminu musi być wcześniejszą datą, niż koniec terminu');
                                },
                            })
                        ]}
                    >
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                </Col>

                <Col sm={24} md={12}>
                    <Form.Item
                        label="Termin do"
                        name="to"
						dependencies={['from']}
                        rules={[
                            {
                                required: true,
                                message: 'Proszę uzupełnić terminy wyjazdu'
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (value.diff(getFieldValue('from'), 'days') > 0) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('Data końca terminu musi być późniejszą datą, niż początek terminu');
                                },
                            })
                        ]}
                    >
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                </Col>

                <Col sm={24} md={12}>
                    <Form.Item
                        label="Cena wyjazdu"
                        name="real_price"
                        rules={[
                            {
                                required: true,
                                message: 'Proszę uzupełnić cenę wyjazdu'
                            }
                        ]}
                    >
                        <InputNumber precision={2} decimalSeparator="," formatter={(value) => value.replace(/,/, '.')} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>

                <Col sm={24} md={12}>
                    <Form.Item
                        label="Uczestnik"
                        name="family_id"
                        rules={[
                            {
                                required: true,
                                message: 'Proszę wybrać uczestnika wypoczynku'
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            optionFilterProp="children"
                        >
                            {
                                profileData && profileData.hasOwnProperty('families') && profileData.families.map(el => (
                                    <Option
                                        key={el.id}
                                        value={el.id}
                                    >
                                        {el.first_name} {el.last_name} ({el.birth_date})
                                    </Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                </Col>

                <Col sm={24} md={12}>
                    <Form.Item
                        label="Opiekun dziecka niepełnosprawnego"
                        name="is_guardian"
                        valuePropName="checked"
                    >
                        <Switch checkedChildren="Tak" unCheckedChildren="Nie" />
                    </Form.Item>
                </Col>

                <Col sm={24} md={12}>
                    <Form.Item
                        label="Kwota podlegająca dofinansowaniu"
                        name="subsidy_value"
                    >
                        <InputNumber precision={2} decimalSeparator="," formatter={(value) => value.replace(/,/, '.')} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>

                <Col sm={24} md={12}>
                    <Form.Item
                        label="Nr rachunku organizatora"
                        name="bank_account"
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
        </>,
        attachments: <>
            <Row gutter={[16, 24]}>
                <Col sm={24} md={12}>
                    <Form.Item
                        label="Ogólny"
                        name="attachments"
                        getValueFromEvent={(e) => {
                            if (Array.isArray(e)) {
                                return e;
                            }

                            return e && e.fileList;
                        }}
                    >
                        <DraggerUpload
                            fileType={
                                (relationData && relationData.hasOwnProperty('dctfiletypes'))
                                    ? relationData.dctfiletypes.find(el => el.name === 'Ogólny') ?? null
                                    : null
                            }
                            updateAttachments={setAttachments}
                            data={attachments}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>

                            <p className="ant-upload-text">Kliknij lub przeciągnij plik do tego obszaru, aby dodać załącznik</p>
                        </DraggerUpload>
                    </Form.Item>
                </Col>
            </Row>
        </>,
    };

    return (
        <>
            <Form form={form} layout="vertical" onFinish={onSave} onValuesChange={onChange}>
                <Subheader title={`Wypoczynek dzieci refundacja`} extra={<Subheader.Actions>
                    <Link to="/" style={{ marginRight: 15 }}>Wróć do pulpitu</Link>
                    <Button type="primary" htmlType="submit" disabled={loading} loading={saving}>Zapisz wniosek</Button>
                </Subheader.Actions>} />

                <CardTabbed loading={loading || loadingUser} tabList={tabs} tabContent={tabContent} activeTabKey={currentTab} onTabChange={key => { setCurrentTab(key); }} />
            </Form>
        </>
    );
};

export default Dashboard_Refcamps;
