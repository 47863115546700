import { APIInstance, APIConfig } from '../../';
import { API } from '../../api';
import APIBackendConfig from '../config';


export default {
	login(login, password) {
		const credentials = {
			[APIConfig.credentialsLoginKey ?? 'username']: login,
			password: password
		};

		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.post(`/api/login`, credentials).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	logout() {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.get(`/api/logout`).then(r => {
					resolve();
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	register(values) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIBackendConfig.getConfig().then(cfg => {
					APIInstance.post(`/api/register`, values).then(r => {
						if(r?.data?.errcode) {
							switch(r.data.errcode) {
								case 'PESEL-NOT-MATCH':
									reject(`Nie odnaleziono Twoich danych w naszej bazie. Skontaktuj się z biurem obsługi pod nr ${cfg?.company_phone ?? '(nie ustawiono)'}`);
									break;
								case 'NAME-NOT-MATCH':
									resolve({
										data: r.data,
										msg: 'Podane imię lub nazwisko nie pasuje do odnalezionego numeru PESEL. Konto zostało założone i oczekuje na aktywację, prosimy o sprawdzanie poczty. Możesz wrócić do strony logowania odnośnikiem poniżej formularza.'
									});
									break;
								case 'VALIDATE-ERRORS':
									reject(r.data.message);
								case 'DATA-ERRORS':
									resolve({
										data: r.data,
										msg: r.data.message
									});
									break;
								case 'DATA-WARNING':
									reject({
										data: r.data,
										msg: r.data.message
									});
									break;
								case 'DATA-MATCHED':
									resolve({
										data: r.data,
										msg: r.data.message,
										// msg: 'Twoje konto zostało założone i aktywowane - sprawdź swoją skrzynkę pocztową w celu kontynuacji. Możesz wrócić do strony logowania odnośnikiem poniżej formularza.'
									});
									break;
								default:
									reject('Wystąpił nieznany błąd podczas zakładania konta, spróbuj ponownie później lub skontaktuj się z administratorem (Kod błędu: ERR-1000)');
									break;
							}
						} else {
							resolve({
								data: r.data,
								msg: 'Twoje konto zostało założone. Możesz wrócić do strony logowania odnośnikiem poniżej formularza.'
							});
						}
					}).catch(err => {
						if(err && err.getCode()) {
							switch(err.getCode()) {
								case 'VALIDATE-ERRORS':
									reject(err.getMessage());
									// reject(`Nie można założyć konta, prosimy o skontaktowanie się z biurem obsługi pod nr ${cfg?.company_phone ?? '(nie ustawiono)'}`, err);
									break;
								case 'CONTRACTOR-NOT-MATCH':
									reject(`Odnaleziono Twoje dane w naszej bazie, lecz wystąpił błąd podczas ich przypisywania. Skontaktuj się z biurem obsługi pod nr ${cfg?.company_phone ?? '(nie ustawiono)'} lub spróbuj ponownie później`, err);
									break;
								default:
									reject('Wystąpił nieznany błąd podczas zakładania konta, spróbuj ponownie później lub skontaktuj się z administratorem', err);
									break;
							}
						} else {
							reject('Wystąpił nieznany błąd podczas zakładania konta, spróbuj ponownie później lub skontaktuj się z administratorem (Kod błędu: ERR-1001)', err);
						}
					});
				}).catch(err => {
					reject(err.getMessage());
				});
			}).catch(err => {
				reject(err.getMessage() ?? 'Wystąpił nieznany błąd podczas zakładania konta, spróbuj ponownie później lub skontaktuj się z administratorem.');
			});
		});
	},

	resetPasswordToken(email) {
		const _data = new FormData();

		_data.append('email', email);

		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.post('/api/reset-password-token', _data).then(() => {
					resolve();
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	checkPasswordToken(token) {
		const _data = new FormData();

		_data.append('token', token);

		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.post('/api/check-token', _data).then((r) => {
					resolve(r.data.valid);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	saveNewPassword(token, email, password) {
		const _data = new FormData();

		_data.append('token', token);
		_data.append('email', email);
		_data.append('password', password);

		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.post('/api/save-new-password', _data).then((r) => {
					resolve();
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},
};
