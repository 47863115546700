import { APIInstance, APIConfig } from '../../';
import { API } from '../../api';

export default {
	getConfig() {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.get(`/api/configs/info`).then(r => {
					if(r.data) {
						resolve(r.data);
					} else {
						resolve({});
					}
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},
};
