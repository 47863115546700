import { APIInstance } from '../../';
import { API } from '../../api';

export default {
	reftourists(id) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.get(`/api/appreftourists/${id}`).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	applyReftourists(values) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.post(`/api/appreftourists`, values).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	updateReftourists(id, values) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.post(`/api/appreftourists/${id}`, values).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	countReftourists(id) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.get(`/api/appreftourists/count/${id}`).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	}
};
