import { APIInstance } from './';

import * as APIEndpoints from './endpoints';

const API = {
	csrfCookie() {
		return APIInstance.get(`/sanctum/csrf-cookie`);
	},

	relations(relation_list, pid = null, filterObj = {}) {
		if(!Array.isArray(relation_list)) relation_list = [relation_list];

		const relationsQuery = new URLSearchParams({
			tables: relation_list.filter(el => (typeof el === 'object') ? (pid) ? true : false : true).map(el => (typeof el === 'object') ? el.name : el)
		});

		relation_list.filter(el => typeof el === 'object').forEach(el => {
			if(pid) {
				relationsQuery.append(`reg[${el.name}][table]`, el.table);
				relationsQuery.append(`reg[${el.name}][field]`, el.field);
				relationsQuery.append(`reg[${el.name}][profile]`, pid);
			}
		});

		// add filter to url for off* relation tables
		if (filterObj && Object.keys(filterObj).length > 0) {
			Object.keys(filterObj).forEach(function(key) {
				relationsQuery.append(`filter[${key}][id]`, filterObj[key]);
			});
		}

		return new Promise((resolve, reject) => {
			this.csrfCookie().then(() => {
				APIInstance.get(`/api/data?${relationsQuery.toString()}`).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	listApps(user_id = null, contractors_id = null) {
		return new Promise((resolve, reject) => {
			this.csrfCookie().then(() => {
				APIInstance.get(`/api/appviews?perPage=-1${user_id ? `&filter[user_id]=${user_id}` : ''}${contractors_id ? `&filter[for_contractors]=${contractors_id}` : ''}`).then(r => {
					resolve(r?.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	acceptApp(table, id) {
		const _data = new FormData();

		_data.append('id', id);

		return new Promise((resolve, reject) => {
			this.csrfCookie().then(() => {
				APIInstance.post(`/api/${table}/status/toaccept`, _data).then(r => {
					resolve();
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	countApp(table, id) {
		return new Promise((resolve, reject) => {
			this.csrfCookie().then(() => {
				APIInstance.get(`/api/${table}/count/${id}`).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	deleteApp(table, id) {
		return new Promise((resolve, reject) => {
			this.csrfCookie().then(() => {
				APIInstance.delete(`/api/${table}/${id}`).then(r => {
					resolve(true);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	poligon() {
		return new Promise((resolve, reject) => {
			this.csrfCookie().then(() => {
				APIInstance.get(`/api/poligon`).then(r => {
					resolve(r);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	dashboard() {
		return new Promise((resolve, reject) => {
			this.csrfCookie().then(() => {
				APIInstance.get(`/api/dashboard`).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	getSystemNotices(id) {
		return new Promise((resolve, reject) => {
			this.csrfCookie().then(() => {
				APIInstance.get(`/api/noticelist/system/${id}`).then(r => {
					resolve(r);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	getLoginNotices() {
		return new Promise((resolve, reject) => {
			this.csrfCookie().then(() => {
				APIInstance.get(`/api/noticelist/login`).then(r => {
					resolve(r);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},


};

export { API };

export default Object.assign({}, API, ...Object.values(APIEndpoints));
