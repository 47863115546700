import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './assets/global.scss';

// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';

// Sentry.init({
// 	dsn: "https://f731be0d43184cbfa7604e1c605ca269@o180972.ingest.sentry.io/5687128",
// 	release: "zfss-client@" + process.env.REACT_APP_VERSION,
// 	integrations: [new Integrations.BrowserTracing()],
// 	tracesSampleRate: 0.2,
// 	beforeBreadcrumb(breadcrumb, hint) {
// 		if(['console'].includes(breadcrumb.category)) {
// 			return null;
// 		}
// 		return breadcrumb;
// 	},
// 	maxBreadcrumbs: 5,
// 	attachStacktrace: false,
// });

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
