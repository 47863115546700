import React from 'react';
import { Switch, Redirect, Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import PrivateRoute from '../../navigation/PrivateRoute';

import { useAuth } from '../../contexts/User';

import logo from '../../assets/images/logos/impel_group_logo.png';
import logo_si from '../../assets/images/logos/logo_si_kolor.svg';
import instructionFile from '../../assets/files/INSTRUKCJA_SocialPartner.pdf';

import { backendApi } from '../../config/paths';

import { APIBackendConfig } from '../../api';
import { TYPE as FILE_TYPE } from '../../helpers/FilesHelper';
import PromiseQueue from '../../utils/PromiseQueue';

import Layout from '../../components/Layout';
import { Content as UIContent, Container, FlexBox, NotificationBell, ProfilePane } from '../../helpers/UI';

import { Dropdown, Menu, Alert, Affix, Modal } from 'antd';

import Dashboard_Home from './Home';
import Dashboard_Contractor from './Contractor';
import Dashboard_Profile from './Profile';

import Dashboard_Infants from './Infants';
import Dashboard_Infants_List from './Infants/list';
import Dashboard_Infants_View from './Infants/view';
import Dashboard_Infants_Edit from './Infants/edit';

import Dashboard_InfantPayments from './InfantPayments';
import Dashboard_InfantPayments_List from './InfantPayments/list';
import Dashboard_InfantPayments_View from './InfantPayments/view';
import Dashboard_InfantPayments_Edit from './InfantPayments/edit';

import Dashboard_Tickets from './Tickets';
import Dashboard_Tickets_View from './Tickets/view';
import Dashboard_Tickets_Edit from './Tickets/edit';

import Dashboard_Multis from './Multis';
import Dashboard_Multis_View from './Multis/view';
import Dashboard_Multis_Edit from './Multis/edit';

import Dashboard_Reftourists from './Reftourists';
import Dashboard_Reftourists_View from './Reftourists/view';
import Dashboard_Reftourists_Edit from './Reftourists/edit';

import Dashboard_Tourists from './Tourists';
import Dashboard_Tourists_View from './Tourists/view';
import Dashboard_Tourists_Edit from './Tourists/edit';

import Dashboard_Camps from './Camps';
import Dashboard_Camps_View from './Camps/view';
import Dashboard_Camps_Edit from './Camps/edit';

import Dashboard_Refcamps from './Refcamps';
import Dashboard_Refcamps_View from './Refcamps/view';
import Dashboard_Refcamps_Edit from './Refcamps/edit';

import Dashboard_Pears from './Pears';
import Dashboard_Pears_View from './Pears/view';
import Dashboard_Pears_Edit from './Pears/edit';

import Dashboard_Loans from './Loans';
import Dashboard_Loans_View from './Loans/view';
import Dashboard_Loans_Edit from './Loans/edit';

import Dashboard_Grants from './Grants';
import Dashboard_Grants_View from './Grants/view';
import Dashboard_Grants_Edit from './Grants/edit';

import Dashboard_Recreation from './Recreation';
import Dashboard_Recreation_View from './Recreation/view';
import Dashboard_Recreation_Edit from './Recreation/edit';

import Dashboard_Extras from './Extras';
import Dashboard_Extras_View from './Extras/view';
import Dashboard_Extras_Edit from './Extras/edit';

import Dashboard_Trips from './Trips';
import Dashboard_Trips_View from './Trips/view';
import Dashboard_Trips_Edit from './Trips/edit';

import Dashboard_Reftrips from './Reftrips';
import Dashboard_Reftrips_View from './Reftrips/view';
import Dashboard_Reftrips_Edit from './Reftrips/edit';

import createPersistedState from '../../hooks/usePersistedState';

import { APIBackend as API } from '../../api';

const useUserStageState = createPersistedState('userStage');
const useContractorState = createPersistedState('contractor');
const useBranchState = createPersistedState('branch');

const { Header, Content, Footer } = Layout;

const LogoWrapper = styled.div``;
const Logo = styled.img`
	width: 100px;
`;

const ContractorLogoWrapper = styled.div`
	padding-left: 15px;
	margin-left: 15px;
	border-left: 1px solid #eaeaea;
`;
const ContractorLogo = styled.img`
	width: 120px;
`;
const ContractorLogoText = styled.h4`
	font-weight: 600;
	margin: 0;
`;

const HeaderActions = styled.div`
	margin-left: auto;
`;

const UserPane = styled.div`
	margin-left: 25px;
`;

const FooterMenu = styled.ul`
	list-style: none;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-content: center;
	padding: 0;
	margin: 0;
`;
const FooterMenuItem = styled.li`
	margin-left: 30px;

	&:first-of-type {
		margin-left: 0;
	}
`;
const FooterLink = styled.a`

`;
const FooterItemContent = styled.span`

`;

const Dashboard = ({ ...props }) => {
	const auth = useAuth();
	const history = useHistory();
	const location = useLocation();

	const [error, setError] = React.useState(null);
	const [message, setMessage] = React.useState(null);

	const [userStage, setUserStage] = useUserStageState(0);

	React.useEffect(() => {
		if(auth.contractor && !auth.hasAcceptedContractor(auth.contractor) && !['/contractor', '/404'].includes(location.pathname)) {
			if(auth.contractorGDPR) {
				PromiseQueue.enqueue(() => {
					return new Promise((resolve, reject) => {
						Modal.confirm({
							title: 'Ogólna informacja o przetwarzaniu danych osobowych',
							content: <>
								Potwierdź, że zapoznałeś się z ogólną informacją o przetwarzaniu danych osobowych
								<br/>
								<a href={APIBackendConfig.baseURL+'/api/storage/app'+auth.contractorGDPR} target="_blank" rel="noreferrer">Klauzula informacyjna RODO</a>
							</>,
							okText: 'Akceptuję',
							onOk() {
								resolve();
							},
							onCancel() {
								reject();
							},
						});
					}).then(() => {
						auth.setAcceptedContractor(auth.contractor, 1);
					}).catch(err => {
						history.push('/contractor');
					});
				});
			}
		}
	}, [auth.contractor, auth.contractorRegulation, location.pathname, auth.profileLoaded]);

	const logout = (e) => {
		auth.logout().then(() => {})
					 .catch(() => {})
					 .finally(() => {
						 history.push('/login');
					 });
	};

	const Notifications = <>
		Powiadomienia
	</>;

	const Profile = <>
		<Menu>
			<Menu.Item onClick={() => { history.push('/profile'); }} key="menuItemNo1">
				Profil
			</Menu.Item>

			<Menu.Item onClick={logout} key="menuItemNo2">
				Wyloguj się
			</Menu.Item>
		</Menu>
	</>;

	const isContractorSelected = () => {
		return (auth.contractor !== null && (auth.branch !== null && auth.branch !== false))
	};

	return (
		<>
			<Layout>
				<Affix>
					<Header>
						<Container>
							<FlexBox>
								<LogoWrapper>
									<Link to="/">
										<Logo src={logo} />
									</Link>
								</LogoWrapper>

								<ContractorLogoWrapper>
									{(auth?.user?.contractors && auth.user.contractors.length && auth.user.contractors.find(el => el.id === auth.contractor))
										? (auth.user.contractors.find(el => el.id === auth.contractor).attachments && (auth.user.contractors.find(el => el.id === auth.contractor).attachments.filter(el => el.dctfiletype_id === FILE_TYPE.LOGOS).length > 0) && <ContractorLogo src={`${backendApi}/storage/app${auth.user.contractors.find(el => el.id === auth.contractor).attachments.filter(el => el.dctfiletype_id === FILE_TYPE.LOGOS)[0].file_path}`} /> || <ContractorLogoText>{auth.user.contractors.find(el => el.id === auth.contractor)?.name}</ContractorLogoText>)
										: null}
								</ContractorLogoWrapper>

								{
									(auth?.user?.contractors && auth.user.contractors.length > 1)
										? <Link to="/contractor" style={{ marginLeft: 25 }}>
											Zmień kontrahenta
										</Link>
										: null
								}

								<HeaderActions>
									<Dropdown overlay={Notifications}>
										<NotificationBell />
									</Dropdown>
								</HeaderActions>

								<UserPane className="zfss-profile-nav">
									<Dropdown overlay={Profile}>
										<a href="#">
											<ProfilePane />
										</a>
									</Dropdown>
								</UserPane>
							</FlexBox>
						</Container>
					</Header>
				</Affix>

				<Content>
					<UIContent style={{ paddingTop: '6rem', paddingBottom: '6rem' }}>
						<Container>
							{error && <Alert type="error" message={error.getMessage()} style={{ marginBottom: 20 }} />}
							{message && <Alert type="danger" message={message} style={{ marginBottom: 20 }} />}
							<Switch>
								<PrivateRoute path="/contractor" component={Dashboard_Contractor} />

								{/* If contractor isn't selected by user then show him selection page */}
								{!isContractorSelected() ? <Redirect to="/contractor" /> : null}

								<PrivateRoute path="/infants/edit/:id" component={Dashboard_Infants_Edit} />
								<PrivateRoute path="/infants/create" component={Dashboard_Infants} />
								<PrivateRoute path="/infants/:id" component={Dashboard_Infants_View} />
								<PrivateRoute path="/infants" component={Dashboard_Infants_List} />

								<PrivateRoute path="/infantpayments/edit/:id" component={Dashboard_InfantPayments_Edit} />
								<PrivateRoute path="/infantpayments/create/:infantId" component={Dashboard_InfantPayments} />
								<PrivateRoute path="/infantpayments/view/:id" component={Dashboard_InfantPayments_View} />
								<PrivateRoute path="/infantpayments/:infantId" component={Dashboard_InfantPayments_List} />

								<PrivateRoute path="/tickets/edit/:id" component={Dashboard_Tickets_Edit} />
								<PrivateRoute path="/tickets/:id" component={Dashboard_Tickets_View} />
								<PrivateRoute path="/tickets" component={Dashboard_Tickets} />

								<PrivateRoute path="/multis/edit/:id" component={Dashboard_Multis_Edit} />
								<PrivateRoute path="/multis/:id" component={Dashboard_Multis_View} />
								<PrivateRoute path="/multis" component={Dashboard_Multis} />

								<PrivateRoute path="/reftourists/edit/:id" component={Dashboard_Reftourists_Edit} />
								<PrivateRoute path="/reftourists/:id" component={Dashboard_Reftourists_View} />
								<PrivateRoute path="/reftourists" component={Dashboard_Reftourists} />

								<PrivateRoute path="/tourists/edit/:id" component={Dashboard_Tourists_Edit} />
								<PrivateRoute path="/tourists/:id" component={Dashboard_Tourists_View} />
								<PrivateRoute path="/tourists" component={Dashboard_Tourists} />

								<PrivateRoute path="/refcamps/edit/:id" component={Dashboard_Refcamps_Edit} />
								<PrivateRoute path="/refcamps/:id" component={Dashboard_Refcamps_View} />
								<PrivateRoute path="/refcamps" component={Dashboard_Refcamps} />

								<PrivateRoute path="/camps/edit/:id" component={Dashboard_Camps_Edit} />
								<PrivateRoute path="/camps/:id" component={Dashboard_Camps_View} />
								<PrivateRoute path="/camps" component={Dashboard_Camps} />

								<PrivateRoute path="/reftrips/edit/:id" component={Dashboard_Reftrips_Edit} />
								<PrivateRoute path="/reftrips/:id" component={Dashboard_Reftrips_View} />
								<PrivateRoute path="/reftrips" component={Dashboard_Reftrips} />

								<PrivateRoute path="/trips/edit/:id" component={Dashboard_Trips_Edit} />
								<PrivateRoute path="/trips/:id" component={Dashboard_Trips_View} />
								<PrivateRoute path="/trips" component={Dashboard_Trips} />

								<PrivateRoute path="/extras/edit/:id" component={Dashboard_Extras_Edit} />
								<PrivateRoute path="/extras/:id" component={Dashboard_Extras_View} />
								<PrivateRoute path="/extras" component={Dashboard_Extras} />

								<PrivateRoute path="/rehabs/edit/:id" component={Dashboard_Recreation_Edit} />
								<PrivateRoute path="/rehabs/:id" component={Dashboard_Recreation_View} />
								<PrivateRoute path="/rehabs" component={Dashboard_Recreation} />

								<PrivateRoute path="/grants/edit/:id" component={Dashboard_Grants_Edit} />
								<PrivateRoute path="/grants/:id" component={Dashboard_Grants_View} />
								<PrivateRoute path="/grants" component={Dashboard_Grants} />

								<PrivateRoute path="/loans/edit/:id" component={Dashboard_Loans_Edit} />
								<PrivateRoute path="/loans/:id" component={Dashboard_Loans_View} />
								<PrivateRoute path="/loans" component={Dashboard_Loans} />

								<PrivateRoute path="/pears/edit/:id" component={Dashboard_Pears_Edit} />
								<PrivateRoute path="/pears/:id" component={Dashboard_Pears_View} />
								<PrivateRoute path="/pears" component={Dashboard_Pears} />

								<PrivateRoute path="/profile/:id?" component={Dashboard_Profile} />

								<PrivateRoute exact path="/" component={Dashboard_Home} />

								<Redirect to="/404" />
							</Switch>
						</Container>
					</UIContent>
				</Content>

				<Footer>
					<Container>
						<div className="footer">
							<div className="footer__meta">
								<div>
									<span className="text-muted footer__build">version {process.env.REACT_APP_VERSION}</span>
								</div>
							</div>

							<FooterMenu>
								<FooterMenuItem>
									<FooterLink download="INSTRUKCJA_SOCIAL_PARTNER.pdf" href={instructionFile}>
										<FooterItemContent>Instrukcja składania wniosków</FooterItemContent>
									</FooterLink>
								</FooterMenuItem>

								{
									auth.contractorRegulation
										? <FooterMenuItem>
											<FooterLink href={APIBackendConfig.baseURL+'/api/storage/app'+auth.contractorRegulation}>
												<FooterItemContent>Regulamin kontrahenta</FooterItemContent>
											</FooterLink>
										</FooterMenuItem>
										: null
								}

								{
									auth.contractorGDPR
										? <FooterMenuItem>
											<FooterLink href={APIBackendConfig.baseURL+'/api/storage/app'+auth.contractorGDPR}>
												<FooterItemContent>Klauzula informacyjna RODO</FooterItemContent>
											</FooterLink>
										</FooterMenuItem>
										: null
								}
							</FooterMenu>
						</div>
					</Container>
				</Footer>
			</Layout>
		</>
	);
};

export default Dashboard;
