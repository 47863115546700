import { APIInstance } from '../../';
import { API } from '../../api';

export default {
	getActiveProfile(user_id, contractor_id) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.get(`/api/profiles/active/${user_id}/${contractor_id}`).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	profiles(user_id, contractor_id) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.get(`/api/profiles?perPage=-1&filter[contractors.id]=${contractor_id}`).then(r => {
					resolve(Array.isArray(r.data?.data) ? r.data.data.filter(el => el.user_id === user_id && el.contractor_id === contractor_id) : r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	profile(id) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.get(`/api/profiles/${id}`).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	profileWasUsed(id) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.get(`/api/profiles/wasused/${id}`).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	acceptProfile(profile_id) {
		const _data = new FormData();

		_data.append('id', profile_id);

		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.post(`/api/profiles/status/toaccept`, _data).then(r => {
					resolve();
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	createProfile(values) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.post(`/api/profiles`, values).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	updateProfile(id, values) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.post(`/api/profiles/${id}`, values).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	hasAnyProfile(user_id, contractor_id) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.get(`/api/profiles/isprofile/${user_id}/${contractor_id}`).then(r => {
					resolve(r.data.statusId);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},
};
