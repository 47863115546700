import moment from 'moment';

const prepareData = (data, form = null, namespace = null) => {
	if(typeof data === 'object' && (data instanceof FormData)) {
		return data;
	}

	let fd = form ?? new FormData();
	let key;

	for(let property in data) {
		if(data.hasOwnProperty(property)) {
			key = namespace ? `${namespace}[${property}]` : property;

			if(typeof data[property] === 'object'
				&& !(data[property] instanceof File)
				&& !moment.isMoment(data[property])
			) {
				prepareData(data[property], fd, property);
			} else {
				if(moment.isMoment(data[property])) {
					fd.append(key, data[property].format('YYYY-MM-DD'));
				} else {
					if(data[property] !== null && typeof data[property] !== 'undefined') {
						fd.append(key, data[property]);
					}
				}
			}
		}
	}

	return fd;
};

export { prepareData };

export { default as APIConfig } from './config.js'

export * from './instances';
