import styled from 'styled-components';

import Header from './Header';
import Content from './Content';
import Footer from './Footer';

const Layout = styled.div.attrs(props => ({

}))`
	// display: grid;
	// grid-template-rows: auto 1fr auto;
	display: flex;
	flex-direction: column;
	height: 100vh;
	width: 100%;
`;

Layout.Header = Header;
Layout.Content = Content;
Layout.Footer = Footer;

export default Layout;
