import axios from 'axios';

import { APIConfig } from './';

import { prepareData } from '../../';

import APIInterceptorsResponse from './interceptors/response';

const APIInstance = axios.create({
	baseURL: APIConfig.baseURL,
	withCredentials: APIConfig?.withCredentials ?? false,
	transformRequest: function(data, headers) {
		if(APIConfig?.detectContentType ?? true) {
			// Browser will detect proper Content-Type
			headers['Content-Type'] = undefined;
		}

		return prepareData(data);
	}
});

APIInstance.interceptors.response.use(...APIInterceptorsResponse);

export default APIInstance;
