import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

import { Main, Container } from '../../helpers/UI';

import { Row, Col, Form, Card, Input, Button, Alert, Checkbox, Modal, notification, Spin } from 'antd';

import Logo_Sanpro from '../../assets/images/logos/sanpro.png';
import Sygnet_Impel from '../../assets/images/logos/impel_sygnet_color.png';

import { useAuth } from '../../contexts/User';

import { APIBackend as API } from '../../api';

import { PeselValidator } from '../../helpers/Validators';

const LoginPane = styled.div`
	display: table-cell;
	vertical-align: middle;
`;

const LoginPaneTitle = styled.div`
	font-size: 24px;
`;

const LoginPaneSubtitle = styled.div`
	font-size: 14px;
	font-weight: 400;
	margin: 10px 0 0;
`;

const CenteredCol = styled(Col)`
	display: table;
	margin: 0 auto;
	height: 100%;
`;

const LoginPaneStyles = createGlobalStyle`
	${LoginPane} .ant-card-head {
		background: #457b9d;
		color: white;
		position: relative;
		text-align: center;
		font-family: 'Poppins', sans-serif;
		padding-bottom: 35px;
	}

	${LoginPane} .ant-card-head:after {
		display: block;
		content: '';
		clear: both;
		position: absolute;
		bottom: -73px;
		left: 50%;
		width: 73px;
		height: 73px;
		border-radius: 100%;
		border: 6px solid #FFF;
		box-shadow: 0 2px 0 -1px #0072a3;
		transform: translate(-50%, -50%);
		background: url(${Sygnet_Impel}) no-repeat center center #FFF;
		background-size: 100% 100%;
	}

	${LoginPane} .ant-card-body {
	    padding-top: 44px;
	}
`;

const CenterBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Register = ({ ...props }) => {
	const [form] = Form.useForm();

	const [changes, setChanges] = React.useState({});
	const [isError, setError] = React.useState(false);
	const [checked, setChecked] = React.useState(false);
    const [message, setMessage] = React.useState(false);
    const [created, setCreated] = React.useState(false);
    const [creating, setCreating] = React.useState(false);
    const [rodoModalVisible, setRodoModalVisible] = React.useState(false);
	const [isLoading, setLoading] = React.useState(true);
	const [config, setConfig] = React.useState(null);

	const auth = useAuth();
	const history = useHistory();

	const onCheckboxChange = async (e) => {
		await setChecked(e.target.checked);
		form.validateFields(['regulation']);
	};

	const onRegister = (values) => {
		const referrer = (props.location.state) ? props.location.state.from : null || '/';

		setCreating(true);

		values.is_agree = values.is_agree ? 1 : 0;

		auth.register(values).then(({data, msg = false}) => {
			setMessage(msg ?? false);
			if(msg) notification.success({
				message: msg,
				duration: 15
			});
			setError(false);
			setCreated(true);
			// setTimeout(() => {
			// 	history.push('/login');
			// }, 2500);
		}).catch(err => {
			setError(err);
			notification.error({
				message: err,
				duration: 15
			});
		}).finally(() => {
			setCreating(false);
		});
	};

	const showRodoModal = () => {
		setRodoModalVisible(true);
	};

	const hideRodoModal = () => {
		setRodoModalVisible(false);
	};

	const handleCancel = () => {
		hideRodoModal();
	};

	const onChange = (field, allFields) => {
		setChanges(allFields);
	};

	const isGdprRequired = () => {
		return (
			changes?.is_agree_offer
			||
			changes?.is_agree_preference
			||
			changes?.is_agree_satisfaction
		);
	};

	React.useEffect(() => {
		setLoading(true);
		API.getConfig().then(cfg => {
			setConfig(cfg);
		}).finally(() => {
			setLoading(false)
		})
	}, []);

	return (
		<Main>
			<Container>
				<Row style={{ height: '100vh' }}>
					<CenteredCol sm={20} md={16} lg={12}>
						<LoginPane>
							{ isError && <Alert message={isError} type="error" style={{ marginBottom: '15px' }} /> }

							{ message && <Alert message={message} type="success" style={{ marginBottom: '15px' }} /> }

							<LoginPaneStyles />
							<Card
								title={<>
									<LoginPaneTitle>Rejestracja konta</LoginPaneTitle>
								</>}
								 style={{ marginTop: 50, marginBottom: 50 }}
							>
								{
									isLoading
										? <CenterBox style={{ marginTop: 15 }}>
											<Spin />
										</CenterBox>
										: <Form
											form={form}
											name="register-form"
											layout="vertical"
											onFinish={onRegister}
											onValuesChange={onChange}
										>
											<Form.Item
												label="Adres e-mail:"
												name="email"
												rules={[{ required: true, message: 'Proszę wprowadzić adres e-mail' },{ type: 'email', message: 'Proszę wprowadzić poprawny adres e-mail' }]}>
												<Input />
											</Form.Item>

											<Form.Item
												label="Imię:"
												name="first_name"
												rules={[{ required: true, message: 'Proszę wprowadzić imię' }]}>
												<Input />
											</Form.Item>

											<Form.Item
												label="Nazwisko:"
												name="last_name"
												rules={[{ required: true, message: 'Proszę wprowadzić nazwisko' }]}>
												<Input />
											</Form.Item>

											<Form.Item
												label="Numer telefonu:"
												name="phone"
												rules={[{ required: true, message: 'Proszę wprowadzić numer kontaktowy' }, { pattern: /^(?<!\w)(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)$/, message: 'Proszę wprowadzić prawidłowy numer telefonu' }]}>
												<Input />
											</Form.Item>

											<Form.Item
												label="PESEL:"
												name="pesel"
												rules={[
													{ required: true, message: 'Proszę wprowadzić PESEL' },
													(config?.is_pesel_checking ?? true)
														? ({ getFieldValue, setFieldsValue, getFieldsValue }) => ({
															validator(_, value) {
																if(value) {
																	if(PeselValidator.isValid(value)) {
																		return Promise.resolve();
																	} else {
																		return Promise.reject(new Error('Podany numer PESEL jest nieprawidłowy'));
																	}
																}

																return Promise.resolve();
															}
														})
														: {
															pattern: /^\d{11}$/,
															message: 'Podany numer PESEL jest nieprawidłowy'
														}
												]}>
												<Input />
											</Form.Item>

											<Form.Item
												label="Dodatkowe notatki:"
												name="remarks">
												<Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
											</Form.Item>

											<Form.Item
												initialValue={false}
												name="is_agree_preference"
												valuePropName="checked"
											>
												<Checkbox>Wyrażam zgodę na przesyłanie na mój numer telefonu komórkowego oraz/lub adres poczty elektronicznej anonimowych ankiet służących ustalaniu moich preferencji wypoczynkowych.</Checkbox>
											</Form.Item>

											<Form.Item
												initialValue={false}
												name="is_agree_offer"
												valuePropName="checked"
											>
												<Checkbox>Wyrażam zgodę na przesyłanie na mój numer telefonu komórkowego oraz/lub adres poczty elektronicznej aktualnej oferty wypoczynku turystyczno-rekreacyjnego.</Checkbox>
											</Form.Item>

											<Form.Item
												initialValue={false}
												name="is_agree_satisfaction"
												valuePropName="checked"
											>
												<Checkbox>Wyrażam zgodę na kontakt telefoniczny na mój numer telefonu komórkowego celem przeprowadzenia ankiety powyjazdowej (ankieta satysfakcji).</Checkbox>
											</Form.Item>

											{
												isGdprRequired()
													? <Form.Item
														initialValue={false}
														name="gdpr"
														valuePropName="checked"
														rules={[{ validator: (rule, value, callback) => { if(checked || !isGdprRequired()) { return callback(); } return callback('Ta zgoda jest wymagana'); } }]}>
														<Checkbox onChange={onCheckboxChange}>
															<div className="ant-form-item-label">
																<label className="ant-form-item-required"><span>Wyrażam zgodę na przetwarzanie moich danych osobowych w celach marketingowych i handlowych (<a href="#" onClick={(e) => {
																	e.preventDefault();
																	showRodoModal();
																}}>Klauzula informacyjna RODO</a>)</span></label>
															</div>
														</Checkbox>
													</Form.Item>
													: null
											}

											<Row>
												<Col span={24} style={{ textAlign: 'right' }}>
													<Form.Item>
														<Button type="primary" htmlType="submit" loading={creating} disabled={created}>
															Załóż konto
														</Button>
													</Form.Item>
												</Col>
											</Row>

											<Row>
												<Col span={24}>
													<Link to="/login">
														Wróć do logowania
													</Link>
												</Col>
											</Row>
										</Form>
								}
							</Card>
						</LoginPane>
					</CenteredCol>
				</Row>
			</Container>

			<Modal
				title="Klauzula informacyjna RODO"
				visible={rodoModalVisible}
				cancelText="Zamknij"
				onCancel={handleCancel}
				okButtonProps={{ style: { display: 'none' }}}
			>
				<section>
					<h2>Po co nam Twoje dane?</h2>
					<p>Chcemy wykorzystać zostawiony nam przez Ciebie numer telefonu komórkowego oraz/lub adres poczty elektronicznej po to, żeby móc:</p>
					<p>
						<ul>
							<li>przesyłać Ci aktualne oferty dotyczące wypoczynku turystyczno-rekreacyjnego,</li>
							<li>poznać Twoje preferencje wypoczynkowe (w postaci anonimowych ankiet),</li>
							<li>dowiedzieć się czy po skorzystaniu z oferty wypoczynku nasz usługa spełniła Twoje oczekiwania (w postaci ankiet powyjazdowych).</li>
						</ul>
					</p>
					<p>Prosimy zatem, abyś wskazał nam, czy się zgadasz na kontakt telefoniczny z naszej strony,  w  tym kierowanie przez nas na Twój numer telefonu komórkowego lub adres poczty elektronicznej aktualnej oferty Sanpro Synergy Sp. z o.o.,linku do ankiet dotyczących Twoich preferencji wypoczynkowych lub na kontakt   telefoniczny mój  numer  telefonu  komórkowego  celem  przeprowadzenia  ankiety powyjazdowej (ankieta satysfakcji).</p>
					<p>Jeśli się nie zgodzisz na kontakt z naszej strony zachowany Twoje oświadczenie i nie będziemy w przyszłości przesyłać Ci i kontaktować się w sprawie ankiet tego rodzaju oraz aktualnej  oferty wypoczynku    turystyczno-rekreacyjnego  (odnotujemy  w  swoich  systemach  tę  informację). Informujemy Cię jednocześnie, że w każdej chwili możesz zmienić swoje zdanie i poprosić o przesłanie Ci ankiety.</p>
					<p>Jeśli się zgodzisz będziemy wykorzystywać Twój numer  telefonu  prywatnego  oraz/lub  adres  poczty elektronicznej na warunkach wskazanych poniżej. Swoją zgodę możesz w każdej chwili wycofać, żeby nie otrzymywać w przyszłości tego rodzaju wiadomości.</p>
					<p>Prosimy Cię, abyś przed podjęciem decyzji zapoznał/a się z klauzulą informacyjną wskazaną poniżej.</p>

					<h3>Klauzula informacyjna RODO</h3>
					<p>
						<strong>Kto będzie administratorem Twoich danych</strong>
						<br/>
						Jeśli się zgodzisz na wykorzystanie Twoich danych osobowych w celu przesłania linku do ankiety, aktualnej oferty wypoczynku turystyczno-rekreacyjnego oraz  kontakt  telefoniczny  celem  przeprowadzenia  ankiety  powyjazdowej odpowiedzialnym  za ochronę danych osobowych  będzie Sanpro Synergy sp. z o.o.z siedzibą w Łodzi (90-318) przy ul. H. Sienkiewicza 85/87, zwana dalej „Spółką”.
					</p>
					<p>
						<strong>Dane kontaktowe administratora</strong>
						<br/>
						Adres korespondencyjny: Bielany Wrocławskie, ul. Magazynowa 4, Bielany, 55-040 Kobierzyce.
					</p>
					<p>
						<strong>W jakim celu i na jakiej podstawie przetwarzamy dane?</strong>
						<br/>
						Potrzebujemy Twój numer telefonu komórkowego oraz  adres  poczty  elektronicznej (jeśli został podany), żeby wysyłać Ci prośbę o wypełnienie anonimowej ankiety zawierającej pytania o to w jaki sposób lubisz lub chciałbyś wypoczywać oraz kontakt telefoniczny celem przeprowadzenia ankiety powyjazdowej. Dzięki Twoim odpowiedziom oferta naszych wycieczek może być dla Ciebie atrakcyjniejsza. Jeśli  zgodzisz  się  na  wykorzystanie  Twojego  numeru  telefonu  komórkowego  oraz  adresu  poczty  elektronicznej,  aby przedstawiać Ci aktualną ofertę wypoczynku turystyczno-rekreacyjnego, to będziemy co pewien czas wysyłać na te numery lub adresy wiadomości zawierające te informacje.
					</p>
					<p>
						<strong>Kto może być odbiorcą Twoich danych osobowych</strong>
						<br/>
						Spółka nie ujawni Twoich danych. Dostęp do Twoich danych w postaci numeru telefonu komórkowego oraz/lub adresu poczty elektronicznej będą mieli wyłącznie nasi pracownicy.
					</p>
					<p>
						<strong>Jak długo będziemy przetwarzać Twoje dane?</strong>
						<br/>
						Twoje  dane  osobowe  usuniemy  z  listy dystrybucyjnej, kiedy dasz nam znać, że nie chcesz już otrzymywać na swój numer telefonu komórkowego lub  adres  poczty  elektronicznej linków  do ankiety lub  aktualnej  oferty wypoczynku  turystyczno-rekreacyjnego oraz nie wyrażasz zgody na kontakt telefoniczny celem przeprowadzenia ankiety powyjazdowej. Możesz to zrobić, gdy na adres e-mail: <a href="mailto:iod.sanpro@impel.pl">iod.sanpro@impel.pl</a> wyślesz informacje, z której wynikać będzie, że nie zgadzasz się na dalsze przesyłanie Ci aktualnej oferty związanej wypoczynku turystyczno-rekreacyjnego, linków do ankiet lub na kontakt telefoniczny  celem  przeprowadzenia  ankiety  powyjazdowej. Możesz wycofać zgodę na przesyłanie Ci naszej oferty a
						pozostawić zgodę na dalsze przesyłanie Ci linków do wypełnienia dobrowolnych ankiet. Możesz też oczywiście wycofać obie zgody.
					</p>
					<p>
						<strong>Jakie masz prawa związane z wykorzystaniem Twoich danych?</strong>
						<br/>
						Prawo:
						<ul>
							<li>dostępu do swoich danych osobowych i otrzymania ich kopii;</li>
							<li>sprostowania (poprawienia) nieprawidłowych danych osobowych;</li>
							<li>żądania usunięcia danych osobowych z  listy  dystrybucyjnej  (potraktujemy  to  wtedy  jako  wycofanie  zgody  na przetwarzanie Twoich danych we ww. celach)</li>
							<li>żądania ograniczenia przetwarzania;</li>
							<li>wycofania zgody na przetwarzanie danych osobowych w dowolnym momencie (jak to zrobić wskazaliśmy powyżej);</li>
							<li>przeniesienia danych osobowych (otrzymania w postaci elektronicznej informacji o numerze telefonu oraz adresu poczty elektronicznej, które wykorzystujemy w celu wysyłki ankiet);</li>
							<li>wniesienia skargi do Prezes UODO (na adres Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00 -193 Warszawa), jeżeli uważasz, że Twoje dane osobowe są przetwarzane niezgodnie z prawem.</li>
						</ul>
					</p>
					<p>
						<strong>Kontakt do naszego Inspektora Ochrony Danych</strong>
						<br/>
						Jeśli  potrzebujesz dodatkowych  informacji  związanych  z  ochroną  danych  osobowych  lub  chcesz skorzystać z przysługujących praw, prosimy o kontakt z naszym Inspektorem Ochrony Danych:
						<ul>
							<li>E-mail: <a href="mailto:iod.sanpro@impel.pl">iod.sanpro@impel.pl</a>,</li>
							<li>telefon +48 535 999 739 (w takim wypadku prosimy Cię, żebyś wskazał, że dzwonisz w sprawie przetwarzania przez Sanpro Synergy sp. z o.o. Twoich danych dla potrzeb wypełnienia ankiet, przesyłania aktualnej oferty wypoczynku turystyczno-rekreacyjnego oraz kontaktu telefonicznego celem przeprowadzenia ankiety powyjazdowej).</li>
						</ul>
					</p>
				</section>
			</Modal>
		</Main>
	);
};

export default Register;
