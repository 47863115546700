import React from 'react';
import ReactDOMServer from 'react-dom/server'

class ProfileImage extends React.Component {
	constructor(props) {
		super(props);
	}

	HSVtoRGB(h, s, v) {
		let hue = h / 60;
		let i = Math.floor(hue);
		let f = h - i;

		let sat = s / 100;

		let p = v * (1 - sat);
		let q = v * (1 - sat * f);
		let t = v * (1 - sat * (1 - f));

		let p_rgb = (p * 255 / 100);
		let v_rgb = (v * 255 / 100);
		let t_rgb = (t * 255 / 100);
		let q_rgb = (q * 255 / 100);

		let rgb = { r: 0, g: 0, b: 0 };

		switch(i) {
			case 0: rgb = { r: v_rgb, g: t_rgb, b: p_rgb }; break;
			case 1: rgb = { r: q_rgb, g: v_rgb, b: p_rgb }; break;
			case 2: rgb = { r: p_rgb, g: v_rgb, b: t_rgb }; break;
			case 3: rgb = { r: p_rgb, g: q_rgb, b: v_rgb }; break;
			case 4: rgb = { r: t_rgb, g: p_rgb, b: v_rgb }; break;
			default: rgb = { r: v_rgb, g: p_rgb, b: q_rgb }; break;
		}

		return rgb;
	}

	randomSeed() {
		return 200;
		return Math.floor(Math.random() * (+359 - +1)) + +1;
	}

	randomColor() {
		let hsv = {
			h: this.randomSeed(),
			s: this.props.saturation,
			v: this.props.value
		};

		let rgb = this.HSVtoRGB(hsv.h, hsv.s, hsv.v);

		let hex = '#' + parseInt((
			(1 << 24) + (rgb.r << 16) + (rgb.g << 8) + rgb.b
		)).toString(16).slice(1);

		return {
			hsv: hsv,
			rgb: rgb,
			hex: hex
		};
	}

	parseInitials(text) {
		// Clean up (punctation, seperator chars, control chars and numbers)
		text = text.replace(/[\p{P}\p{S}\p{C}\p{N}]+/gu, '');

		// Replace all possible whitespace/separator chars with a single ASCII space
		text = text.replace(/\p{Z}+/gu, ' ');

		let initials = text.match(/(?<![\p{N}\p{L}])\p{L}/gu) || [];

		initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();

		return initials;
	}

	render() {
		let bg = this.randomColor();

		const { shape, size, name, saturation, value, className, ...props } = this.props;

		let fontsize = size / 2.4;
		let diameter = size / 2;

		const b64UnicodeEncode = (str) => {
			return btoa(
				encodeURIComponent(str).replace(
					/%([0-9A-F]{2})/g,
					(match, p1) => String.fromCharCode('0x'+p1)
				)
			);
		};

		// eslint-disable-next-line no-unused-vars
		const b64UnicodeDecode = (str) => {
			return decodeURIComponent(
				atob(str)
					.split('')
					.map(c => '%'+('00'+c.charCodeAt(0).toString(16)).slice(-2))
					.join('')
			);
		};

		return (<div className={ 'userPhoto'+(className ? ' '+className : '') } {...props}>
			{ this.props.shape === 'rect' ?
				<img alt={this.parseInitials(name)} src={'data:image/svg+xml;base64,'+b64UnicodeEncode(ReactDOMServer.renderToString(<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={size} height={size}>
					<g>
						<rect width={size} height={size} fill={bg.hex} />
						<text fill="white" x="50%" y="65%" textAnchor="middle" style={{ font: 'bold '+fontsize+'px Arial, sans-serif' }}>
							{this.parseInitials(name)}
						</text>
					</g>
				</svg>))} />
				:
				<img alt={this.parseInitials(name)} src={'data:image/svg+xml;base64,'+b64UnicodeEncode(ReactDOMServer.renderToString(<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={size} height={size}>
					<circle cx={diameter} cy={diameter} r={diameter} stroke="white" stroke-width="4" fill={bg.hex}>
						<text fill="white" x="50%" y="65%" textAnchor="middle" style={{ font: 'bold '+fontsize+'px Arial, sans-serif' }}>
							{this.parseInitials(name)}
						</text>
					</circle>
				</svg>))} />
			}
		</div>);
	}
}

ProfileImage.defaultProps = {
	shape: 'rect',
	size: 100,
	saturation: 50,
	value: 90,
	name: 'ZFSS'
};

export default ProfileImage;
