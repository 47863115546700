class APIConfig {
	cfg;

	constructor(cfg) {
		this.cfg = this.parseConfig(cfg);
	}

	parseConfig(cfg) {
		const branch = process.env.NODE_ENV === 'production' ? 'production' : 'development';

		const defaults = cfg.defaults;
		const config = cfg.hasOwnProperty(branch) ? cfg[branch] : {};

		return Object.assign({
			baseURL: '/'
		}, defaults, config);
	}
}

export default APIConfig;
