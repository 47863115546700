import React from 'react';
import styled from 'styled-components';

import { useAuth } from '../../../contexts/User';

import { objectMap } from '../../../utils/functions/objectMap';

import { FlexBox } from '../../../helpers/UI';

import DraggerUpload from '../../../components/DraggerUpload';

import { PeselValidator } from '../../../helpers/Validators';

import { InboxOutlined } from '@ant-design/icons';
import {
	Row,
	Col,
	Card,
	Form,
	Button,
	Input,
	InputNumber,
	Select,
	DatePicker,
	Switch,
	Skeleton,
	Tabs,
	message,
	Steps,
	Alert,
	Checkbox
} from 'antd';

import { APIBackend as API } from '../../../api';

import moment from 'moment';

import createPersistedState from '../../../hooks/usePersistedState';

const { Option } = Select;
const { Step } = Steps;

const useContractorState = createPersistedState('contractor');
const useBranchState = createPersistedState('branch');

const StepsContent = styled.div`
	margin-top: 25px;
`;

const ErrorSwitch = styled(Switch)`
	&:not(.ant-switch-checked) {
		background: #ff1843;
	}
`;

const Dashboard_Profile_Create = ({ ...props }) => {
	const auth = useAuth();
	const [form] = Form.useForm();

	const [contractor, setContractor] = useContractorState(null);
	const [branch, setBranch] = useBranchState(null);

	const [relationData, setRelationData] = React.useState(null);
	const [config, setConfig] = React.useState(null);
	const [saving, setSaving] = React.useState(false);

	const [attachments, setAttachments] = React.useState({
		list: [],
		add: [],
		del: []
	});
	const [attachments2, setAttachments2] = React.useState({
		list: [],
		add: [],
		del: []
	});

	React.useEffect(() => {
		API.relations(['dctmaritials', 'dctrelationships', 'dcteducations', 'dctdisabilities', 'dctincometypes', 'dctfiletypes']).then(data => {
			setRelationData(data);
		}).catch(err => {
			console.log(err);
		});

		API.getConfig().then(cfg => {
			setConfig(cfg);
		})
	}, []);

	const onFail = (data) => {
		const {
			values,
			errorFields,
			outOfDate
		} = data;

		if(errorFields.length) {
			let errorField = errorFields[0];

			errorFields.forEach(el => {
				if(el.errors[0]) {
					message.error(`${el.errors[0]}`);
				}
			});
		}
	};

	const onSave = (values) => {
		setSaving(true);
		Object.keys(values).forEach(el => {
			if(el === 'birth_date') {
				values[el] = values[el].format('YYYY-MM-DD');
			} else if(['incomeothers', 'families'].includes(el)) {
				values[el] = values[el] && typeof values[el] === 'object'
					? JSON.stringify(
						objectMap(values[el], el => {
							if(el.hasOwnProperty('birth_date')) {
								el.birth_date = el.birth_date.format('YYYY-MM-DD');
							}

							if(el.hasOwnProperty('dcteducation_id') && el['dcteducation_id']) {
								el.is_education = 1;
							}

							if(el.hasOwnProperty('dctdisability_id') && el['dctdisability_id']) {
								el.is_disability = 1;
							}

							return el;
						})
					)
					: null
			} else if(['attachments', 'attachments2'].includes(el)) {
				if(attachments.hasOwnProperty('add')) {
					attachments.add.forEach(el => {
						if(el) {
							values[`files[${el.category}/${el.name}]`] = el.file;
						}
					});
				}

				if(attachments.hasOwnProperty('del')) {
					attachments.del.forEach(el => {
						if(el) {
							values[`delete[${el.category}/${el.name}]`] = el.uid;
						}
					});
				}

				if(attachments2.hasOwnProperty('add')) {
					attachments2.add.forEach(el => {
						if(el) {
							values[`files[${el.category}/${el.name}]`] = el.file;
						}
					});
				}

				if(attachments2.hasOwnProperty('del')) {
					attachments2.del.forEach(el => {
						if(el) {
							values[`delete[${el.category}/${el.name}]`] = el.uid;
						}
					});
				}

				delete values[el];
			}
		});

		const _data = new FormData();

		for(let k in values) {
			let val = values[k];

			if(val !== null && val !== undefined && val !== '') {
				_data.append(k, val);
			}
		}

		_data.append('user_id', auth.user.id);
		_data.append('contractor_id', contractor);
		_data.append('branch_id', branch);
		_data.append('type', 'K');
		if(auth.user?.pesel) {
			_data.append('pesel', auth.user.pesel);
		}

		API.createProfile(_data).then(r => {
			message.success('Profil został utworzony');
			props.changePage('drafts');
		}).catch(err => {
			if(props?.setError) props.setError(err);
		}).finally(() => {
			setSaving(false);
		});
	};

	const steps = [
		{
			key: 'step-general',
			tabKey: 'general',
			title: 'Dane podstawowe',
			description: 'Uzupełnij swoje dane'
		},
		{
			key: 'step-family',
			tabKey: 'family',
			title: 'Rodzina',
			description: 'Dodaj do formularza członków swojej rodziny'
		},
		{
			key: 'step-incomes',
			tabKey: 'incomes',
			title: 'Dochody',
			description: 'Dodaj do formularza wszelkie dochody rodziny'
		},
		{
			key: 'step-attachments',
			tabKey: 'attachments',
			title: 'Załączniki',
			description: 'Dodaj odpowiednie załączniki'
		},
		{
			key: 'step-agreements',
			tabKey: 'agreements',
			title: 'Oświadczenia',
			description: 'Oświadczenia o prawdziwości podawanych danych'
		},
	];

	const [currentStep, setCurrentStep] = React.useState(0);
	const [activeTab, setActiveTab] = React.useState('general');

	const hasNextStep = () => {
		return currentStep + 1 <= (steps.length - 1);
	};
	const hasPrevStep = () => {
		return currentStep - 1 >= 0;
	};

	const nextStep = () => {
		if(hasNextStep()) {
			setCurrentStep(currentStep + 1);
		}
	};
	const prevStep = () => {
		if(hasPrevStep()) {
			setCurrentStep(currentStep - 1);
		}
	};

	React.useEffect(() => {
		if(steps.hasOwnProperty(currentStep)) {
			setActiveTab(steps[currentStep].tabKey);
		}
	}, [currentStep, setCurrentStep]);

	const onValuesChange = (field, allFields) => {
		if(allFields.families && allFields.families.length) {
			allFields.families.forEach(el => {
				if(el && el.pesel && PeselValidator.isValid(el.pesel)) {
					if(!el.birth_date) {
						el.birth_date = moment(PeselValidator.parse(el.pesel).getBirthday());
					}
				}
			});

			if(form) {
				form.setFieldsValue({
					families: allFields.families
				});
			}
		}
	};

	return (
		relationData
			? <>
				<Alert type="info" message="Profil należy uzupełniać zgodnie z zapisami regulaminu kontrahenta" style={{ marginBottom: 15 }} />
				<Form layout="vertical" onFinish={onSave} onFinishFailed={onFail} form={form} onValuesChange={onValuesChange}>
					<Steps
						progressDot
						current={currentStep}
					>
						{
							steps.map(step => (
								<Step
									key={step?.key ?? step.title}
									title={step.title}
									description={step?.description ?? null}
								/>
							))
						}
					</Steps>

					<StepsContent>
						<Tabs
							size="large"
							forceRender
							renderTabBar={() => null}
							activeKey={activeTab}
							defaultActiveKey="general"
						>
							<Tabs.TabPane tab="Dane podstawowe" key="general">
								<Row gutter={[16, 24]} style={{ marginTop: 15 }}>
									<Col sm={24} md={12}>
										<Form.Item
											label="Stan cywilny"
											name="dctmaritial_id"
											rules={[
												{ required: true, message: 'Proszę wybrać stan cywilny' }
											]}
										>
											<Select placeholder="Wybierz...">
												{relationData?.dctmaritials?.filter(el => el.is_active).map(el => (
													<Option key={['dctmaritial_id', el.id]} value={el.id}>
														{el.name}
													</Option>
												))}
											</Select>
										</Form.Item>
									</Col>

									<Col sm={24} md={12}>
										<Form.Item
											label="Data urodzenia"
											name="birth_date"
											rules={[
												{ required: true, message: 'Proszę uzupełnić datę urodzenia' },
												({ getFieldValue }) => ({
													validator(_, value) {
														if(value) {
															if(value.isSameOrAfter(moment(), 'day')) {
																return Promise.reject(new Error('Podana data urodzenia jest nieprawidłowa'));
															}
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<DatePicker placeholder="Wybierz..." style={{ width: '100%' }} />
										</Form.Item>
									</Col>

									{/*
									<Col sm={24} md={12}>
										<Form.Item
											label="Rodzaj dokumentu tożsamości"
											name="identity_type"
											rules={[
												{ required: true, message: 'Proszę wybrać rodzaj dokumentu tożsamości' }
											]}
										>
											<Select>
												<Option value="D">Dowód osobisty</Option>
												<Option value="P">Paszport</Option>
											</Select>
										</Form.Item>
									</Col>

									<Col sm={24} md={12}>
										<Form.Item
											label="Numer dokumentu tożsamości"
											name="identity_number"
											rules={[
												{ required: true, message: 'Proszę uzupełnić numer dokumentu tożsamości' }
											]}
										>
											<Input />
										</Form.Item>
									</Col>
									*/}

									<Col sm={24} md={12}>
										<Form.Item
											label="Numer ewidencyjny"
											name="registration_number"
										>
											<Input disabled />
										</Form.Item>
									</Col>

									<Col sm={24} md={12}>
										<Form.Item
											label="Symbol komórki organizacyjnej"
											name="department_symbol"
										>
											<Input />
										</Form.Item>
									</Col>

									<Col sm={24} md={12}>
										<Form.Item
											label="Ulica i nr domu/mieszkania"
											name="street"
											rules={[
												{ required: true, message: 'Proszę uzupełnić adres' }
											]}
										>
											<Input />
										</Form.Item>
									</Col>

									<Col sm={24} md={5}>
										<Form.Item
											label="Kod pocztowy"
											name="zip"
											rules={[
												{ required: true, message: 'Proszę uzupełnić kod pocztowy' },
												{ pattern: /^\d{2}-\d{3}$/g, max: 6, message: 'Kod pocztowy powinien spełniać odpowiedni format: XX-XXX, gdzie X oznacza cyfrę' }
											]}
										>
											<Input />
										</Form.Item>
									</Col>

									<Col sm={24} md={7}>
										<Form.Item
											label="Miejscowość"
											name="town"
											rules={[
												{ required: true, message: 'Proszę uzupełnić miejscowość' }
											]}
										>
											<Input />
										</Form.Item>
									</Col>

									<Col span={24}>
										<Form.Item
											label="Numer rachunku bankowego"
											name="bank_account"
										>
											<Input />
										</Form.Item>
									</Col>

									<Col sm={24} md={12}>
										<Form.Item
											label="Numer kontaktowy"
											name="phone"
										>
											<Input />
										</Form.Item>
									</Col>

									<Col sm={24} md={12}>
										<Form.Item
											label="Emeryt"
											name="is_pension_declaration"
											valuePropName="checked"
										>
											<Switch checkedChildren="Tak" unCheckedChildren="Nie" />
										</Form.Item>
									</Col>
								</Row>
							</Tabs.TabPane>

							<Tabs.TabPane tab="Rodzina" key="family">
								<Form.List name="families">
									{(fields, { add, remove }) => (
										<>
											{fields.map(({ key, name, fieldKey, ...restField }) => (
												<Card style={{ marginBottom: 15 }} extra={<Button danger size="small" onClick={() => remove(name)}>Usuń pozycję</Button>}>
													<Row gutter={[16, 24]}>
														<Col sm={24} md={12}>
															<Form.Item
																{...restField}
																label="Imię"
																name={[name, 'first_name']}
																fieldKey={[fieldKey, 'first_name']}
																rules={[
																	{ required: true, message: 'Proszę uzupełnić imię członka rodziny' }
																]}
															>
																<Input />
															</Form.Item>
														</Col>

														<Col sm={24} md={12}>
															<Form.Item
																{...restField}
																label="Nazwisko"
																name={[name, 'last_name']}
																fieldKey={[fieldKey, 'last_name']}
																rules={[
																	{ required: true, message: 'Proszę uzupełnić nazwisko członka rodziny' }
																]}
															>
																<Input />
															</Form.Item>
														</Col>

														<Col sm={24} md={12}>
															<Form.Item
																{...restField}
																label="PESEL"
																name={[name, 'pesel']}
																fieldKey={[fieldKey, 'pesel']}
																rules={[
																	(config?.is_pesel_checking ?? true)
																		? ({ getFieldValue, setFieldsValue, getFieldsValue }) => ({
																			validator(_, value) {
																				if(value) {
																					if(PeselValidator.isValid(value)) {
																						return Promise.resolve();
																					} else {
																						return Promise.reject(new Error('Podany numer PESEL jest nieprawidłowy'));
																					}
																				}

																				return Promise.resolve();
																			}
																		})
																		: {
																			pattern: /^\d{11}$/,
																			message: 'Podany numer PESEL jest nieprawidłowy'
																		}
																]}
															>
																<Input />
															</Form.Item>
														</Col>

														<Col sm={24} md={12}>
															<Form.Item
																{...restField}
																label="Data urodzenia"
																name={[name, 'birth_date']}
																fieldKey={[fieldKey, 'birth_date']}
																rules={[
																	{ required: true, message: 'Proszę uzupełnić datę urodzenia członka rodziny' },
																	({ getFieldValue }) => ({
																		validator(_, value) {
																			if(value) {
																				if(value.isSameOrAfter(moment(), 'day')) {
																					return Promise.reject(new Error('Podana data urodzenia jest nieprawidłowa'));
																				}
																			}

																			return Promise.resolve();
																		}
																	}),
																	({ getFieldValue, setFieldsValue, getFieldsValue }) => ({
																		validator(_, value) {
																			if(getFieldValue(['families', name, 'pesel']) && (config?.is_pesel_checking ?? true)) {
																				const pesel = PeselValidator.parse(getFieldValue(['families', name, 'pesel']));

																				if(moment(pesel.getBirthday()).isSame(value)) {
																					return Promise.resolve();
																				} else {
																					return Promise.reject(new Error('Podana data urodzenia jest niezgodna z numerem PESEL'));
																				}
																			}

																			return Promise.resolve();
																		}
																	})
																]}
															>
																<DatePicker defaultPickerValue={moment('2000-01-01')} placeholder="RRRR-MM-DD" style={{ width: '100%' }} />
															</Form.Item>
														</Col>

														<Col sm={24} md={12}>
															<Form.Item
																{...restField}
																label="Rodzaj pokrewieństwa"
																name={[name, 'dctrelationship_id']}
																fieldKey={[fieldKey, 'dctrelationship_id']}
																rules={[
																	{ required: true, message: 'Proszę wybrać rodzaj pokrewieństwa członka rodziny' }
																]}
															>
																<Select>
																	{relationData?.dctrelationships?.filter(el => el.is_active).map(el => (
																		<Option key={['dctrelationship_id', el.id]} value={el.id}>
																			{el.name}
																		</Option>
																	))}
																</Select>
															</Form.Item>
														</Col>

														<Col sm={24} md={12}>
															<Form.Item
																{...restField}
																label="Obecny stopień edukacji (dot. tylko dzieci)"
																name={[name, 'dcteducation_id']}
																fieldKey={[fieldKey, 'dcteducation_id']}
															>
																<Select allowClear>
																	{relationData?.dcteducations?.filter(el => el.is_active).map(el => (
																		<Option key={['dcteducation_id', el.id]} value={el.id}>
																			{el.name}
																		</Option>
																	))}
																</Select>
															</Form.Item>
														</Col>

														<Col sm={24} md={12}>
															<Form.Item
																{...restField}
																label="Obecny stopień niepełnosprawności (dot. tylko dzieci)"
																name={[name, 'dctdisability_id']}
																fieldKey={[fieldKey, 'dctdisability_id']}
															>
																<Select allowClear>
																	{relationData?.dctdisabilities?.filter(el => el.is_active).map(el => (
																		<Option key={['dctdisability_id', el.id]} value={el.id}>
																			{el.name}
																		</Option>
																	))}
																</Select>
															</Form.Item>
														</Col>

														<Col sm={24} md={12}>
															<Form.Item
																{...restField}
																label="Uprawniony do pobierania świadczeń?"
																name={[name, 'is_authorized']}
																fieldKey={[fieldKey, 'is_authorized']}
																valuePropName="checked"
															>
																<ErrorSwitch checkedChildren="Tak" unCheckedChildren="Nie" />
															</Form.Item>
														</Col>

														<Col sm={24} md={12}>
															<Form.Item
																{...restField}
																label="Czy rodzic zmarł (dot. tylko dzieci)"
																name={[name, 'is_parent_died']}
																fieldKey={[fieldKey, 'is_parent_died']}
																valuePropName="checked"
															>
																<Switch checkedChildren="Tak" unCheckedChildren="Nie" />
															</Form.Item>
														</Col>
													</Row>
												</Card>
											))}

											<Form.Item>
												<Button type="dashed" onClick={() => add()} block>
													Dodaj nową pozycję
												</Button>
											</Form.Item>
										</>
									)}
								</Form.List>
							</Tabs.TabPane>

							<Tabs.TabPane tab="Dochody" key="incomes">
								<Row gutter={[16,24]}>
									<Col sm={24} md={12}>
										<Form.Item
											label="Twoje dochody"
											name="client_income"
										>
											<InputNumber precision={2} decimalSeparator="," formatter={(value) => value.replace(/,/, '.')} style={{ width: '100%' }} />
										</Form.Item>
									</Col>

									<Col sm={24} md={12}>
										<Form.Item
											label="Dochody współmałżonka"
											name="spouse_income"
										>
											<InputNumber precision={2} decimalSeparator="," formatter={(value) => value.replace(/,/, '.')} style={{ width: '100%' }} />
										</Form.Item>
									</Col>

									<Col span={24}>
										<Form.List name="incomeothers">
											{(fields, { add, remove }) => (
												<>
													{fields.map(({ key, name, fieldKey, ...restField }) => (
														<Card style={{ marginBottom: 15 }} extra={<Button danger size="small" onClick={() => remove(name)}>Usuń pozycję</Button>}>
															<Row gutter={[16, 24]}>
																<Col sm={24} md={12}>
																	<Form.Item
																		{...restField}
																		label="Rodzaj dochodu"
																		name={[name, 'dctincometype_id']}
																		fieldKey={[fieldKey, 'dctincometype_id']}
																		rules={[
																			{ required: true, message: 'Proszę wybrać rodzaj dochodu' }
																		]}
																	>
																		<Select>
																			{relationData?.dctincometypes?.filter(el => el.is_active).map(el => (
																				<Option key={['dctincometype_id', el.id]} value={el.id}>
																					{el.name}
																				</Option>
																			))}
																		</Select>
																	</Form.Item>
																</Col>

																<Col sm={24} md={12}>
																	<Form.Item
																		{...restField}
																		label="Wartość dochodu"
																		name={[name, 'quote']}
																		fieldKey={[fieldKey, 'quote']}
																		rules={[
																			{ required: true, message: 'Proszę uzupełnić wartość dochodu' }
																		]}
																	>
																		<InputNumber decimalSeparator="," formatter={(value) => value.replace(/,/, '.')} precision={2} style={{ width: '100%' }} />
																	</Form.Item>
																</Col>
															</Row>
														</Card>
													))}

													<Form.Item>
														<Button type="dashed" onClick={() => add()} block>
															Dodaj nową pozycję
														</Button>
													</Form.Item>
												</>
											)}
										</Form.List>
									</Col>
								</Row>
							</Tabs.TabPane>

							<Tabs.TabPane tab="Załączniki" key="attachments">
								<Row gutter={[16, 24]}>
									<Col sm={24} md={12}>
										<Form.Item
											label="Wnioski"
											name="attachments"
											getValueFromEvent={(e) => {
												if(Array.isArray(e)) {
													return e;
												}

												return e && e.fileList;
											}}
										>
											<DraggerUpload
												fileType={
													(relationData && relationData.hasOwnProperty('dctfiletypes'))
														? relationData.dctfiletypes.find(el => el.name === 'Wnioski') ?? null
														: null
												}
												updateAttachments={setAttachments}
												data={attachments}
											>
												<p className="ant-upload-drag-icon">
													<InboxOutlined />
												</p>

												<p className="ant-upload-text">Kliknij lub przeciągnij plik do tego obszaru, aby dodać załącznik</p>
											</DraggerUpload>
										</Form.Item>
									</Col>

									<Col sm={24} md={12}>
										<Form.Item
											label="Zaświadczenia"
											name="attachments2"
											getValueFromEvent={(e) => {
												if(Array.isArray(e)) {
													return e;
												}

												return e && e.fileList;
											}}
										>
											<DraggerUpload
												fileType={
													(relationData && relationData.hasOwnProperty('dctfiletypes'))
														? relationData.dctfiletypes.find(el => el.name === 'Zaświadczenia') ?? null
														: null
												}
												updateAttachments={setAttachments2}
												data={attachments2}
											>
												<p className="ant-upload-drag-icon">
													<InboxOutlined />
												</p>

												<p className="ant-upload-text">Kliknij lub przeciągnij plik do tego obszaru, aby dodać załącznik</p>
											</DraggerUpload>
										</Form.Item>
									</Col>
								</Row>
							</Tabs.TabPane>

							<Tabs.TabPane tab="Oświadczenia" key="agreements">
								<Row gutter={[16, 24]}>
									<Col sm={24} md={24}>
										<Form.Item
											initialValue={false}
											name="agreement_1"
											valuePropName="checked"
											rules={[
												{
													validator: (rule, value, callback) => {
														if(value) {
															return callback();
														} else {
															return callback('Zaznaczenie oświadczenia jest obowiązkowe');
														}
													}
												}
											]}>
											<Checkbox>
												<div className="ant-form-item-label">
													<label className="ant-form-item-required">
														<span>
															Oświadczam pod odpowiedzialnością karną, że wyżej podane dane są prawdziwe, wymienione dochody w mojej rodzinie są jedynymi jakie posiadam i znane mi są przepisy Regulaminu ZFŚS o odpowiedzialności za złożenie nieprawdziwych danych w tym zakresie.
														</span>
													</label>
												</div>
											</Checkbox>
										</Form.Item>
									</Col>

									<Col sm={24} md={24}>
										<Form.Item
											initialValue={false}
											name="agreement_2"
											valuePropName="checked"
											rules={[
												{
													validator: (rule, value, callback) => {
														if(value) {
															return callback();
														} else {
															return callback('Zaznaczenie oświadczenia jest obowiązkowe');
														}
													}
												}
											]}>
											<Checkbox>
												<div className="ant-form-item-label">
													<label className="ant-form-item-required">
														<span>
															W przypadku zmiany mojej sytuacji rodzinnej / finansowej mającej wpływ na średni dochód w rodzinie i wysokość dofinansowania lub refundacji wg zasad Regulaminu, zobowiązuję się do niezwłocznej aktualizacji danych ujętych w niniejszej informacji.
														</span>
													</label>
												</div>
											</Checkbox>
										</Form.Item>
									</Col>
								</Row>
							</Tabs.TabPane>
						</Tabs>
					</StepsContent>

					<FlexBox justifyEnd>
						<Button disabled={!hasPrevStep()} onClick={() => prevStep()} style={{ margin: '0 5px' }}>
							Wróć
						</Button>
						<Button type="primary" disabled={!hasNextStep()} onClick={() => nextStep()} style={{ margin: '0 5px' }}>
							Dalej
						</Button>
						{
							!hasNextStep()
								? <Button className="zfss-create-profile-btn2" type="primary" htmlType="submit" disabled={!relationData} loading={saving} style={{ marginLeft: 5 }}>Utwórz profil</Button>
								: null
						}
					</FlexBox>
				</Form>
			</>
			: <Skeleton active />
	);
};

export default Dashboard_Profile_Create;
