import React from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

import { Button, Result } from 'antd';

const ResultContainer = styled.div`
	display: grid;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100wh;
`;

const NotFound = ({ ...props }) => {
	const history = useHistory();

	return (
		<ResultContainer>
			<Result
				status="404"
				title="404"
				subTitle="Przepraszamy, strona której szukasz nie istnieje."
				extra={<Button type="primary" onClick={() => {
					history.push('/');
				}}>Wróć do strony głównej</Button>}
			/>
		</ResultContainer>
	);
};

export default NotFound;
