import React from 'react';
import styled from 'styled-components';
import { useHistory, useParams, Link } from 'react-router-dom';

import { useAuth } from '../../../contexts/User';

import Subheader from '../../../components/Subheader';
import CardTabbed from '../../../components/CardTabbed';
import DraggerUpload from '../../../components/DraggerUpload';

import { InboxOutlined } from '@ant-design/icons';
import { Row, Col, Card, Form, Button, Input, InputNumber, Select, DatePicker, Switch, message, Space, Checkbox } from 'antd';

import { APIBackend as API } from '../../../api';

import moment from 'moment';

import createPersistedState from '../../../hooks/usePersistedState';
import { checkRelationTablesService as checkRelationTables } from '../../../helpers/ApiHelper';

const { Option } = Select;

const useUserStageState = createPersistedState('userStage');
const useContractorState = createPersistedState('contractor');

const FlexSpace = styled(Space)`
	display: flex;
	align-items: baseline;
	margin-bottom: 8px;

	& .ant-space-item {
		flex: 1;

		&:last-child {
			flex: 0;
		}
	}
`;

const AntTable = styled.div`
	& .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
		display: none;
	}
`;

const Dashboard_Trips = ({ ...props }) => {
	const auth = useAuth();
	const history = useHistory();

	const [form] = Form.useForm();

	const { id } = useParams();

	const [data, setData] = React.useState(null);
	const [relationData, setRelationData] = React.useState(null);

	const [profileData, setProfileData] = React.useState({});
	const [loading, setLoading] = React.useState(true);
	const [saving, setSaving] = React.useState(false);
	const [loadingUser, setLoadingUser] = React.useState(true);
	const [currentTab, setCurrentTab] = React.useState('general');
	const [changes, setChanges] = React.useState({});
	const [recordData, setRecordData] = React.useState({});

	const [userStage, setUserStage] = useUserStageState(0);
	const [contractor, setContractor] = useContractorState(null);

	const relationTypes = ['dcttriptypes', 'apptripmembers', 'tripprices', 'dctpaymethods', 'offtrips', 'dctfiletypes'];

	const [attachments, setAttachments] = React.useState({
		list: [],
		add: [],
		del: []
	});

	React.useEffect(() => {
		setRecordData(Object.assign({}, data, changes ?? {}));
	}, [data, setData, changes, setChanges]);

	React.useEffect(() => {
		API.getActiveProfile(auth.user.id, contractor).then(data => {
			setProfileData(data);
		}).catch(err => {

		}).finally(() => {
			setLoading(false);
		});

		API.trips(id).then(data => {
			if(data?.apptripmembers) {
				const member = data.apptripmembers.find(el => el.is_member);
				if(member) {
					data.client_id = member?.id;
					data.client_is_member = 1;
					data.client_tripprice_id = member.tripprice_id;
				}

				data.apptripmembers = data.apptripmembers.filter(el => !el.is_member);
			}
			setData(data);
			const filterObj = checkRelationTables(relationTypes, data);
			API.relations(relationTypes, null, filterObj).then(reldata => {
				setRelationData(reldata);
			}).catch(err => {
				console.log(err);
			});
		}).catch(err => {
			console.log(err);
		});
	}, []);

	React.useEffect(() => {
		setLoadingUser(false);
	}, [auth, auth.user]);

	React.useEffect(() => {
		if(data && relationData) {
			const values = {...attachments};

			const fileType = (relationData && relationData.hasOwnProperty('dctfiletypes'))
				? relationData.dctfiletypes.find(el => el.name === 'Ogólny') ?? null
				: null;

			if(data?.attachments) {
				data.attachments.forEach((el, idx) => {
					if(el.dctfiletype_id === fileType?.id) {
						if(values?.list && !values.list?.find?.(_el => _el.uid === el.id)) {
							values.list.push({
								uid: el.id,
								name: el.file_name,
								file: el,
								category: el.dctfiletype_id
							});
						}
					}
				});

				setAttachments(values);
			}
		}
	}, [data, relationData]);

	const onSave = (values) => {
		setSaving(true);

		Object.keys(values).forEach(el => {
			if(['application_date', 'from', 'to', 'payment_date'].includes(el)) {
				values[el] = values[el] ? values[el].format('YYYY-MM-DD') : null;
			} else if(['attachments'].includes(el)) {
				if(attachments.hasOwnProperty('add')) {
					attachments.add.forEach(el => {
						if(el) {
							values[`files[${el.category}/${el.name}]`] = el.file;
						}
					});
				}

				if(attachments.hasOwnProperty('del')) {
					attachments.del.forEach(el => {
						if(el) {
							values[`delete[${el.category}/${el.name}]`] = el.uid;
						}
					});
				}

				delete values[el];
			} else if(['apptripmembers'].includes(el)) {
				const newValues = values[el] ?? [];

				if(values?.client_is_member) {
					let member = {
						is_member: 1,
						tripprice_id: values?.client_tripprice_id ?? null
					};

					if(values?.client_id) {
						member.id = values.client_id;
					}

					newValues.push(member);
				}

				values[el] = JSON.stringify(newValues);
			}
		});

		const _data = new FormData();

		for(let k in values) {
			let val = values[k];

			if(val !== null && val !== undefined && val !== '') {
				_data.append(k, val);
			}
		}

		API.updateTrips(id, _data).then(r => {
			message.success('Wniosek został zapiasny');
			history.push(`/trips/${r.id}`);
		}).catch(err => {
			message.error(err.getMessage());
			console.log(err);
		}).finally(() => {
			setSaving(false);
		});
	};

	const getRelation = (name) => {
		if(relationData && relationData.hasOwnProperty(name)) {
			if(relationData[name]) {
				return relationData[name].filter(el => el?.is_active ?? true);
			}
		}

		return [];
	};

	const onChange = (field, allFields) => {
		setChanges(allFields);

		const keys = Object.keys(field);

		if(!keys.length) return;

		const value = field[keys[0]];
	};

	const tabs = [
		{ key: 'general', tab: 'Dane podstawowe' },
		{ key: 'members', tab: 'Uczestnicy' },
		{ key: 'attachments', tab: 'Załączniki' }
	];

	const tabContent = {
		general: <>
			<Row gutter={[16, 24]}>
				<Col sm={24} md={12}>
					<Form.Item
						label="Wnioskujący"
						name="profile_id"
						initialValue={profileData.id}
					>
						<Select disabled>
							<Option value={profileData.id}>{auth.user.full_name}</Option>
						</Select>
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Data złożenia wniosku"
						name="application_date"
						initialValue={moment()}
					>
						<DatePicker style={{ width: '100%' }} disabled />
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Rodzaj wyjazdu"
						name="dcttriptype_id"
						rules={[
							{ required: true, message: 'Proszę uzupełnić rodzaj wyjazdu' }
						]}
						initialValue={data?.dcttriptype_id}
					>
						<Select
							showSearch
							optionFilterProp="children"
						>
							{getRelation('dcttriptypes').map(el => {
								return (
									<Option
										key={['dcttriptype', el.id]}
										value={el.id}
									>
										{el.name}
									</Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Oferta"
						name="offtrip_id"
						initialValue={data?.offtrip_id}
					>
						<Select
							allowClear
							showSearch
							optionFilterProp="children"
						>
							{getRelation('offtrips').filter(el => el?.dcttriptype_id === recordData?.dcttriptype_id).map(el => {
								return (
									<Option
										key={['offtrip', el.id]}
										value={el.id}
									>
										{el.resort} ({el.from} - {el.to})
									</Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Termin od"
						name="from"
						dependencies={['to']}
						rules={[
							{
								required: true,
								message: 'Proszę uzupełnić terminy wyjazdu'
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if(value.diff(getFieldValue('to'), 'days') < 0) {
										return Promise.resolve();
									}
									return Promise.reject('Data początku terminu musi być wcześniejszą datą, niż koniec terminu');
								},
							})
						]}
						initialValue={data?.from ? moment(data.from) : null}
					>
						<DatePicker style={{ width: '100%' }} />
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Termin do"
						name="to"
						dependencies={['from']}
						rules={[
							{
								required: true,
								message: 'Proszę uzupełnić terminy wyjazdu'
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if(value.diff(getFieldValue('from'), 'days') > 0) {
										return Promise.resolve();
									}
									return Promise.reject('Data końca terminu musi być późniejszą datą, niż początek terminu');
								},
							})
						]}
						initialValue={data?.to ? moment(data.to) : null}
					>
						<DatePicker style={{ width: '100%' }} />
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Kwota podlegająca dofinansowaniu"
						name="subsidy_value"
						initialValue={data?.subsidy_value}
					>
						<InputNumber precision={2} decimalSeparator="," formatter={(value) => value.replace(/,/, '.')} style={{ width: '100%' }} />
					</Form.Item>
				</Col>
			</Row>
		</>,
		members: <>
			<Row gutter={[16, 24]}>
				<Col span={24}>
					<AntTable className="ant-table">
						<div className="ant-table-container">
							<div className="ant-table-content">
								<table style={{ tableLayout: 'auto' }}>
									<thead className="ant-table-thead">
										<tr className="ant-table-row">
											<th className="ant-table-cell">
												<strong>Uczestnik</strong>
											</th>
											<th className="ant-table-cell">
												<strong>Pozycja cennika</strong>
											</th>
											<th className="ant-table-cell">
												<strong>Korzysta ze świadczenia?</strong>
											</th>
										</tr>
									</thead>
									<tbody className="ant-table-tbody">
										<tr className="ant-table-row" valign="baseline">
											<td className="ant-table-cell">
												{auth.user?.full_name ?? '?'}
											</td>
											<td className="ant-table-cell">
												<Form.Item
													name="client_tripprice_id"
													initialValue={data?.client_tripprice_id}
												>
													<Select placeholder="Wybierz pozycję cennika..." disabled={!recordData?.offtrip_id}>
														{getRelation('tripprices').filter(el => el?.offtrip_id === recordData?.offtrip_id).map(el => {
															return (
																<Option
																	key={['tripprice', el.id]}
																	value={el.id}
																>
																	{el.service_name} ({el.dctunit_name}, {el.price} PLN)
																</Option>
															);
														})}
													</Select>
												</Form.Item>
											</td>
											<td className="ant-table-cell">
												<Form.Item
													name="client_is_member"
													valuePropName="checked"
													initialValue={data?.client_is_member}
												>
													<Checkbox />
												</Form.Item>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</AntTable>
				</Col>
				<Col span={24}>
					{
						changes?.apptripmembers?.length
							? <FlexSpace>
								<strong>Uczestnik</strong>
								<strong>Pozycja cennika</strong>
								<span style={{ display: 'block', width: 64 }}></span>
							</FlexSpace>
							: null
					}
					<Form.List name="apptripmembers" initialValue={data?.apptripmembers ? data?.apptripmembers.filter(el => !el.is_member) : []}>
						{(fields, { add, remove }) => (
							<>
								{fields.map(({ key, name, fieldKey, ...restField }) => (
									<FlexSpace key={key}>
										<Form.Item
											{...restField}
											name={[name, 'family_id']}
											fieldKey={[fieldKey, 'family_id']}
											rules={[
												{
													required: true,
													message: '[Uczestnicy] Proszę wybrać uczestnika'
												}
											]}
										>
											<Select placeholder="Wybierz uczestnika...">
												{
													profileData && profileData.hasOwnProperty('families') && profileData.families.map(el => (
														<Option
															key={el.id}
															value={el.id}
														>
															{el.first_name} {el.last_name} ({el.birth_date})
														</Option>
													))
												}
											</Select>
										</Form.Item>

										<Form.Item
											{...restField}
											name={[name, 'tripprice_id']}
											fieldKey={[fieldKey, 'tripprice_id']}
										>
											<Select placeholder="Wybierz pozycję cennika..." disabled={!recordData?.offtrip_id}>
												{getRelation('tripprices').filter(el => el?.offtrip_id === recordData?.offtrip_id).map(el => {
													return (
														<Option
															key={['tripprice', el.id]}
															value={el.id}
														>
															{el.service_name} ({el.dctunit_name}, {el.price} PLN)
														</Option>
													);
												})}
											</Select>
										</Form.Item>

										<Button danger block onClick={() => remove(name)}>
											Usuń
										</Button>
									</FlexSpace>
								))}

								<Form.Item>
									<Button type="dashed" onClick={() => add()} block>
										Dodaj uczestnika
									</Button>
								</Form.Item>
							</>
						)}
					</Form.List>
				</Col>
			</Row>
		</>,
		attachments: <>
			<Row gutter={[16, 24]}>
				<Col sm={24} md={12}>
					<Form.Item
						label="Ogólne"
						name="attachments"
						getValueFromEvent={(e) => {
							if(Array.isArray(e)) {
								return e;
							}

							return e && e.fileList;
						}}
					>
						<DraggerUpload
							fileType={
								(relationData && relationData.hasOwnProperty('dctfiletypes'))
									? relationData.dctfiletypes.find(el => el.name === 'Ogólny') ?? null
									: null
							}
							updateAttachments={setAttachments}
							data={attachments}
						>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>

							<p className="ant-upload-text">Kliknij lub przeciągnij plik do tego obszaru, aby dodać załącznik</p>
						</DraggerUpload>
					</Form.Item>
				</Col>
			</Row>
		</>,
	};

	return (
		<>
			{
				(data === null || relationData === null)
					? <Card loading={true} />
					: <Form form={form} layout="vertical" onFinish={onSave} onValuesChange={onChange} initialValues={{
						from: data?.from ? moment(data.from) : null,
						to: data?.to ? moment(data.to) : null,
						working_days: data?.working_days ?? 0,
						subsidy_total: data?.subsidy_total ?? null,
						payment_date: data?.payment_date ? moment(data.payment_date) : null,
						attachments: data?.attachments ?? []
					}}>
						<Subheader title={`Wyjazdy dofinansowanie`} extra={<Subheader.Actions>
							<Link to="/" style={{ marginRight: 15 }}>Wróć do pulpitu</Link>
							<Button type="primary" htmlType="submit" disabled={loading} loading={saving}>Zapisz wniosek</Button>
						</Subheader.Actions>} />

						<CardTabbed loading={loading || loadingUser} tabList={tabs} tabContent={tabContent} activeTabKey={currentTab} onTabChange={key => { setCurrentTab(key); }} />
					</Form>
			}
		</>
	);
};

export default Dashboard_Trips;
