import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { UserProvider } from './contexts/User';
import { ConfigProvider } from 'antd';

import Main from './Main';

import 'moment/locale/pl';
import locale from 'antd/lib/locale/pl_PL';

const App = () => {
	return (
		<div className="app">
			<Router>
				<ConfigProvider locale={locale}>
					<UserProvider>
						<Main />
					</UserProvider>
				</ConfigProvider>
			</Router>
		</div>
	)
}

export default App;
