import { API } from '../';

import ErrorHandler from '../../../../helpers/ErrorHandler';

const logoutIgnore = ['WRONG-EMAIL-PASSWORD', 'LOGIN-FAILURE', 'ACCOUNT-IS-LOCKED', 'PERMISSION-FAILURE'];

const onResponse = (response) => {
	return response;
};

const onError = (error) => {
	const err = ErrorHandler.parseError(error);

	// Logout when unauthorized
	if(err.getStatus() === 401 && !logoutIgnore.includes(err.getCode())) {
		API.logout().then(() => {}).catch(() => {}).finally(() => {
			localStorage.removeItem('user_id');
			window.location.href = '/';
		});
	}

	// If responseType if Blob then return JSON if any
	if(
		error.request.responseType === 'blob' &&
		error.response &&
		error.response.data &&
		error.response.data instanceof Blob &&
		error.response.data.type &&
		error.response.data.type.toLowerCase().indexOf('json') !== -1
	) {
		return new Promise((resolve, reject) => {
			let reader = new FileReader();
			reader.onload = () => {
				error.response.data = JSON.parse(reader.result);
				resolve(Promise.reject(ErrorHandler.parseError(error)));
			};

			reader.onerror = () => {
				reject(error);
			};

			reader.readAsText(error.response.data);
		});
	}

	return Promise.reject(err);
};

export default [onResponse, onError];
