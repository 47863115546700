import { Modal } from 'antd';

const { confirm } = Modal;

export const onApplyHook = (appType) => {
    return new Promise((resolve, reject) => {
        confirm({
            title: 'Wymagane potwierdzenie',
            content: 'Oświadczam pod odpowiedzialnością karną, że wcześniej podany średni dochód w rodzinie nie uległ zmianie, wyżej podane dane są prawdziwe i znane mi są przepisy Regulaminu ZFŚS o odpowiedzialności za złożenie nieprawdziwych danych w tym zakresie. Jeśli nastąpiła jakakolwiek zmiana edytuj swój profil.',
            onOk() {
                resolve(true);
            },
            onCancel() {
                resolve(false);
            },
            okText: 'Potwierdzam',
            cancelText: 'Anuluj',
        });
    });
};
