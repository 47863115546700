import React from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';

import { useAuth } from '../../../contexts/User';

import Subheader from '../../../components/Subheader';
import CardTabbed from '../../../components/CardTabbed';
import DraggerUpload from '../../../components/DraggerUpload';

import { InboxOutlined } from '@ant-design/icons';
import { Row, Col, Card, Form, Button, Input, InputNumber, Select, DatePicker, Switch, message } from 'antd';

import { APIBackend as API } from '../../../api';

import moment from 'moment';

import createPersistedState from '../../../hooks/usePersistedState';

const { Option } = Select;

const useUserStageState = createPersistedState('userStage');
const useContractorState = createPersistedState('contractor');

const Dashboard_Recreation = ({ ...props }) => {
	const auth = useAuth();
	const history = useHistory();

	const [form] = Form.useForm();

	const { id } = useParams();

	const [data, setData] = React.useState(null);
	const [relationData, setRelationData] = React.useState(null);

	const [profileData, setProfileData] = React.useState({});
	const [loading, setLoading] = React.useState(true);
	const [saving, setSaving] = React.useState(false);
	const [loadingUser, setLoadingUser] = React.useState(true);
	const [currentTab, setCurrentTab] = React.useState('general');

	const [userStage, setUserStage] = useUserStageState(0);
	const [contractor, setContractor] = useContractorState(null);

	const [attachments, setAttachments] = React.useState({
		list: [],
		add: [],
		del: []
	});

	React.useEffect(() => {
		API.getActiveProfile(auth.user.id, contractor).then(data => {
			setProfileData(data);
		}).catch(err => {

		}).finally(() => {
			setLoading(false);
		});

		API.rehabs(id).then(data => {
			setData(data);
		}).catch(err => {
			console.log(err);
		});

		API.relations(['dctconditions', 'dctpaymethods', 'dctfiletypes']).then(data => {
			setRelationData(data);
		}).catch(err => {
			console.log(err);
		});
	}, []);

	React.useEffect(() => {
		setLoadingUser(false);
	}, [auth, auth.user]);

	React.useEffect(() => {
		if(data && relationData) {
			const values = {...attachments};

			const fileType = (relationData && relationData.hasOwnProperty('dctfiletypes'))
				? relationData.dctfiletypes.find(el => el.name === 'Ogólny') ?? null
				: null;

			if(data?.attachments) {
				data.attachments.forEach((el, idx) => {
					if(el.dctfiletype_id === fileType?.id) {
						if(values?.list && !values.list?.find?.(_el => _el.uid === el.id)) {
							values.list.push({
								uid: el.id,
								name: el.file_name,
								file: el,
								category: el.dctfiletype_id
							});
						}
					}
				});

				setAttachments(values);
			}
		}
	}, [data, relationData]);

	const onSave = (values) => {
		setSaving(true);

		Object.keys(values).forEach(el => {
			if(['application_date', 'from', 'to', 'payment_date'].includes(el)) {
				values[el] = values[el] ? values[el].format('YYYY-MM-DD') : null;
			} else if(['attachments'].includes(el)) {
				if(attachments.hasOwnProperty('add')) {
					attachments.add.forEach(el => {
						if(el) {
							values[`files[${el.category}/${el.name}]`] = el.file;
						}
					});
				}

				if(attachments.hasOwnProperty('del')) {
					attachments.del.forEach(el => {
						if(el) {
							values[`delete[${el.category}/${el.name}]`] = el.uid;
						}
					});
				}

				delete values[el];
			}
		});

		const _data = new FormData();

		for(let k in values) {
			let val = values[k];

			if(val !== null && val !== undefined && val !== '') {
				_data.append(k, val);
			}
		}

		API.updateRehabs(id, _data).then(r => {
			message.success('Wniosek został zapiasny');
			history.push(`/rehabs/${r.id}`);
		}).catch(err => {
			message.error(err.getMessage());
			console.log(err);
		}).finally(() => {
			setSaving(false);
		});
	};

	const getRelation = (name) => {
		if(relationData && relationData.hasOwnProperty(name)) {
			if(relationData[name]) {
				return relationData[name].filter(el => el?.is_active);
			}
		}

		return [];
	};

	const onChange = (field, allFields) => {
		const keys = Object.keys(field);

		if(!keys.length) return;

		const value = field[keys[0]];
	};

	const tabs = [
		{ key: 'general', tab: 'Dane podstawowe' },
		{ key: 'attachments', tab: 'Załączniki' }
	];

	const tabContent = {
		general: <>
			<Row gutter={[16, 24]}>
				<Col sm={24} md={12}>
					<Form.Item
						label="Wnioskujący"
						name="profile_id"
						initialValue={profileData.id}
					>
						<Select disabled>
							<Option value={profileData.id}>{auth.user.full_name}</Option>
						</Select>
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Data złożenia wniosku"
						name="application_date"
						initialValue={moment()}
					>
						<DatePicker style={{ width: '100%' }} disabled />
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Nazwa obiektu"
						name="object_name"
						rules={[
							{ required: true, message: 'Proszę uzupełnić nazwę obiektu' }
						]}
						initialValue={data?.object_name}
					>
						<Input />
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Kwota skierowania"
						name="real_price"
						rules={[
							{ required: true, message: 'Proszę uzupełnić kwotę skierowania' }
						]}
						initialValue={data?.real_price}
					>
						<InputNumber style={{ width: '100%' }} decimalSeparator="," formatter={(value) => value.replace(/,/, '.')} precision={2} />
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Warunek szczególny"
						name="dctcondition_id"
						rules={[
							{ required: true, message: 'Proszę uzupełnić warunek szczególny' }
						]}
						initialValue={data?.dctcondition_id}
					>
						<Select
							showSearch
							optionFilterProp="children"
						>
							{getRelation('dctconditions').map(el => {
								return (
									<Option
										key={['dctcondition', el.id]}
										value={el.id}
									>
										{el.name}
									</Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Kwota podlegająca dofinansowaniu"
						name="subsidy_value"
						initialValue={data?.subsidy_value}
					>
						<InputNumber precision={2} decimalSeparator="," formatter={(value) => value.replace(/,/, '.')} style={{ width: '100%' }} />
					</Form.Item>
				</Col>
			</Row>
		</>,
		attachments: <>
			<Row gutter={[16, 24]}>
				<Col sm={24} md={12}>
					<Form.Item
						label="Ogólne"
						name="attachments"
						getValueFromEvent={(e) => {
							if(Array.isArray(e)) {
								return e;
							}

							return e && e.fileList;
						}}
					>
						<DraggerUpload
							fileType={
								(relationData && relationData.hasOwnProperty('dctfiletypes'))
									? relationData.dctfiletypes.find(el => el.name === 'Ogólny') ?? null
									: null
							}
							updateAttachments={setAttachments}
							data={attachments}
						>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>

							<p className="ant-upload-text">Kliknij lub przeciągnij plik do tego obszaru, aby dodać załącznik</p>
						</DraggerUpload>
					</Form.Item>
				</Col>
			</Row>
		</>,
	};

	return (
		<>
			{
				(data === null || relationData === null)
					? <Card loading={true} />
					: <Form form={form} layout="vertical" onFinish={onSave} onValuesChange={onChange} initialValues={{
						from: data?.from ? moment(data.from) : null,
						to: data?.to ? moment(data.to) : null,
						working_days: data?.working_days ?? 0,
						subsidy_total: data?.subsidy_total ?? null,
						payment_date: data?.payment_date ? moment(data.payment_date) : null,
						attachments: data?.attachments ?? []
					}}>
						<Subheader title={`Zajęcia rekreacyjne`} extra={<Subheader.Actions>
							<Link to="/" style={{ marginRight: 15 }}>Wróć do pulpitu</Link>
							<Button type="primary" htmlType="submit" disabled={loading} loading={saving}>Zapisz wniosek</Button>
						</Subheader.Actions>} />

						<CardTabbed loading={loading || loadingUser} tabList={tabs} tabContent={tabContent} activeTabKey={currentTab} onTabChange={key => { setCurrentTab(key); }} />
					</Form>
			}
		</>
	);
};

export default Dashboard_Recreation;
