import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useAuth } from '../../../contexts/User';

import Subheader from '../../../components/Subheader';

import { Row, Col, Card, Select, Button, message } from 'antd';

import { APIBackend as API } from '../../../api';

import createPersistedState from '../../../hooks/usePersistedState';

import objectHash from 'object-hash';

const useUserStageState = createPersistedState('userStage');
const useContractorState = createPersistedState('contractor');
const useBranchState = createPersistedState('branch');

const FormItem = styled.div`
	display: flex;
	flex-direction: column;
`;

const FormItemLabel = styled.label`
	font-weight: 600;
`;

const FormItemValue = styled.span`

`;

const Dashboard_Contractor = ({ ...props }) => {
	const auth = useAuth();
	const history = useHistory();

	const [userStage, setUserStage] = useUserStageState(0);
	const [contractor, setContractor] = useContractorState(null);
	const [branch, setBranch] = useBranchState(null);

	const [branches, setBranches] = React.useState([]);

	const options = (auth?.user?.contractors && Array.isArray(auth.user.contractors))
					? auth.user.contractors.map(el => {
						return <Select.Option key={['contractor', el.id]} value={el.id}>
							{el.name} [{el.symbol}]
						</Select.Option>
					})
					: [];

	const updateBranches = (id) => {
		if(auth?.user?.contractors && Array.isArray(auth.user.contractors)) {
			const contr = auth.user.contractors.find(el => el.id === contractor);

			if(contr) {
				setBranch(contr.branches.length === 1 && contr.branches[0].id);
				return setBranches(contr.branches);
			}
		}

		return setBranches([]);
	};

	React.useEffect(() => {
		updateBranches(contractor);
		auth.fetchActiveProfile();
	}, [contractor, setContractor]);

	const onContractorChange = (value) => {
		setContractor(value);
	};

	const onBranchChange = (value) => {
		setBranch(value);
	};

	return (
		<>
			<Subheader title="Wybierz kontrahenta" extra={<Subheader.Actions>
				<Button type="primary" onClick={(e) => {
					if(contractor && branch) {
						setUserStage(1);
						history.push('/');
					} else {
						message.error('Proszę wybrać kontrahenta i/lub oddział');
					}
				}}>Dalej</Button>
			</Subheader.Actions>} />

			<Card loading={auth?.user?.contractors ? false : true}>
				<Row gutter={[16,16]}>
					<Col span={24}>
						<FormItem>
							<FormItemLabel>Kontrahent</FormItemLabel>
							<FormItemValue>
								<Select key="contractorSelect" style={{ width: '100%' }} value={contractor} onChange={onContractorChange} placeholder="Wybierz kontrahenta...">
									{options}
								</Select>
							</FormItemValue>
						</FormItem>
					</Col>

					<Col span={24}>
						<FormItem>
							<FormItemLabel>Oddział</FormItemLabel>
							<FormItemValue>
								<Select key="branchSelect" style={{ width: '100%' }} value={branch} onChange={onBranchChange} placeholder="Wybierz oddział...">
									{branches.map(el => (
										<Select.Option value={el.id}>{el.name}</Select.Option>
									))}
								</Select>
							</FormItemValue>
						</FormItem>
					</Col>
				</Row>
			</Card>
		</>
	);
};

export default Dashboard_Contractor;
