import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import moment from 'moment';

const SubheaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 3rem;
`;

const PageTitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const Title = styled.h1`
	font-size: 16px;
	font-weight: 700;
	margin: 0;
	padding: 0;
`;

const Subtitle = styled.span`
	font-size: 13px;
`;

const ExtraWrapper = styled.div``;

const SubheaderActions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

class Subheader extends React.PureComponent {
	static propTypes = {
		title: PropTypes.string.isRequired,
		extra: PropTypes.node
	};

	static defaultProps = {

	};

	render() {
		const {
			title,
			extra
		} = this.props;

		return (
			<SubheaderWrapper>
				<PageTitleWrapper>
					<Title>{title ?? 'Podstrona'}</Title>
					<Subtitle>
						Dzisiaj jest {moment().format('dddd, D MMMM YYYY')}
					</Subtitle>
				</PageTitleWrapper>

				<ExtraWrapper>
					{extra ?? null}
				</ExtraWrapper>
			</SubheaderWrapper>
		);
	}
}

Subheader.Actions = SubheaderActions;

export default Subheader;
