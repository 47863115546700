import React from "react";
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../contexts/User';

function PublicRoute({ component: Component, ...rest }) {
	const auth = useAuth();

	return (
		<Route {...rest} render={props =>
			auth.token ? (
				<Redirect
					to={{
						pathname: '/',
						state: { from: props.location }
					}} />
			) : (
				<Component {...props} />
			)
		} />
	);
}

export default PublicRoute;
