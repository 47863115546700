import { APIInstance } from '../../';
import { API } from '../../api';

export default {
	extras(id) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.get(`/api/appextras/${id}`).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	applyExtras(values) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.post(`/api/appextras`, values).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	updateExtras(id, values) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.post(`/api/appextras/${id}`, values).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	countExtras(id) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.get(`/api/appextras/count/${id}`).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	}
};
