import React from 'react';
import { useHistory, Link } from 'react-router-dom';

import { useAuth } from '../../../contexts/User';

import Subheader from '../../../components/Subheader';
import CardTabbed from '../../../components/CardTabbed';
import DraggerUpload from '../../../components/DraggerUpload';

import { InboxOutlined } from '@ant-design/icons';
import { Row, Col, Card, Form, Button, Input, InputNumber, Select, DatePicker, Switch, message, Alert, Table, Space } from 'antd';

import { APIBackend as API } from '../../../api';

import moment from 'moment';

import createPersistedState from '../../../hooks/usePersistedState';

const { Option } = Select;

const useUserStageState = createPersistedState('userStage');
const useContractorState = createPersistedState('contractor');

const columns = [
	{
		title: 'Numer wniosku',
		dataIndex: 'number',
		key: 'number',
		render: (text, record, index) => {
			return text ?? '-';
		}
	},
	{
		title: 'Status',
		dataIndex: 'staapp_name',
		key: 'staapp_name'
	},
	{
		title: 'Data złożenia',
		dataIndex: 'application_date',
		key: 'application_date'
	},
	{
		title: 'Akcje',
		key: 'actions',
		align: 'right',
		render: (text, record) => (
			<Space size="middle">
				<Link to={`/infantpayments/${record.id_table.split('-')[0]}`} className="ant-btn ant-btn-primary ant-btn-sm app-btn-preview">Dodaj wpłatę</Link>

				<Link to={`/infants/${record.id_table.split('-')[0]}`} className="ant-btn ant-btn-primary ant-btn-sm app-btn-preview">Podgląd</Link>
			</Space>
		)
	}
];

const Dashboard_Infants = ({ ...props }) => {
	const auth = useAuth();
	const history = useHistory();

	const [form] = Form.useForm();

	const [data, setData] = React.useState(null);
	const [loading, setLoading] = React.useState(true);
	const [loadingUser, setLoadingUser] = React.useState(true);

	const [userStage, setUserStage] = useUserStageState(0);
	const [contractor, setContractor] = useContractorState(null);

	const getAppList = () => {
		if(auth.hasActiveProfile()) {
			API.listApps(auth?.user?.id, auth?.contractor).then(data => {
				setData(Array.isArray(data) ? data.filter(el => el.table === 'appinfants' && el.staapp_id === 3) : []);
			}).catch(err => {
				console.log(err)
			});
		}
	};

	React.useEffect(() => {

	}, []);

	React.useEffect(() => {
		setLoadingUser(false);
	}, [auth, auth.user]);

	React.useEffect(() => {
		getAppList();
	}, [auth.activeProfile, auth.user]);

	const applyInfants = () => {
		history.push('/infants/create');
	};

	return (
		<>
			<Subheader title={`Przedszkola i żłobki`} extra={<Subheader.Actions>
				<Link to="/" style={{ marginRight: 15 }}>Wróć do pulpitu</Link>
			</Subheader.Actions>} />

			{
				data?.length
					? <Alert message='Masz już zaakceptowane wnioski o przedszkole lub żłobek. Jeśli chcesz dodać wpłatę na kolejny okres, znajdź odpowiednią pozycję na liście i kliknij przycisk "Dodaj wpłatę".' style={{ marginBottom: 24 }} />
					: null
			}

			<Row gutter={[24, 24]}>
				<Col sm={24} lg={12}>
					<Card title="Dodaj wpłatę do istniejącego wniosku" loading={!data}>
						<div style={{ display: 'block', width: '100%' , overflowX: 'auto' }}>
							<Table dataSource={data} columns={columns} rowKey={() => Math.random()} />
						</div>
					</Card>
				</Col>

				<Col sm={24} lg={12}>
					<Card title="Złóż nowy wniosek">
						Jeżeli nie składałeś/łaś jeszcze wniosku lub chcesz złożyć wniosek na kolejne dziecko albo nowy rok kalendarzowy, kliknij przycisk "Złóż nowy wniosek".
						<br/>
						W danym roku kalendarzowym pracujemy na jednym wniosku, jeśli nie ma zmiany placówki.
						<br/>
						<Link style={{ marginTop: 15 }} to={`/infants/create`} className="ant-btn ant-btn-primary">Złóż nowy wniosek</Link>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default Dashboard_Infants;
