import React, { useState } from 'react';
import { useParams, Link } from "react-router-dom";
import styled, { createGlobalStyle } from 'styled-components';

import { Main, Container } from '../../helpers/UI';

import { APIBackend as API } from '../../api';

import { Form, Input, Button, Row, Col, Card, Alert, Skeleton } from 'antd';

import Logo_Sanpro from '../../assets/images/logos/sanpro.png';
import Sygnet_Impel from '../../assets/images/logos/impel_sygnet_color.png';

const LoginPane = styled.div`
	display: table-cell;
	vertical-align: middle;
`;

const LoginPaneTitle = styled.div`
	font-size: 24px;
`;

const CenteredCol = styled(Col)`
	display: table;
	margin: 0 auto;
	height: 100%;
`;

const LoginPaneStyles = createGlobalStyle`
	${LoginPane} .ant-card-head {
		background: #457b9d;
		color: white;
		position: relative;
		text-align: center;
		font-family: 'Poppins', sans-serif;
		padding-bottom: 35px;
	}

	${LoginPane} .ant-card-head:after {
		display: block;
		content: '';
		clear: both;
		position: absolute;
		bottom: -73px;
		left: 50%;
		width: 73px;
		height: 73px;
		border-radius: 100%;
		border: 6px solid #FFF;
		box-shadow: 0 2px 0 -1px #0072a3;
		transform: translate(-50%, -50%);
		background: url(${Sygnet_Impel}) no-repeat center center #FFF;
		background-size: 100% 100%;
	}

	${LoginPane} .ant-card-body {
	    padding-top: 44px;
	}
`;

const ForgottenPassword = (props) => {
	const { token } = useParams();

    const [sent, setSent] = useState(false);
    const [error, setError] = useState(false);
    const [validToken, setValidToken] = useState(null);

	React.useEffect(() => {
		API.checkPasswordToken(token).then((valid) => {
			setValidToken(valid);
		}).catch(err => {
			setValidToken(false);
		});
	}, []);

	const onFormFinished = (values) => {
		API.saveNewPassword(token, values.email, values.password).then(() => {

		}).catch(err => {
			setError(err);
		}).finally(() => {
			setSent(true);
		});
	};

	return (<Main>
		<Container>
			<Row style={{ height: '100vh' }}>
				<CenteredCol sm={20} md={16} lg={12}>
					<LoginPane>
						<LoginPaneStyles />
						<Card title={<>
							<LoginPaneTitle>Ustawianie nowego hasła</LoginPaneTitle>
						</>} bordered={false} className="login-card">
							{ sent ? (
								error ? <Row>
									<Col span={24} style={{ textAlign: 'center', marginTop: 15 }}>
										<Alert message={error.getMessage()} type="error" style={{ marginBottom: 20 }} />

										<Link to="/login">
											Wróć do logowania
										</Link>
									</Col>
								</Row> : <Row>
									<Col span={24} style={{ textAlign: 'center', marginTop: 15 }}>
										<Alert message="Twoje hasło zostało zmienione! Teraz możesz się zalogować podając nowe hasło." type="success" style={{ marginBottom: 20 }} />

										<Link to="/login">
											Wróć do logowania
										</Link>
									</Col>
								</Row>
							) : (validToken === null
									? <Skeleton active paragraph={{ rows: 1 }} />
									: (
										validToken
											? <Form name="reset-form" layout="vertical" onFinish={onFormFinished}>
												<Form.Item
													label="Adres e-mail:"
													name="email"
													rules={[{ required: true, message: 'Proszę wprowadzić adres e-mail' },{ type: 'email', message: 'Proszę wprowadzić poprawny adres e-mail' }]}>
													<Input />
												</Form.Item>

												<Form.Item
													label="Nowe hasło:"
													name="password"
													rules={[{ required: true, message: 'Proszę wprowadzić hasło' }]}>
													<Input.Password />
												</Form.Item>

												<Form.Item
													label="Powtórz hasło:"
													name="confirm"
													rules={[{ required: true, message: 'Proszę powtórzyć wprowadzone hasło' }, ({ getFieldValue }) => ({
														validator(rule, value) {
															if(!value || getFieldValue('password') === value) {
																return Promise.resolve();
															}

															return Promise.reject('Podane hasła nie zgadzają się ze sobą');
														}
													})]}>
													<Input.Password />
												</Form.Item>

												<Row>
													<Col span={24} style={{ textAlign: 'right' }}>
														<Form.Item>
															<Button type="primary" htmlType="submit">
																Ustaw hasło
															</Button>
														</Form.Item>
													</Col>
												</Row>

												<Row>
													<Col span={24}>
														<Link to="/login">
															Wróć do logowania
														</Link>
													</Col>
												</Row>
											</Form>
											: <Row>
												<Col span={24} style={{ textAlign: 'center', marginTop: 15 }}>
													<Alert message="Wybrany adres odzykiwania hasła jest nieprawidłowy lub wygasł. Prześlij ponownie swój adres e-mail, aby otrzymać nowy link do zresetowania hasła." type="error" />
												</Col>
												<Col span={24} style={{ textAlign: 'center', marginTop: 20 }}>
													<Link to="/login">
														Powrót do logowania
													</Link>
												</Col>
											</Row>
										)
									) }
						</Card>
					</LoginPane>
				</CenteredCol>
			</Row>
		</Container>
	</Main>);
};

export default ForgottenPassword;
