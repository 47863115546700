import React from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import styled from 'styled-components';

import { useAuth } from '../../../contexts/User';

import Subheader from '../../../components/Subheader';
import CardTabbed from '../../../components/CardTabbed';
import DraggerUpload from '../../../components/DraggerUpload';

import { InboxOutlined } from '@ant-design/icons';
import { Row, Col, Card, Form, Button, Input, InputNumber, Select, DatePicker, Switch, message } from 'antd';

import { STATUS as STASTATUS, hasStatus, handleCountClick } from '../../../helpers/AppsHelper';

import { backendApi } from '../../../config/paths';
import { APIBackend as API } from '../../../api';

import moment from 'moment';

const { Option } = Select;

const FormItem = styled.div`
	display: flex;
	flex-direction: column;
`;

const FormItemLabel = styled.label`
	font-weight: 600;
`;

const FormItemValue = styled.span`

`;

const FileList = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
`;

const FileListItem = styled.li`

`;

const Dashboard_InfantPayments = ({ ...props }) => {
	const auth = useAuth();
	const history = useHistory();

	const { id } = useParams();

	const [data, setData] = React.useState(null);
	const [relationData, setRelationData] = React.useState(null);
	const [currentTab, setCurrentTab] = React.useState('general');

	const [attachments, setAttachments] = React.useState({
		list: [],
		add: [],
		del: []
	});

	React.useEffect(() => {
		API.infantPayment(id).then(data => {
			setData(data);
		}).catch(err => {
			console.log(err);
		});

		API.relations(['dctfiletypes']).then(data => {
			setRelationData(data);
		}).catch(err => {
			console.log(err);
		});
	}, []);

	const allowedEditIds = [1,4];

	const handleEditClick = (e) => {
		if(data && allowedEditIds.includes(data?.staapp_id)) {
			history.push(`/infantpayments/edit/${id}`);
		}
	};

	const getFileType = (name) => {
		if(relationData && relationData.hasOwnProperty('dctfiletypes')) {
			return relationData.dctfiletypes.filter(el => el.is_active).find(el => el.name === name);
		}

		return null;
	};

	const tabs = [
		{ key: 'general', tab: 'Dane podstawowe' },
		{ key: 'attachments', tab: 'Załączniki' }
	];

	const tabContent = {
		general: <>
			<Row gutter={[16, 24]}>
				<Col span={24}>
					<FormItem>
						<FormItemLabel>
							Status wniosku
						</FormItemLabel>
						<FormItemValue>
							{data?.staapp_name}
						</FormItemValue>
					</FormItem>
				</Col>

				<Col sm={24} md={12}>
					<FormItem>
						<FormItemLabel>
							Wnioskujący
						</FormItemLabel>
						<FormItemValue>
							{data?.full_name}
						</FormItemValue>
					</FormItem>
				</Col>

				<Col sm={24} md={12}>
					<FormItem>
						<FormItemLabel>
							Data złożenia wniosku
						</FormItemLabel>
						<FormItemValue>
							{data?.application_date}
						</FormItemValue>
					</FormItem>
				</Col>

				<Col sm={24} md={12}>
					<FormItem>
						<FormItemLabel>
							Termin urlopu od
						</FormItemLabel>
						<FormItemValue>
							{data?.from}
						</FormItemValue>
					</FormItem>
				</Col>

				<Col sm={24} md={12}>
					<FormItem>
						<FormItemLabel>
							Termin urlopu do
						</FormItemLabel>
						<FormItemValue>
							{data?.to}
						</FormItemValue>
					</FormItem>
				</Col>

				<Col sm={24} md={12}>

				</Col>

				<Col sm={24} md={12}>
					<FormItem>
						<FormItemLabel>
							Ilość dni roboczych
						</FormItemLabel>
						<FormItemValue>
							{data?.working_days ?? '(brak)'}
						</FormItemValue>
					</FormItem>
				</Col>

				<Col sm={24} md={12}>
					<FormItem>
						<FormItemLabel>
							Kwota podlegająca dofinansowaniu
						</FormItemLabel>
						<FormItemValue>
							{data?.subsidy_value ?? '-'}
						</FormItemValue>
					</FormItem>
				</Col>

				{
					(data?.subsidy_total && parseFloat(data.subsidy_total) > 0)
						? <Col sm={24} md={12}>
							<FormItem>
								<FormItemLabel>
									Wysokość dofinansowania
								</FormItemLabel>
								<FormItemValue>
									{data?.subsidy_total ?? '(brak)'}
								</FormItemValue>
							</FormItem>
						</Col>
						: null
				}

				<Col sm={24} md={12}>
					<FormItem>
						<FormItemLabel>
							Rzeczywista kwota do zapłaty
						</FormItemLabel>
						<FormItemValue>
							{data?.real_quote ?? '(brak)'}
						</FormItemValue>
					</FormItem>
				</Col>

				{
					(data?.remarks)
						? <Col span={24}>
							<FormItem>
								<FormItemLabel>
									Uwagi
								</FormItemLabel>
								<FormItemValue>
									{data?.remarks ?? '(brak)'}
								</FormItemValue>
							</FormItem>
						</Col>
						: null
				}

				{
					(data?.to_correct)
						? <Col span={24}>
							<FormItem>
								<FormItemLabel>
									Uwagi (do korekty)
								</FormItemLabel>
								<FormItemValue>
									{data?.to_correct ?? '(brak)'}
								</FormItemValue>
							</FormItem>
						</Col>
						: null
				}

				{
					(data?.control)
						? <Col span={24}>
							<FormItem>
								<FormItemLabel>
									Wynik ostatniego obliczenia
								</FormItemLabel>
								<FormItemValue>
									{data?.control ?? '(brak)'}
								</FormItemValue>
							</FormItem>
						</Col>
						: null
				}
			</Row>
		</>,
		attachments: <>
			<Row gutter={[16, 24]}>
				<Col sm={24} md={12}>
					<FormItem>
						<FormItemLabel>
							Ogólne
						</FormItemLabel>
						<FormItemValue>
							<FileList>
								{
									(data?.attachments && data.attachments.filter(file => file.dctfiletype_id === getFileType('Ogólny')?.id).length)
										? data.attachments.filter(file => file.dctfiletype_id === getFileType('Ogólny')?.id).map(file => (
											<FileListItem key={['fileList_item', file.uid]}>
												<a href={`${backendApi}/storage/app${file.file_path}`}>
													{file.file_name}
												</a>
											</FileListItem>
										))
										: '(brak)'
								}
							</FileList>
						</FormItemValue>
					</FormItem>
				</Col>
			</Row>
		</>,
	};

	return (
		<>
			<Subheader title={data ? `[${data.number}] Rejestr wpłat` : `Rejestr wpłat`} extra={<Subheader.Actions>
				<Link to="/" style={{ marginRight: 15 }}>Wróć do pulpitu</Link>
				<Button type="primary" onClick={handleEditClick} disabled={!(data && allowedEditIds.includes(data?.staapp_id))}>Edytuj wniosek</Button>
				{
					hasStatus(data?.staapp_id, STASTATUS.STA_REGISTERED)
						? <Button type="primary" onClick={() => handleCountClick('pears', data.id).then(r => {
							message.success(`Dofinansowanie zostało obliczone, wysokość dofinansowania: ${r?.subsidy_total ?? 0} PLN`);
							setData(r);
						}).catch(err => message.error('Wystąpił błąd podczas obliczania dofinansowania, prosimy o kontakt z BOK'))} style={{ marginLeft: 15 }}>Oblicz dofinansowanie</Button>
						: null
				}
			</Subheader.Actions>} />

			{
				(data === null || relationData === null)
					? <Card loading={true} />
					: <CardTabbed tabList={tabs} tabContent={tabContent} activeTabKey={currentTab} onTabChange={key => { setCurrentTab(key); }} />
			}
		</>
	);
};

export default Dashboard_InfantPayments;
