import styled from 'styled-components';

const Main = styled.div`
	display: block;
	min-width: 0;
	position: relative;
	width: 100vw;
	height: 100vh;
	background: #f2f6ff;
`;

export default Main;
