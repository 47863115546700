import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import cardHeaderBg from '../../assets/images/appcard_header.png';

import icons from './icons';

const Card = styled.div`
	background: #fff;
	border-radius: 2px;
	box-sizing: border-box;
	color: black;
	font-size: 14px;
	margin: 0;
	padding: 0;
	position: relative;
	border: 1px solid #f0f0f0;
	box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 5%);
`;

const CardHeader = styled.div`
	display: flex;
	background: url(${cardHeaderBg}) center center no-repeat;
	background-size: cover;
	border-bottom: 1px solid #f0f0f0;
	border-radius: 2px 2px 0 0;
	color: black;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: -1px;
	height: 67px;
	padding: 0 15px;
	align-items: center;
	cursor: pointer;
`;

const CardImage = styled.img`
	margin-right: 20px;
	margin-top: -20px;
	filter: drop-shadow(-2px -2px 0px #1a98e5);
	width: 64px;
	height: 64px;
	object-fit: cover;
`;

const CardTitles = styled.div`
	display: flex;
	flex-direction: column;
	font-weight: 600;
	text-transform: uppercase;
	line-height: 1.2;
	justify-content: center;
	align-items: flex-start;
`;

const CardTitle = styled.h1`
	font-size: 22px;
	font-weight: 600;
	margin: 0;
	padding: 0;
`;

const CardSubtitle = styled.div`
	font-size: 16px;
	color: #455260;
`;

const CardBody = styled.div`
	font-size: 12px;
	padding: 10px 15px;
`;

const CardBodyActions = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 15px;
`;

class AppCard extends React.PureComponent {
	static propTypes = {
		title: PropTypes.string.isRequired,
		subtitle: PropTypes.string,
		icon: PropTypes.oneOfType([
			PropTypes.oneOf([
				'pears'
			]),
			PropTypes.node
		]),
	};

	static defaultProps = {

	};

	getIcon(icon) {
		if(icons.hasOwnProperty(icon)) {
			return icons[icon];
		}

		return false;
	}

	render() {
		const {
			title,
			subtitle,
			icon,
			children
		} = this.props;

		return (
			<Card>
				<CardHeader>
					<CardImage src={this.getIcon(icon)} />
					<CardTitles>
						<CardTitle>{title}</CardTitle>
						<CardSubtitle>{subtitle}</CardSubtitle>
					</CardTitles>
				</CardHeader>
				<CardBody>
					{children}
				</CardBody>
			</Card>
		);
	}
}

AppCard.BodyActions = CardBodyActions;

export default AppCard;
