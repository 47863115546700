import React from 'react';
import { useHistory, Link } from 'react-router-dom';

import { useAuth } from '../../../contexts/User';

import Subheader from '../../../components/Subheader';
import CardTabbed from '../../../components/CardTabbed';
import DraggerUpload from '../../../components/DraggerUpload';

import { InboxOutlined } from '@ant-design/icons';
import { Row, Col, Card, Form, Button, Input, InputNumber, Select, DatePicker, Switch, message, Modal } from 'antd';

import { APIBackend as API } from '../../../api';

import moment from 'moment';

import createPersistedState from '../../../hooks/usePersistedState';
import {onApplyHook} from "../../../common/on-apply-hook/on-apply.hook";

const { Option } = Select;

const useUserStageState = createPersistedState('userStage');
const useContractorState = createPersistedState('contractor');

const Dashboard_Infants = ({ ...props }) => {
	const auth = useAuth();
	const history = useHistory();

	const [form] = Form.useForm();

	const [profileData, setProfileData] = React.useState({});
	const [loading, setLoading] = React.useState(true);
	const [saving, setSaving] = React.useState(false);
	const [loadingUser, setLoadingUser] = React.useState(true);
	const [relationData, setRelationData] = React.useState(null);
	const [currentTab, setCurrentTab] = React.useState('general');
	const [changes, setChanges] = React.useState({});

	const [userStage, setUserStage] = useUserStageState(0);
	const [contractor, setContractor] = useContractorState(null);

	const [attachments, setAttachments] = React.useState({
		list: [],
		add: [],
		del: []
	});

	React.useEffect(() => {
		API.getActiveProfile(auth.user?.id, contractor).then(data => {
			setProfileData(data);
		}).catch(err => {

		}).finally(() => {
			setLoading(false);
		});

		API.relations(['appinfantpayments', 'appinfantagreements', 'infants', 'dctfiletypes']).then(data => {
			setRelationData(data);
		}).catch(err => {
			console.log(err);
		});
	}, []);

	React.useEffect(() => {
		setLoadingUser(false);
	}, [auth, auth.user]);

	const onSave = async (values) => {
		setSaving(true);

		if(!(await onApplyHook('infants'))) {
			setSaving(false);
			return false;
		}

		Object.keys(values).forEach(el => {
			if(['application_date', 'payment_date', 'from', 'to'].includes(el)) {
				values[el] = values[el] ? values[el].format('YYYY-MM-DD') : null;
			} else if(['attachments'].includes(el)) {
				if(attachments.hasOwnProperty('add')) {
					attachments.add.forEach(el => {
						if(el) {
							values[`files[${el.category}/${el.name}]`] = el.file;
						}
					});
				}

				if(attachments.hasOwnProperty('del')) {
					attachments.del.forEach(el => {
						if(el) {
							values[`delete[${el.category}/${el.name}]`] = el.uid;
						}
					});
				}

				delete values[el];
			}
		});

		const _data = new FormData();

		for(let k in values) {
			let val = values[k];

			if(val !== null && val !== undefined && val !== '') {
				_data.append(k, val);
			}
		}

		API.applyInfants(_data).then(r => {
			Modal.confirm({
				title: 'Wniosek został zapisany.',
				content: <>Chcesz przekazać wniosek do akceptacji?</>,
				okText: 'Przekaż do akceptacji',
				cancelText: 'Nie',
				onOk() {
					return API.acceptApp('appinfants', r.id).then(() => {
						message.success('Wniosek został przekazany do akceptacji');
					}).catch(err => {
						message.error(err.getMessage());
					}).finally(() => {
                        history.push('/');
                    });
				},
                onCancel() { history.push('/'); }
			});
		}).catch(err => {
			message.error(err.getMessage());
			console.log(err);
		}).finally(() => {
			setSaving(false);
		});
	};

	const getRelation = (name) => {
		if(relationData && relationData.hasOwnProperty(name)) {
			if(relationData[name]) {
				return relationData[name].filter(el => el?.is_active ?? true);
			}
		}

		return [];
	};

	const onChange = (field, allFields) => {
		setChanges(allFields);

		const keys = Object.keys(field);

		if(!keys.length) return;

		const value = field[keys[0]];
	};

	const tabs = [
		{ key: 'general', tab: 'Dane podstawowe' },
		{ key: 'attachments', tab: 'Załączniki' }
	];

	const tabContent = {
		general: <>
			<Row gutter={[16, 24]}>
				<Col sm={24} md={12}>
					<Form.Item
						label="Wnioskujący"
						name="profile_id"
						initialValue={profileData.id}
					>
						<Select disabled>
							<Option value={profileData.id}>{auth.user?.full_name}</Option>
						</Select>
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Data złożenia wniosku"
						name="application_date"
						initialValue={moment()}
					>
						<DatePicker style={{ width: '100%' }} disabled />
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Uczestnik"
						name="family_id"
						rules={[
							{
								required: true,
								message: 'Proszę wybrać uczestnika wypoczynku'
							}
						]}
					>
						<Select
							showSearch
							optionFilterProp="children"
						>
							{
								profileData && profileData.hasOwnProperty('families') && profileData.families.map(el => (
									<Option
										key={el.id}
										value={el.id}
									>
										{el.first_name} {el.last_name} ({el.birth_date})
									</Option>
								))
							}
						</Select>
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Rok szkolny"
						name="school_year"
					>
						<Input placeholder="RRRR" maxLength={4} />
					</Form.Item>
				</Col>

				<Col span={24}>
					<Form.Item
						label="Rodzaj placówki"
						name="infant_type"
						rules={[
							{
								required: true,
								message: 'Proszę wybrać rodzaj placówki'
							}
						]}
					>
						<Select>
							<Option value="I">Wewnętrzna</Option>
							<Option value="E">Zewnętrzna</Option>
						</Select>
					</Form.Item>
				</Col>

				{
					changes?.infant_type === 'I'
						? <>
							<Col sm={24} md={12}>
								<Form.Item
									label="Przedszkole/żłobek"
									name="infant_id"
									rules={[
										{
											required: true,
											message: 'Proszę wybrać przedszkole lub żłobek'
										}
									]}
								>
									<Select
										showSearch
										optionFilterProp="children"
									>
										{getRelation('infants').filter(el => el.contractor_id === auth?.contractor).map(el => {
											return (
												<Option
													key={['infant', el.id]}
													value={el.id}
												>
													{el.name}
												</Option>
											);
										})}
									</Select>
								</Form.Item>
							</Col>
						</>
						: null
				}

				{
					changes?.infant_type === 'E'
						? <>
							<Col span={24}>
								<Form.Item
									label="Nazwa obiektu"
									name="name"
								>
									<Input />
								</Form.Item>
							</Col>

							<Col sm={24} md={12}>
								<Form.Item
									label="Ulica obiektu"
									name="street"
								>
									<Input />
								</Form.Item>
							</Col>

							<Col sm={24} md={5}>
								<Form.Item
									label="Kod pocztowy obiektu"
									name="zip"
								>
									<Input />
								</Form.Item>
							</Col>

							<Col sm={24} md={7}>
								<Form.Item
									label="Miejscowość obiektu"
									name="town"
								>
									<Input />
								</Form.Item>
							</Col>
						</>
						: null
				}
			</Row>
		</>,
		attachments: <>
			<Row gutter={[16, 24]}>
				<Col sm={24} md={12}>
					<Form.Item
						label="Ogólny"
						name="attachments"
						getValueFromEvent={(e) => {
							if(Array.isArray(e)) {
								return e;
							}

							return e && e.fileList;
						}}
					>
						<DraggerUpload
							fileType={
								(relationData && relationData.hasOwnProperty('dctfiletypes'))
									? relationData.dctfiletypes.find(el => el.name === 'Ogólny') ?? null
									: null
							}
							updateAttachments={setAttachments}
							data={attachments}
						>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>

							<p className="ant-upload-text">Kliknij lub przeciągnij plik do tego obszaru, aby dodać załącznik</p>
						</DraggerUpload>
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Umowy"
						name="attachments"
						getValueFromEvent={(e) => {
							if(Array.isArray(e)) {
								return e;
							}

							return e && e.fileList;
						}}
					>
						<DraggerUpload
							fileType={
								(relationData && relationData.hasOwnProperty('dctfiletypes'))
									? relationData.dctfiletypes.find(el => el.name === 'Umowy') ?? null
									: null
							}
							updateAttachments={setAttachments}
							data={attachments}
						>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>

							<p className="ant-upload-text">Kliknij lub przeciągnij plik do tego obszaru, aby dodać załącznik</p>
						</DraggerUpload>
					</Form.Item>
				</Col>
			</Row>
		</>,
	};

	return (
		<>
			<Form form={form} layout="vertical" onFinish={onSave} onValuesChange={onChange}>
				<Subheader title={`Przedszkola i żłobki`} extra={<Subheader.Actions>
					<Link to="/infants" style={{ marginRight: 15 }}>Wróć</Link>
					<Button type="primary" htmlType="submit" disabled={loading} loading={saving}>Zapisz wniosek</Button>
				</Subheader.Actions>} />

				<CardTabbed loading={loading || loadingUser} tabList={tabs} tabContent={tabContent} activeTabKey={currentTab} onTabChange={key => { setCurrentTab(key); }} />
			</Form>
		</>
	);
};

export default Dashboard_Infants;
