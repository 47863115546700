import { APIInstance } from '../../';
import { API } from '../../api';

export default {
	camps(id) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.get(`/api/appcamps/${id}`).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	applyCamps(values) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.post(`/api/appcamps`, values).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	updateCamps(id, values) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.post(`/api/appcamps/${id}`, values).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	},

	countCamps(id) {
		return new Promise((resolve, reject) => {
			API.csrfCookie().then(() => {
				APIInstance.get(`/api/appcamps/count/${id}`).then(r => {
					resolve(r.data);
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				reject(err);
			});
		});
	}
};
