import styled from 'styled-components';

const Header = styled.div.attrs(props => ({

}))`
	background: #fff;
	box-shadow: 0 5px 5px -5px rgba(0, 0, 0, .3);
	border-top: 2px solid #0097d6;
	height: 64px;
	padding: 0;
	color: rgba(0, 0, 0, .85);
	line-height: 64px;
`;

export default Header;
