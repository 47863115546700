import styled from 'styled-components';

import { breakpoints } from '../../utils/Styled/breakpoints';

const Container = styled.div`
	width: 100%;
	padding-left: ${props => props?.noPadding ? 0 : '.75rem'};
	padding-right: ${props => props?.noPadding ? 0 : '.75rem'};
	margin-left: auto;
	margin-right: auto;

	${breakpoints(
		'max-width',
		'px',
		[
			{ 480: 480 },
			{ 576: 576 },
			{ 768: 768 },
			{ 992: 992 },
			{ 1200: 1200 },
			{ 1600: 1600 },
		],
		'min-width'
	)}
`;

export default Container;
