import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import { UserProvider, useAuth } from './contexts/User';

import PrivateRoute from './navigation/PrivateRoute';
import PublicRoute from './navigation/PublicRoute';

import NotFound from './pages/NotFound';

import Dashboard from './pages/Dashboard';

import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Register from './pages/Register';

const Main = () => {
	const auth = useAuth();

	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		auth.authenticate().then(data => {

		}).catch(err => {

		}).finally(() => {
			setTimeout(() => {
				const el = document.getElementById('zfss-loading');

				setLoading(false);

				if(el) {
					el.classList.add('hide');
					setTimeout(() => {
						el.outerHTML = '';
					}, 1000);
				}
			}, 1000);
		});
	}, []);

	return (
		loading
			? null
			: <Switch>
				<PublicRoute path="/login" component={Login} />
				<PublicRoute path="/reset-password/:token" component={ResetPassword} />
				<PublicRoute path="/forgot-password" component={ForgotPassword} />
				<PublicRoute path="/register" component={Register} />

				<Route path="/404" component={NotFound} />
				<PrivateRoute path="/" component={Dashboard} />

				<Redirect to="/404" />
			</Switch>
	)
}

export default Main;
