const relationOffTables = ['offtrips', 'offcamps', 'offtourists', 'offikos', 'offiros'];
const filterObj = {};

export const checkRelationTablesService = (table, data) => {

	const foundOffRelation = table.some(r=> relationOffTables.includes(r));

	// check if api relation request has off* table names
	if (foundOffRelation) {
		const filteredArray = table.filter(value => relationOffTables.includes(value));
		// fill object with off* names and ids
		filteredArray.forEach((element, index) => {
			filterObj[element] = data[element.slice(0, -1) + '_id'];
		});
		// output example
		// { offcamps: 18, ... }
	}

	return filterObj;

};
