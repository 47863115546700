import { APIBackend as API } from '../../api';

export const TYPE = {
	GENERAL: 1,
	CERTS: 2,
	APPS: 3,
	CORRECTIONS: 4,
	GDPR: 5,
	REGS: 6,
	CONTRACTS: 7,
	LOGOS: 8
};
