class PeselEntity {
	pesel;
	gender;
	birthday;
	isValid;
	errors = [];

	constructor(pesel) {
		// if(typeof pesel !== 'string') {
		// 	throw new Error('PESEL must be a string.');
		// }
		//
		// if(!/^\d+$/.test(pesel)) {
		// 	throw new Error('Invalid PESEL provided (non-numeric)');
		// }

		if(typeof pesel === 'string' && /^\d+$/.test(pesel)) {
			this.pesel = pesel.trim();
			this.parse();
		} else {
			this.pesel = pesel;
			this.isValid = false;
			this.errors.push('PESEL-INVALID-FORMAT');
		}
	}

	/**
	 * Parse PESEL number (https://www.gov.pl/web/gov/czym-jest-numer-pesel)
	 * @return {void}
	 */
	parse() {
		const {
			pesel
		} = this;

		const peselArr = pesel.split('').map(el => Number(el));

		this.isValid = this.checkControl(peselArr);

		if(this.isValid) {
			let year = 1900 + peselArr[0] * 10 + peselArr[1];

			if(peselArr[2] >= 2 && peselArr[2] < 8) {
				year += Math.floor(peselArr[2] / 2) * 100;
			}

			if(peselArr[2] >= 8) {
				year -= 100;
			}

			const month = (peselArr[2] % 2) * 10 + peselArr[3];
			const day = peselArr[4] * 10 + peselArr[5];

			const validBirthday = this.checkBirthday(year, month, day);

			if(!validBirthday) {
				this.isValid = false;
				this.errors.push('PESEL-INVALID-DATE');
			} else {
				this.birthday = new Date(year, month - 1, day, 12, 0, 0, 0);
			}

			this.gender = (peselArr[9] % 2 === 1) ? 'M' : 'F';
		} else {
			this.errors.push('PESEL-INVALID-CONTROL');
		}
	}

	checkControl(peselArr) {
		const weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3, 1];
		let sum = 0;

		weight.forEach((w, idx) => {
			sum += w * peselArr[idx];
		});

		return (sum % 10) === 0;
	}

	checkBirthday(year, month, day) {
		const _date = new Date(year, month - 1, day);

		return _date.getDate() === day
				&& _date.getMonth() === (month - 1)
				&& _date.getFullYear() === year;
	}

	getBirthday() {
		return this.birthday ?? null;
	}

	getGender() {
		return this.gender;
	}

	valid() {
		return this.isValid;
	}

	isValidControl() {
		return !this.errors.includes('PESEL-INVALID-CONTROL');
	}

	isValidBirthday() {
		return !this.errors.includes('PESEL-INVALID-DATE');
	}

	isValidFormat() {
		return !this.errors.includes('PESEL-INVALID-FORMAT');
	}
}

const PeselValidator = {
	isValid(pesel) {
		return (new PeselEntity(pesel)).valid();
	},

	parse(pesel) {
		return new PeselEntity(pesel);
	}
};

export default PeselValidator;
