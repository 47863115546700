import styled from 'styled-components';

const Content = styled.div`
	display: block;
	width: 100%;
	height: 100%;
	min-width: 0;
	position: relative;
	background: #f2f6ff;
	box-shadow: inset 0 13px 17px -16px rgb(0 0 0 / 15%);
`;

export default Content;
